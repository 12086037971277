import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import {
  ListDataRequest,
  ListFiltersState,
  ListOrderingState,
  ListPaginationState,
} from './types';
import { ListContext } from './ListContext';
import { useListDataRequest } from './useListDataRequest';

export type ListContainerProps = {
  children?: ReactNode;
  dataRequest: ListDataRequest;
  filters?: ListFiltersState | null;
  ordering?: ListOrderingState | null;
  pagination?: ListPaginationState | null;
};

export const ListContainer: FC<ListContainerProps> = (props) => {
  const { children, dataRequest } = props;
  const [pagination, setPagination] = useState<ListPaginationState>(
    props.pagination || {
      page: 1,
      size: 10,
    }
  );
  const [ordering, setOrdering] = useState<ListOrderingState | null>(
    props.ordering || null
  );
  const [filters, setFilters] = useState<ListFiltersState | null>(
    props.filters || null
  );

  const params = useMemo(
    () => ({
      pagination,
      ordering,
      filters,
    }),
    [pagination, ordering, filters]
  );

  const { data, count, loading, error, reload } = useListDataRequest(
    dataRequest,
    params
  );

  const goToNextPage = useCallback(() => {
    setPagination((state) => ({
      size: state.size,
      page: state.page + 1,
    }));
  }, []);

  const goToPrevPage = useCallback(() => {
    setPagination((state) => ({
      size: state.size,
      page: state.page - 1,
    }));
  }, []);

  const goToFirstPage = useCallback(() => {
    setPagination((state) => ({
      size: state.size,
      page: 1,
    }));
  }, []);

  const goToPage = useCallback((page: number) => {
    setPagination((state) => ({
      size: state.size,
      page,
    }));
  }, []);

  return (
    <ListContext.Provider
      value={{
        data,
        count,
        loading,
        error,
        filters,
        ordering,
        pagination,
        reload,
        goToNextPage,
        goToPrevPage,
        goToFirstPage,
        goToPage,
        setFilters,
        setOrdering,
        setPagination,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};
