import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export const ButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 12px 0 6px;
`;

export const RecoveryLinkContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

export const RecoveryLink = styled(NavLink)`
  text-decoration: none;
  color: #7e66e1;
`;
