import { createContext, FC, useState } from 'react';
import { useAnimatedMeshViewer } from '../hooks/useAnimatedMeshViewer';
import {
  FDAItem,
  StandingDynamicAssessment,
  WalkingDynamicAssessment,
} from '../types';

type UseAnimatedMeshViewerReturnType = ReturnType<typeof useAnimatedMeshViewer>;

interface FunctionalDynamicAssessmentProviderReturn {
  balanceData: StandingDynamicAssessment;
  gaitData: WalkingDynamicAssessment;
  balanceControls: UseAnimatedMeshViewerReturnType;
  gaitControls: UseAnimatedMeshViewerReturnType;
  handleSwitch: (view: 'balance' | 'gait') => void;
  selectedView: 'balance' | 'gait';
}

export const FunctionalDynamicAssessmentContext =
  createContext<FunctionalDynamicAssessmentProviderReturn>(
    {} as FunctionalDynamicAssessmentProviderReturn
  );

interface FunctionalDynamicAssessmentProviderProps {
  fdaData: FDAItem;
  authToken: string;
  // fdaData: {
  //   balance_glb_url: string;
  //   gait_glb_url: string;
  //   balance_data: BalanceData;
  //   gait_data: GaitData;
  // };
  children: React.ReactNode;
}

export const FunctionalDynamicAssessmentProvider: FC<
  FunctionalDynamicAssessmentProviderProps
> = ({ children, fdaData, authToken }) => {
  const balanceControls = useAnimatedMeshViewer({
    url: fdaData.standing.output_stream,
    authToken,
  });
  const gaitControls = useAnimatedMeshViewer({
    url: fdaData.walking.output_stream,
    authToken,
  });
  const [selectedView, setSelectedView] = useState<'balance' | 'gait'>(
    'balance'
  );

  const handleSwitch = (view: 'balance' | 'gait') => {
    setSelectedView(view);
    balanceControls.stop();
    gaitControls.stop();
  };

  const context = {
    balanceData: fdaData.standing,
    gaitData: fdaData.walking,
    balanceControls,
    gaitControls,

    selectedView,
    handleSwitch,
  };

  return (
    <FunctionalDynamicAssessmentContext.Provider value={context}>
      {children}
    </FunctionalDynamicAssessmentContext.Provider>
  );
};
