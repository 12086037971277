import { styled } from '@mui/material';

export const IconContainer = styled('div')`
  position: absolute;
  bottom: 24px;
  right: 24px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1eeff;
  border-radius: 50%;
`;

export const ButtonContainer = styled('div')`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const WarningLabel = styled('div')`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

export const NextScanContainer = styled('div')`
  padding-top: 16px;
`;
