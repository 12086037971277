import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ScanCompare } from '../../../scan';
import { usePatientContext } from '../../containers/PatientContainer/usePatientContext';

export const PatientScansScreen: FC = () => {
  const { patient } = usePatientContext();
  const [params] = useSearchParams();

  const scanId = params.get('scanId');

  return <ScanCompare patientId={patient.id} selectedScanId={scanId} />;
};
