import { FC } from 'react';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { ListContainer, ListLayout, ListTitle } from '../../../ui';
import { ScanDataGrid } from '../../components';
import { getScanList } from '../../network';

export const ScanListScreen: FC = () => {
  return (
    <>
      <ListContainer dataRequest={getScanList}>
        <DocumentTitle title='Scans' />
        <ListTitle variant='h1'>Scans</ListTitle>
        <ListLayout>
          <ScanDataGrid height='80vh' />
        </ListLayout>
      </ListContainer>
    </>
  );
};
