import { FC } from 'react';
import { Link } from '@mui/material';
import { useAuthContext } from '../../../auth';
import { AccountEditForm, ChangePasswordButton, DeleteAccountButton } from '../../../account';
import { FormStackVertical } from '../../../ui';

export const AccountSettings: FC = () => {
  const { logout } = useAuthContext();

  return (
    <div>
      <AccountEditForm />
      <FormStackVertical alignItems='flex-start'>
        <ChangePasswordButton
          render={({ handleOpen }) => (
            <Link onClick={handleOpen} variant='button'>
              Change password
            </Link>
          )}
        />
        <Link onClick={logout} variant='button'>
          Log out
        </Link>
        <DeleteAccountButton
          render={({ handleOpen }) => (
            <Link onClick={handleOpen} variant='button' color='error'>
              Delete Account
            </Link>
          )}
        />
      </FormStackVertical>
    </div>
  );
};
