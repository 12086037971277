import { DragOverlay, DragStartEvent, useDndMonitor } from '@dnd-kit/core';
import { FC, useCallback, useState } from 'react';
import { ScanModel } from '../../../network';
import { ScanItem } from './ScanItem';

export const ScanDragOverlay: FC = () => {
  const [dragItem, setFragItem] = useState<ScanModel | null>(null);

  const onDragStart = useCallback((event: DragStartEvent) => {
    setFragItem(event.active.data.current as ScanModel);
  }, []);

  const onDragEnd = useCallback(() => {
    setFragItem(null);
  }, []);

  useDndMonitor({
    onDragStart,
    onDragEnd,
    onDragCancel: onDragEnd,
  });

  return <DragOverlay>{dragItem && <ScanItem item={dragItem} />}</DragOverlay>;
};
