import { MutableRefObject, createContext } from 'react';
import { UserModel } from '../account';
import { GridApiCommunity } from '@mui/x-data-grid/internals';

export interface IAllScansContext {
  data: UserModel[] | null;
  apiRef: MutableRefObject<GridApiCommunity>;
  setIncomingPatchRequest: (value: boolean) => void;
  setLoadTable: (value: boolean) => void;
  loading: boolean;
}

export const AllScansContext = createContext({} as IAllScansContext);
