import { useEffect, useCallback, FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ValidationErrors } from 'final-form';
import { Form } from 'react-final-form';
import { Button, Typography, styled } from '@mui/material';
import { DateInputFF, FormStackVertical, TextInputFF } from '../../../ui';
import { AuthCardHeader, AuthCardTitle } from '../ui';
import { ButtonContainer } from './styles';
import { useSignUp } from '../../hooks/useSignUp';
import { CreateNewUserData, CreateNewUserDataWithOrgId } from '../../network';

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

// patient sign up page
// also handles users signing up to an organization
export const SignUp = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orgName = searchParams.get('org_name');
  const orgId = searchParams.get('org_id');

  const { result, isLoading, request } = useSignUp();

  useEffect(() => {
    if (result) {
      navigate('/sign-up-success');
    }
  }, [result, navigate]);

  const onSubmit = useCallback(
    (values: CreateNewUserData | CreateNewUserDataWithOrgId) => {
      let userData = values;
      if (orgId && orgName) {
        userData = { ...values, organization_id: orgId };
      }

      userData.birthday = new Date(userData.birthday)
        .toISOString()
        .split('T')[0];

      request(userData);
    },
    [request, orgId, orgName]
  );

  const validate = (values: any) => {
    const errors: ValidationErrors = {};
    if (!values.first_name) {
      errors.first_name = 'First name is required';
    }
    if (!values.last_name) {
      errors.last_name = 'Last name is required';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    }
    if (!values.birthday) {
      errors.birthday = 'Date of birth is required';
    }
    return errors;
  };

  const submitButtonText =
    orgId && orgName ? `Sign up and join ${orgName}` : 'Sign Up';

  return (
    <ScrollContainer>
      <Container>
        <AuthCardHeader>
          <AuthCardTitle variant='h1'>Sign Up</AuthCardTitle>
          <div>
            <Typography variant='bodyMedium'>
              Welcome to Momentum Spine!
            </Typography>
            {orgName && (
              <>
                <br />
                <Typography variant='bodyMedium'>
                  You're about to join <b>{orgName}</b>
                </Typography>
              </>
            )}
          </div>
        </AuthCardHeader>
        <Form
          validate={validate}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormStackVertical>
                <TextInputFF label='First Name' name='first_name' />
                <TextInputFF label='Last Name' name='last_name' />
                <TextInputFF
                  label='Email'
                  name='email'
                  placeholder='email@example.com'
                />

                <TextInputFF
                  label='Password'
                  name='password'
                  placeholder='8+ Characters'
                  type='password'
                />
                <DateInputFF name='birthday' label='Date of birth' fullWidth />

                <OrgSignUpText />
                <ButtonContainer>
                  <Button type='submit' disabled={isLoading}>
                    {submitButtonText}
                  </Button>
                </ButtonContainer>
              </FormStackVertical>
            </form>
          )}
        />
      </Container>
    </ScrollContainer>
  );
};

const OrgSignUpText: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const orgName = searchParams.get('org_name');

  if (!orgName) {
    return null;
  }

  const handleRemoveOrgIdRef = () => {
    // remove org_id and org_name from query params
    setSearchParams();
  };

  return (
    <div>
      <Typography variant='bodyMedium'>
        Click <InlineButton onClick={handleRemoveOrgIdRef}>here</InlineButton>{' '}
        if you do not want to join <b>{orgName}</b>
      </Typography>
    </div>
  );
};

export const ScrollContainer = styled('div')`
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 1rem;
`;

export const Container = styled('div')`
  margin: 0 auto;
  background: white;
  max-width: 512px;
  margin-top: 30px;
  margin-bottom: 100px;
  padding: 40px;
  border-radius: 24px;
  box-shadow: 0px 27px 107px rgba(55, 9, 130, 0.07),
    0px 6.0308px 23.8999px rgba(55, 9, 130, 0.0417275),
    0px 1.79553px 7.11561px rgba(55, 9, 130, 0.0282725);
`;

export const InlineButton = styled('button')`
  outline: none;
  border: none;
  cursor: pointer;
  display: inline;
  font-size: inherit;
  font-family: inherit;
  color: blue;
  background: none;
  padding: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;
