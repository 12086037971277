import { FC } from 'react';
import { PatientNoteModel, deleteNote } from '../../../network';
import { enqueueSnackbar } from 'notistack';
import { NoteItem } from './NoteItem';
import { styled } from '@mui/material';
import { useCurrentUser } from '../../../../account';
import { Loader } from '../../../../ui';

interface NoteListProps {
  data: PatientNoteModel[] | null;
  reload: () => void;
}
export const NoteList: FC<NoteListProps> = ({ data, reload }) => {
  const handleRemove = async (noteId: number) => {
    await deleteNote(noteId);
    enqueueSnackbar('Note removed successfully', { variant: 'success' });
    reload();
  };

  const [result, isLoading, error] = useCurrentUser();

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (!result) {
    return <div>Not coordinator data</div>;
  }

  const currentCoordinatorId = result?.id;

  return (
    <NotesContainer>
      {data &&
        data.map((note) => (
          <NoteItem
            key={note.id}
            note={note}
            handleRemove={handleRemove}
            currentCoordinatorId={currentCoordinatorId}
          />
        ))}
    </NotesContainer>
  );
};

const NotesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 16px;
`;
