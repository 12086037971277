import { request } from '../../../network';
import { UserModel } from '../types';

export const getCurrentUser = async (): Promise<UserModel> => {
  const response = await request({
    path: '/api/current_user/',
    method: 'GET',
  });

  if (response.ok) {
    if (
      Array.isArray(response.data?.results) &&
      response.data.results.length > 0
    ) {
      return response.data.results[0];
    } else {
      throw new Error('Current user not found');
    }
  }

  throw new Error(response.errorMessage);
};

export const updateUser = async (data: UserModel): Promise<UserModel> => {
  const response = await request({
    path: `/api/current_user/${data.id}/`,
    method: 'PUT',
    body: data,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export interface ChangePasswordData {
  old_password: string;
  new_password: string;
}

export interface ChangePasswordResponse {
  result: boolean;
}

export const changePassword = async (
  data: ChangePasswordData
): Promise<ChangePasswordResponse> => {
  const response = await request({
    path: '/api/change_password/',
    method: 'PUT',
    body: data,
  });

  if (response.ok) {
    return {
      result: true,
    };
  }

  throw new Error(response.errorMessage);
};

export interface DeleteUserResponse {
  result: boolean;
}


export const deleteUser = async (
  id: string | number
): Promise<DeleteUserResponse> => {
  const response = await request({
    path: `/api/current_user/${id}`,
    method: 'DELETE',
  });

  if (response.ok) {
    return {
      result: true,
    };
  }

  throw new Error(response.errorMessage);
};
