import { FC, ReactElement, useCallback } from 'react';
import { Item, ItemIcon, Wrapper } from './styles';

export interface SwitchButtonsItem {
  text: string;
  value: any;
  icon?: ReactElement;
}
export interface SwitchButtonsProps {
  items: Array<SwitchButtonsItem>;
  value: any;
  onChange?: (value: any) => void;
}

export const SwitchButtons: FC<SwitchButtonsProps> = (props) => {
  const { items, value, onChange } = props;

  const changeHandler = useCallback((newValue: any) => {
    if (onChange) {
      onChange(newValue);
    }
  }, [onChange]);

  return (
    <Wrapper>
      {items.map((item) => (
        <Item key={item.value} active={item.value === value} onClick={() => changeHandler(item.value)}>
          {item.icon && <ItemIcon>{item.icon}</ItemIcon>}
          {item.text}
        </Item>
      ))}
    </Wrapper>
  );
};
