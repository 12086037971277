import {
  resendInvitationRequest,
  sendNextScanReminderRequest,
} from '../../../../network/requestList';

export const resendInvitation = async (postData: {
  user_id: number;
}): Promise<{ Success: 'Invitation email resent successfully' }> => {
  const response = await resendInvitationRequest(
    '/api/resend_invitation/',
    postData
  );

  return response;
};

export const sendNextScanReminder = async (postData: {
  patient_id: number;
}): Promise<{ Success: 'Next scan reminder sent successfully' }> => {
  const response = await sendNextScanReminderRequest(
    '/api/resend_next_scan_reminder/',
    postData
  );

  return response;
};
