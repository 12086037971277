import { FC } from 'react';
import { Card, styled } from '@mui/material';

export const DeleteAccountScreen: FC = () => {
  return (
    <Root>
      <h1>How to delete your Momentum Spine account</h1>

      <p>
        Send an email to{' '}
        <a href='mailto:contact@momentum.health'>contact@momentum.health</a> and
        include the email address associated with your Momentum Spine account.
      </p>
      <p>
        Once the request to delete your account has been processed, all of your
        data will be delete.
      </p>

      <h2>What Happens When You Request to Delete Your Account</h2>
      <p>
        We understand that deleting your account is a significant decision.
        Below is a detailed explanation of what happens when you request account
        deletion:
      </p>
      <h3>1. Data Removal Process</h3>
      <p>
        Once you submit a request to delete your account, we initiate the
        process of permanently removing your data from our systems. This
        includes:
      </p>

      <ul>
        <li>
          <b>Personal Information:</b> All personal details, such as your name,
          email address, and other identifiable information, will be erased.
        </li>
        <li>
          <b>Scan History:</b> Any scans you've uploaded will be deleted.
        </li>
        <li>
          <b>Health Data:</b> All of the health data you shared with us will be
          deleted.
        </li>
        <li>
          <b>Account Settings and Preferences:</b> All your account settings,
          preferences, and saved items will be wiped from our servers.
        </li>
      </ul>

      <h3>2. Processing Time</h3>
      <p>
        Account deletion is not immediate. The process typically takes 2 days to
        complete. During this time, your account will be deactivated, which
        means:
      </p>
      <ul>
        <li>You won't be able to log in or access your account.</li>
        <li>
          Coordinators will no longer see your profile or any of your records.
        </li>
      </ul>

      <h3>3. Irreversible Action</h3>
      <p>
        Please note that account deletion is permanent and irreversible. Once
        your account is deleted:
      </p>
      <ul>
        <li>You won't be able to recover your data or account settings.</li>
        <li>
          Any remaining balance, rewards, or credits associated with your
          account will be lost.
        </li>
        <li>
          If you decide to use our services again, you will need to create a new
          account.
        </li>
      </ul>

      <h3>4. Exceptions and Retained Data</h3>
      <p>
        In some cases, we may retain certain data for legal, security, or
        operational reasons. This may include:
      </p>

      <ul>
        <li>
          <b>Transaction Records:</b> If you've made any purchases or
          transactions, we may retain records for accounting and legal purposes.
        </li>
        <li>
          <b>Compliance Obligations:</b> We may retain data to comply with legal
          obligations, such as responding to legal requests or maintaining
          records for regulatory compliance.
        </li>
      </ul>

      <h3>5. Support and Questions</h3>
      <p>
        If you have any questions or need assistance, our support team is here
        to help. Please contact us at{' '}
        <a href='mailto:contact@momentum.health'>contact@momentum.health</a>.
      </p>

      <p>We're sorry to see you go!</p>
    </Root>
  );
};

const Root = styled(Card)`
  background: white;
  overflow: auto;
  max-width: 800px;
  padding: 20px 20px;
  margin-bottom: 40px;
`;
