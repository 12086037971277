import { FC, useContext } from 'react';
import { Grid } from '@mui/material';
import { BalanceAssessment } from './BalanceAssessment';
import {
  BalanceMeshViewerContainer,
  GaitMeshViewerContainer,
} from './MeshViews';
import { FunctionalDynamicAssessmentContext } from '../context';
import { GaitAssessment } from './GaitAssessment';

export const FDALayout: FC = () => {
  const { selectedView } = useContext(FunctionalDynamicAssessmentContext);
  return (
    <Grid container spacing={4}>
      {selectedView === 'balance' ? (
        <>
          <Grid item xs={7}>
            <BalanceMeshViewerContainer />
          </Grid>
          <Grid item xs={5}>
            <BalanceAssessment />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={7}>
            <GaitMeshViewerContainer />
          </Grid>
          <Grid item xs={5}>
            <GaitAssessment />
          </Grid>
        </>
      )}
    </Grid>
  );
};
