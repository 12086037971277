import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { InvitePatientHeaderButton } from '../../modules/patient';

export const HeaderRouteActions: FC = () => {
  const { pathname } = useLocation();

  if (pathname === '/patients') {
    return <InvitePatientHeaderButton />;
  }

  return null;
};
