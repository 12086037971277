import { request, requestList } from '../../../network';
import { ListData, ListDataRequestParams } from '../../ui';
import { NotificationModel } from '../types';

export const getNotificationList = async (
  data?: ListDataRequestParams
): Promise<ListData<NotificationModel>> => {
  const response = await requestList('/api/notifications/', data);
  return response;
};

export const getNotifications = async (
  data?: ListDataRequestParams
): Promise<ListData<NotificationModel>> => {
  const response = (await requestList(
    '/api/notifications/',
    data
  )) as ListData<NotificationModel>;

  return response;
};

interface ReadNotificationParams {
  id: number;
  value: boolean;
}

export const toggleReadNotification = async ({
  id,
  value,
}: ReadNotificationParams): Promise<any> => {
  const response = await request({
    path: `/api/notifications/${id}/`,
    method: 'PATCH',
    body: {
      is_read: value,
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

interface ArchiveNotificationParams {
  id: number;
  value: boolean;
}

export const toggleArchiveNotification = async ({
  id,
  value,
}: ArchiveNotificationParams): Promise<any> => {
  const response = await request({
    path: `/api/notifications/${id}/`,
    method: 'PATCH',
    body: {
      archived: value,
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};
