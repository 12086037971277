import { Outlet, Navigate } from 'react-router-dom';
import { FC } from 'react';
import { UnauthorizedLayout } from '../../components/UnauthorizedLayout';
import { useAuthContext } from '../../modules/auth';
import { FullscreenStatus } from '../../modules/ui';

interface UnauthorizedContainerProps {
  universalView?: boolean;
}

export const UnauthorizedContainer: FC<UnauthorizedContainerProps> = (
  props
) => {
  const { universalView = false } = props;
  const { authorized, initialized } = useAuthContext();

  if (!initialized) {
    return <FullscreenStatus loading />;
  }

  if (authorized && !universalView) {
    return <Navigate to='/' />;
  }

  return (
    <UnauthorizedLayout>
      <Outlet />
    </UnauthorizedLayout>
  );
};
