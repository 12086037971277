import { FC, useEffect, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import {
  useAnimations,
  OrbitControls,
  GizmoHelper,
  GizmoViewport,
  Grid as DreiGrid,
} from '@react-three/drei';
import { UseAnimationsReturnType } from '../hooks/useAnimatedMeshViewer';
import { AnimationClip, MeshStandardMaterial } from 'three';

type AnimatedFDAModelProps = {
  setAnimationContext: (context: UseAnimationsReturnType) => void;
  setCurrentFrame: (frame: number) => void;
  animations: AnimationClip[];
  nodes: {
    mesh_0: THREE.Mesh;
  };
} & JSX.IntrinsicElements['group'];

export const AnimatedFDAModel: FC<AnimatedFDAModelProps> = ({
  setAnimationContext,
  setCurrentFrame,
  animations,
  nodes,
  ...props
}) => {
  const group = useRef<any>();
  const useAnimationReturn = useAnimations(animations, group);

  useEffect(() => {
    if (useAnimationReturn.actions) {
      setAnimationContext(useAnimationReturn);
    }
  }, [useAnimationReturn, setAnimationContext]);

  useEffect(() => {
    if (nodes.mesh_0.material) {
      const material = nodes.mesh_0.material as MeshStandardMaterial;
      // Apply your changes to the material here
      material.color.set('#006ead');
      material.metalness = 0;
      material.roughness = 0;
    }
  }, [nodes.mesh_0.material]);

  useFrame(() => {
    if (useAnimationReturn.actions) {
      const currentTime = useAnimationReturn.actions.animation_0?.time || 0;
      const fps = 60;
      const currentFrame = Math.floor(currentTime * fps);
      setCurrentFrame(currentFrame);
    }
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <mesh
          scale={[2, 2, 2]}
          name='mesh_0'
          castShadow
          receiveShadow
          geometry={nodes.mesh_0.geometry}
          material={nodes.mesh_0.material}
          morphTargetDictionary={nodes.mesh_0.morphTargetDictionary}
          morphTargetInfluences={nodes.mesh_0.morphTargetInfluences}
        />
      </group>
    </group>
  );
};

interface FDAMeshViewerProps {
  setAnimationContext: (context: UseAnimationsReturnType) => void;
  setCurrentFrame: (frame: number) => void;
  animations: AnimationClip[];
  nodes: {
    mesh_0: THREE.Mesh;
  };
}

export const FDAMeshViewer: FC<FDAMeshViewerProps> = ({
  setAnimationContext,
  setCurrentFrame,
  animations,
  nodes,
}) => {
  return (
    <Canvas
      style={{
        height: 'calc(100vh - 180px)',
        minHeight: 400,
      }}
      camera={{
        position: [-5, 4, 4],
      }}
    >
      <ambientLight intensity={1} color={0xffffff} />
      <directionalLight
        castShadow
        position={[2.5, 5, 5]}
        intensity={1}
        shadow-mapSize={[1024, 1024]}
      >
        <orthographicCamera
          attach='shadow-camera'
          args={[-5, 5, 5, -5, 1, 50]}
        />
      </directionalLight>
      {/* <ambientLight intensity={0.9} color={0xffffff} /> */}
      {/* <spotLight position={[10, 10, 10]} /> */}

      <pointLight position={[-10, -10, -10]} decay={0} intensity={0.5} />
      <AnimatedFDAModel
        setAnimationContext={setAnimationContext}
        setCurrentFrame={setCurrentFrame}
        animations={animations}
        nodes={nodes}
      />

      <GizmoHelper alignment='bottom-right' margin={[100, 100]}>
        <GizmoViewport labelColor='white' axisHeadScale={1} />
      </GizmoHelper>
      <OrbitControls makeDefault target={[0, 0, 0]} />
      <DreiGrid
        infiniteGrid
        position={[0, -0.01, 0]}
        cellSize={5}
        cellThickness={1}
        sectionSize={0}
        sectionThickness={0}
        cellColor={'#6f6f6f'}
      />
    </Canvas>
  );
};
