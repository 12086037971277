import { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AuthCard,
  AuthCardHeader,
  AuthCardTitle,
  ConfirmCardHeader,
} from '../ui';
import { ButtonContainer } from './styles';
import { enqueueSnackbar } from 'notistack';
import { useDeviceType } from '../../hooks/useDeviceType';
import { appStoreURL, playStoreURL, appDeepLink } from './constants';

export const SuccessView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const deviceType = useDeviceType();

  const passwordResetState = location.state;

  const handleDeepLink = () => {
    const openAppStore = () => {
      window.location.href = deviceType === 'iOS' ? appStoreURL : playStoreURL;
    };

    if (deviceType === 'iOS' || deviceType === 'android') {
      window.location.href = appDeepLink;

      setTimeout(() => {
        if (document.visibilityState === 'visible') {
          openAppStore();
        }
      }, 1000);
    }
  };

  const renderButtons = () => {
    if (deviceType === 'iOS' || deviceType === 'android') {
      return (
        <Button
          type='button'
          onClick={handleDeepLink}
          sx={{ minWidth: '25%', width: '45%' }}
        >
          Mobile Login
        </Button>
      );
    } else {
      return (
        <Button
          type='button'
          onClick={() => navigate('/')}
          sx={{ minWidth: '25%', width: '45%' }}
        >
          Web Login
        </Button>
      );
    }
  };

  useEffect(() => {
    if (passwordResetState && passwordResetState.passwordReset) {
      enqueueSnackbar('Password set successfully', {
        variant: 'success',
        preventDuplicate: true,
      });
      navigate(location.pathname, { replace: true });
    }
  }, [passwordResetState, navigate, location.pathname]);

  return (
    <AuthCard>
      <AuthCardHeader>
        <ConfirmCardHeader>
          <CheckCircleRoundedIcon
            style={{
              color: 'green',
              marginBottom: '12px',
              fontSize: '2rem',
            }}
          />
          <AuthCardTitle variant='h1' style={{ marginLeft: '0.5rem' }}>
            Success!
          </AuthCardTitle>
        </ConfirmCardHeader>
        <Typography variant='bodyMedium'>
          Your password has been set successfully.
        </Typography>
        <ButtonContainer style={{ marginTop: '0.5vw' }}>
          {renderButtons()}
        </ButtonContainer>
      </AuthCardHeader>
    </AuthCard>
  );
};
