import { request } from '../../../network';

export interface ContactUsData {
  sender_name: string;
  sender_email: string;
  sender_message: string;
}

export const contactUs = async (
  data: ContactUsData
): Promise<ContactUsData> => {
  const response = await request({
    path: '/api/contact_us/',
    method: 'POST',
    withAuth: false,
    body: data,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};
