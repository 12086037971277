import { ScanModel } from '../../scan';
import { PreloadUrl } from '../../scan/components/ScanItemViewer/MeshViewer/types';

export const createMeshViewerLoadUrls = (
  scanModelArray: ScanModel[]
): PreloadUrl[] => {
  const formatted = scanModelArray.flatMap((item) => {
    const bentData = {
      mesh_url: item.bent_mesh_stream ?? '',
      deviation_mesh_url:
        item.featureset_values?.[0]?.bent_deviation_mesh_stream ?? null,
      mesh_texture_url: item?.bent_mesh_texture_stream ?? null,
      mesh_metadata: item.featureset_values?.[0]?.mesh_metadata ?? null,
    };

    const standingData = {
      mesh_url: item.standing_mesh_stream ?? '',
      deviation_mesh_url: null,
      mesh_texture_url: item.standing_mesh_texture_stream ?? null,
      mesh_metadata: item.featureset_values?.[0]?.mesh_metadata ?? null,
    };

    return [standingData, bentData];
  });

  return formatted;
};
