import { useCallback, useState } from 'react';
import { updateUser } from '../network';
import { UserModel } from '../types';

export const useUserUpdate = (): [
  UserModel | null,
  boolean,
  Error | null,
  (data: UserModel) => void,
] => {
  const [result, setResult] = useState<UserModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async (data: UserModel) => {
    setIsLoading(true);
    setResult(null);
    setError(null);

    try {
      const response = await updateUser(data);
      setResult(response);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        const err = new Error('Unknown error');
        setError(err);
      }
    }

    setIsLoading(false);
  }, []);

  return [result, isLoading, error, request];
};
