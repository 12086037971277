import { styled } from '@mui/material/styles';

interface DataGridContainerProps {
  loading?: boolean;
  height?: string;
}

export const DataGridContainer = styled('div')<DataGridContainerProps>`
  position: relative;
  display: flex;
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  justify-content: ${(props) => (props.loading ? 'center' : '')};
  align-items: ${(props) => (props.loading ? 'center' : '')};
`;

export const tableStyles = ({ height }: DataGridContainerProps) => ({
  position: 'relative' as const,
  paddingLeft: '1vw',
  paddingRight: '1vw',
  maxHeight: `${height}`,
  overflow: 'auto',
  backgroundColor: 'white',
  borderRadius: '1rem',
  borderColor: 'white',
});

export const NoRowsOverlayContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
