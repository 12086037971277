import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  DialogStyled,
  CloseModalButton,
  ModalHeader,
  ModalTitle,
} from './styles';

export interface ModalProps {
  open?: boolean;
  onClose?: () => void;
  title?: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
}

export const Modal: FC<ModalProps> = (props) => {
  const { open = false, title, subtitle, children, onClose } = props;

  return (
    <DialogStyled open={open} onClose={onClose} scroll='body'>
      <CloseModalButton aria-label='close' onClick={onClose}>
        <CloseIcon />
      </CloseModalButton>
      {(title || subtitle) && (
        <ModalHeader>
          {title && (
            <ModalTitle>
              <Typography variant='h2'>{title}</Typography>
            </ModalTitle>
          )}
          {subtitle && <Typography variant='bodyMedium'>{subtitle}</Typography>}
        </ModalHeader>
      )}
      {children}
    </DialogStyled>
  );
};
