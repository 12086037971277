import { useCallback } from 'react';
import { Button, Typography } from '@mui/material';
import { ValidationErrors } from 'final-form';
import { Form } from 'react-final-form';
import { CheckboxInputFF, FormStackVertical, TextInputFF } from '../../../ui';
import { useSignin } from '../../hooks';
import { AuthCard, AuthCardHeader, AuthCardTitle } from '../ui';
import { ButtonContainer, RecoveryLink, RecoveryLinkContainer } from './styles';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

const initialValues = {
  remember: true,
};

export const Signin = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const passwordResetState = location.state;
  const [, loading, , request] = useSignin();

  useEffect(() => {
    if (passwordResetState && passwordResetState.passwordReset) {
      enqueueSnackbar('Password set successfully', {
        variant: 'success',
        preventDuplicate: true,
      });
      navigate(location.pathname, { replace: true });
    }
  }, [passwordResetState, navigate, location.pathname]);

  const onSubmit = useCallback(
    (values: any) => {
      request(values);
    },
    [request]
  );

  const validate = useCallback((values: any) => {
    const result: ValidationErrors = {};
    if (!values.username) {
      result.username = true;
    }
    if (!values.password) {
      result.password = true;
    }

    return result;
  }, []);

  return (
    <AuthCard>
      <AuthCardHeader>
        <AuthCardTitle variant='h1'>Login</AuthCardTitle>
        <Typography variant='bodyMedium'>
          Welcome back to Momentum Spine!
        </Typography>
      </AuthCardHeader>
      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormStackVertical>
              <TextInputFF
                label='Email*'
                name='username'
                placeholder='email@example.com'
              />

              <TextInputFF
                label='Password'
                name='password'
                placeholder='8+ Characters'
                type='password'
              />

              <ButtonContainer>
                <CheckboxInputFF name='remember' label='Remember me' />
                <Button
                  type='submit'
                  disabled={loading}
                  sx={{ width: '132px' }}
                >
                  Login
                </Button>
              </ButtonContainer>

              <RecoveryLinkContainer>
                <RecoveryLink to='/recovery'>
                  <Typography variant='button'>
                    Forgot your password?
                  </Typography>
                </RecoveryLink>
              </RecoveryLinkContainer>
            </FormStackVertical>
          </form>
        )}
      />
    </AuthCard>
  );
};
