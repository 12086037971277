import jwtDecode from 'jwt-decode';
import { Listener, ListenerEventMap } from '../../../utils/Listener';
import { logout, refreshAuthToken } from '../network';
import { authStorage } from '../storage';
import { JwtTokenData, AuthData } from '../types';

interface AuthServiceEventMap extends ListenerEventMap {
  change: {
    initialized: boolean;
    authorized: boolean;
  };
  beforeLogout: unknown;
}
class AuthService extends Listener<AuthServiceEventMap> {
  initialized: boolean = false;
  authorized: boolean = false;

  private refreshTokenPromise: Promise<void> | null = null;

  constructor() {
    super();
    this.initialize();
  }

  private triggerChange = () => {
    this.trigger('change', {
      initialized: this.initialized,
      authorized: this.authorized,
    });
  };

  private refreshAccessTokenHandler = async (): Promise<void> => {
    const authData = await this.getAuthData();
    if (!authData?.refreshToken) {
      this.refreshTokenPromise = null;
      throw new Error('Don\'t have a refresh token');
    }
    let result: Awaited<ReturnType<typeof refreshAuthToken>> | null = null;
    let error: Error | null = null;
    try {
      result = await refreshAuthToken({
        refresh: authData?.refreshToken,
      });
      if (result?.access) {
        await this.setAuthData({
          ...authData,
          refreshToken: result.refresh || authData.refreshToken,
          accessToken: result.access,
        });
        this.refreshTokenPromise = null;
        return;
      }
    } catch (e) {
      if (e instanceof Error) {
        error = e;
      }
    }
    this.refreshTokenPromise = null;
    throw error || new Error('Failed to refresh token');
  };

  private refreshAccessToken = async (): Promise<void> => {
    if (this.refreshTokenPromise) {
      return await this.refreshTokenPromise;
    }
    this.refreshTokenPromise = this.refreshAccessTokenHandler();
    return await this.refreshTokenPromise;
  };

  private checkAccessToken = async (): Promise<void> => {
    const authData = await this.getAuthData();
    if (!authData) {
      this.logout();
      throw new Error('No Auth Data');
    }
    const { exp } = jwtDecode<JwtTokenData>(authData.accessToken);

    const nowSeconds = Date.now() / 1000;

    if (exp - nowSeconds > 30) {
      // Has enough life, do nothing
      return;
    }

    if (authData.refreshToken) {
      const { exp: refreshExp } = jwtDecode<JwtTokenData>(
        authData.refreshToken
      );
      if (refreshExp - nowSeconds > 1800) {
        // We can refresh
        await this.refreshAccessToken();
        return;
      }
    }

    this.logout();
    throw new Error('Your session has expired');
  };

  initialize = async (): Promise<void> => {
    const data = await authStorage.getAuthData();
    if (data) {
      try {
        await this.checkAccessToken();
        this.authorized = true;
      } catch (e) {
        this.authorized = false;
        this.logout();
      }
    } else {
      this.authorized = false;
    }
    this.initialized = true;
    this.triggerChange();
  };

  getAuthData = async (): Promise<AuthData | null> => {
    return authStorage.getAuthData();
  };

  setAuthData = async (authData: AuthData): Promise<void> => {
    if (!authData) {
      throw new Error('Token Data not provided');
    }
    await authStorage.setAuthData(authData);
    this.authorized = true;
    this.triggerChange();
  };

  logout = async (): Promise<void> => {
    await this.trigger('beforeLogout', null);
    try {
      const authData = await this.getAuthData();
      if (authData) {
        await logout({
          refresh: authData?.refreshToken,
        });
      }
    } catch (e) {
      // pass
    }
    authStorage.clear();
    this.authorized = false;
    this.triggerChange();
  };

  getValidAccessToken = async (): Promise<string> => {
    await this.checkAccessToken();
    const authData = await this.getAuthData();
    if (authData) {
      return authData.accessToken;
    }
    throw new Error('Don\'t have valid access token');
  };
}

export const authService = new AuthService();
