import { FC } from 'react';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { SuccessView } from '../../components';

export const ResetSuccessScreen: FC = () => {
  return (
    <>
      <DocumentTitle title='Reset Successful' />
      <SuccessView />
    </>
  );
};
