import { Mesh, Vector3, BoxGeometry, MeshBasicMaterial, Group } from 'three';
import { MeshMetaData } from '.';

const lerpColor = (a: number, b: number, t: number) => {
  let ax = a >> 16;
  let ay = (a >> 8) & 0xff;
  let az = a & 0xff;

  let bx = b >> 16;
  let by = (b >> 8) & 0xff;
  let bz = b & 0xff;

  let rx = Math.round(ax + t * (bx - ax));
  let ry = Math.round(ay + t * (by - ay));
  let rz = Math.round(az + t * (bz - az));

  return (rx << 16) + (ry << 8) + rz;
};

export const loadJSONData = (data: MeshMetaData) => {
  let modifiedData = {
    centroid_coordinates: data.centroid_coordinates.map(
      (coord) => new Vector3(coord[0], coord[1], coord[2])
    ),
    psedo_spine: data.pseudo_spine?.map(
      (coord) => new Vector3(coord[0], coord[1], coord[2])
    ),
    shoulder1_coordinates: new Vector3(
      data.shoulder1_coordinates[0],
      data.shoulder1_coordinates[1],
      data.shoulder1_coordinates[2]
    ),
    shoulder2_coordinates: new Vector3(
      data.shoulder2_coordinates[0],
      data.shoulder2_coordinates[1],
      data.shoulder2_coordinates[2]
    ),
    layer_rotations: data.layer_rotations,
    slice_thickness: data.slice_thickness,
    plumb_shift_plane: data.plumb_shift_plane,
    mid_sac_plane: data.msl_plane,
    trunk_shift_plane: data.trunk_plane,
  };

  return modifiedData;
};

export const create3DPlusSymbol = (position: Vector3, size = 0.005) => {
  const group = new Group();

  const plusMaterial = new MeshBasicMaterial({ color: 0xff0000 });

  const verticalGeometry = new BoxGeometry(size, size * 5, size); // Y-axis
  const horizontalGeometry = new BoxGeometry(size * 5, size, size); // X-axis
  const depthGeometry = new BoxGeometry(size, size, size * 5); // Z-axis (Forward-Backward)

  const vertical = new Mesh(verticalGeometry, plusMaterial);
  const horizontal = new Mesh(horizontalGeometry, plusMaterial);
  const depth = new Mesh(depthGeometry, plusMaterial);

  group.add(vertical);
  group.add(horizontal);
  group.add(depth);

  group.position.set(position.x, position.y + 0.005, position.z);

  return group;
};

export const create3DSpineSegment = (
  position: Vector3,
  rotationAmount: number,
  height: number,
  size = 0.005
) => {
  const group = new Group();

  // Normalize rotationAmount (0 means 0 degree, 1 means 2 degrees)
  let normalizedRotation = Math.min(Math.max(rotationAmount / 2, 0), 1); // Ensure it's between 0 and 1

  const color = lerpColor(0x00ff00, 0xff0000, normalizedRotation); // Green to Red
  const spineMaterial = new MeshBasicMaterial({
    color,
    transparent: true,
    opacity: 0.7,
  });

  // Only horizontal and forward-backward geometries
  const horizontalGeometry = new BoxGeometry(size * 5, size * 4.5, size);
  const forwardBackwardGeometry = new BoxGeometry(size, size * 4.5, size * 5);

  const horizontal = new Mesh(horizontalGeometry, spineMaterial);
  const forwardBackward = new Mesh(forwardBackwardGeometry, spineMaterial);

  group.add(horizontal, forwardBackward);
  group.position.set(position.x, position.y, position.z);

  return group;
};
