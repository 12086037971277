import { ReactNode } from 'react';
import { TabPanelWrapper } from './styles';

export interface TabPanelProps {
  children?: ReactNode;
  id?: string | number;
  current?: string | number;
  isFirst?: boolean;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, id, current, isFirst, ...other } = props;

  return (
    <TabPanelWrapper
      role='tabpanel'
      hidden={id !== current}
      id={`tabpanel-${id}`}
      aria-labelledby={`tab-${id}`}
      isFirst={isFirst}
      {...other}
    >
      {id === current && children}
    </TabPanelWrapper>
  );
};
