import { FC, ReactNode } from 'react';
import { Footer, Header } from './styles';

export interface ListLayoutProps {
  children: ReactNode;
}

export const ListLayout: FC<ListLayoutProps> = (props) => {
  const { children } = props;

  return (
    <div>
      <Header>
        <div>
          {
            // #TODO: filters and controls
          }
        </div>
      </Header>
      {children}
      <Footer></Footer>
    </div>
  );
};
