import { useCallback, useState } from 'react';
import { useCurrentUser } from '../../account';
import { interceptedSuperRecordsUrl } from '../../../network/apiClient';
import { ValidationErrors } from 'final-form';
import { Button, Grid, styled } from '@mui/material';
import { Modal, TextInputFF } from '../../ui';
import { Form } from 'react-final-form';

export const GenerateMLModel = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [user] = useCurrentUser();

  const onSubmit = (values: any) => {
    if (user === null) {
      setMessage('User not found');
      return;
    }

    setMessage(
      'Model params generated. Please check the dev console by pressing F12'
    );

    console.log({
      model_name: values?.model_name,
      user_id: user.id,
      queryUrl: interceptedSuperRecordsUrl.url,
    });
  };

  const validate = useCallback((values: any) => {
    const result: ValidationErrors = {};

    if (!values.model_name) {
      result.model_name = true;
    }

    return result;
  }, []);

  return (
    <div>
      <StyledToolbarMLModelButton onClick={() => setOpen(true)}>
        Generate ML Model
      </StyledToolbarMLModelButton>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <h3>Create a new ML Model Dataset</h3>
          <p>
            Generates a url that contains the exact same filters as the table.
            The url params are already sorted alphabetically. This means that
            duplicate filters can be cached.
          </p>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, dirty }) => (
              <form onSubmit={handleSubmit}>
                <Grid container rowGap={4}>
                  <TextInputFF
                    helperText=''
                    name='model_name'
                    label='Model Name'
                    fullWidth
                  />

                  <Button
                    type='submit'
                    variant='containedLight'
                    // disabled={!dirty || updating || loading}
                  >
                    Submit
                  </Button>
                </Grid>
              </form>
            )}
          />

          <p>{message}</p>
        </div>
      </Modal>
    </div>
  );
};

const StyledToolbarMLModelButton = styled(Button)({
  background: 'white',
  color: 'black',
  '&:hover': {
    background: '#f0f0f0',
  },
});
