import { css, styled } from '@mui/material';

export const Wrapper = styled('div')`
  height: calc(100vh - 340px);
  position: relative;
  border-radius: 10px;
  background: #f6f6f6;
  overflow: hidden;
`;

export const Content = styled('div')`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
`;

export const EmptyContainer = styled('div')<{
  isOver?: boolean;
  dragging?: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #f6f6f6;
  border: 3px dashed #e6e6e6;
  color: #353535;

  ${({ dragging }) =>
    dragging &&
    css`
      border: 3px dashed #7e66e1;
      background: #f1eeff;
      color: #52489c;
    `}
  ${({ isOver }) =>
    isOver &&
    css`
      transform: scale(1.05);
    `}


  transition: ${({ theme }) =>
    theme.transitions.create([
      'background',
      'color',
      'border-color',
      'transform',
      'opacity',
    ])};
`;

export const DropContainer = styled(EmptyContainer)`
  pointer-events: none;
  opacity: 0;

  ${({ dragging }) =>
    dragging &&
    css`
      opacity: 0.9;
    `}
`;

export const MoveIconContainer = styled('div')`
  margin-bottom: 16px;
  font-size: 32px;
  line-height: 0;
`;
