import { Listener } from '../../../utils/Listener';

class PatientNamesService extends Listener {
  names: { [id: string | number]: string } = {};

  addPatientName = (id: string | number, fullName: string) => {
    this.names[id] = fullName;
    this.trigger('change', null);
  };

  getPatientName = (id: string | number) => {
    return this.names[id] || null;
  };
}

export const patientNamesService = new PatientNamesService();
