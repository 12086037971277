import { useEffect, useState } from 'react';
import { authService } from '../../auth';
import { FDAItem, FDAItemApiResponse } from '../types';
import { getFunctionalDynamicAssessmentItem } from '../network';

const validateFDAItemApiResponse = (data: FDAItemApiResponse): FDAItem => {
  if (data.standing.output_stream === null) {
    throw new Error('Standing output stream is null');
  }

  if (data.walking.output_stream === null) {
    throw new Error('Walking output stream is null');
  }

  return {
    ...data,
    standing: {
      ...data.standing,
      output_stream: data.standing.output_stream,
    },
    walking: {
      ...data.walking,
      output_stream: data.walking.output_stream,
    },
  };
};

export const useFunctionalDynamicAssessment = (
  fdaId: number | string | null
) => {
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [data, setData] = useState<FDAItem | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getAuthToken = async () => {
    const accessToken = await authService.getValidAccessToken();
    setAuthToken(accessToken);
  };

  const getFDAData = async (id: number) => {
    try {
      setLoading(true);
      const response = await getFunctionalDynamicAssessmentItem(id);
      const lData = validateFDAItemApiResponse(response);
      setData(lData);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAuthToken();

    if (fdaId) {
      const id = Number(fdaId);
      if (!isNaN(id)) {
        getFDAData(id);
      }
    }
  }, [fdaId]);

  return {
    data,
    authToken,
    loading,
    error,
  };
};
