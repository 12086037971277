import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Popper,
  Select,
  Tooltip,
} from '@mui/material';
import { FC, useState } from 'react';
import { useScanCompareContext } from '../useScanCompareContext';
import {
  CollapseIcon,
  ControlsButton,
  ExpandIcon,
  HeaderContainer,
  InformationCircleIcon,
  LayerRow,
  LayersContainer,
  MeshLegendIcons,
  RowContainer,
  Title,
} from '../../ScanItemViewer/styles';
import {
  ViewModeLabel,
  layersInfo,
} from '../../ScanItemViewer/MeshViewer/layers';
import { OrbitControls } from '@three-ts/orbit-controls';

class SyncedOrbitControls {
  isSplitMode: boolean = false;
  oc1: OrbitControls | undefined = undefined;
  oc2: OrbitControls | undefined = undefined;
  isUpdating: boolean = false;
  syncEnabled: boolean = false;

  setSyncEnabled(value: boolean) {
    this.syncEnabled = value;
  }

  setOrbitControls(
    orbitControl: OrbitControls,
    controllerName: 'first' | 'second'
  ) {
    if (controllerName === 'first') {
      this.oc1 = orbitControl;
      this.oc1.addEventListener('change', (e: any) =>
        this.handleChange(e, controllerName)
      );
    }

    if (controllerName === 'second') {
      this.oc2 = orbitControl;
      this.oc2.addEventListener('change', (e: any) =>
        this.handleChange(e, controllerName)
      );
    }
  }

  setSplitMode(splitMode: boolean) {
    this.isSplitMode = splitMode;
  }

  syncControl(sourceControls: OrbitControls, targetControls: OrbitControls) {
    const sourceCamera = sourceControls.object;
    targetControls.object.position.copy(sourceCamera.position);
    targetControls.target.copy(sourceControls.target);
    targetControls.update();
  }

  handleChange = (_e: any, controllerName: 'first' | 'second') => {
    if (!this.isSplitMode) {
      return;
    }

    // do not run if we are currently updating
    // prevents infinite loop of reacting to changes ctlr1 -> ctlr2 -> ctlr1 -> ...
    if (this.isUpdating) {
      return;
    }

    // allow the user to disable syncing
    if (this.syncEnabled === false) {
      return;
    }

    try {
      // we need this updating flag to prevent infinite loop
      this.isUpdating = true;

      const ctrl1 = this.oc1;
      const ctrl2 = this.oc2;

      // ctrl represents the orbit control instance for each scene
      if (!ctrl1 || !ctrl2) return;
      const camera2 = ctrl2.object;
      const camera1 = ctrl1.object;

      // make absolutely sure that both cameras are initialized
      if (!camera1.isCamera || !camera2.isCamera) {
        return;
      }

      if (controllerName === 'first' && ctrl1 && ctrl2) {
        this.syncControl(ctrl1, ctrl2);
      }
      if (controllerName === 'second' && ctrl1 && ctrl2) {
        this.syncControl(ctrl2, ctrl1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      // always set updating to false when done
      this.isUpdating = false;
    }
  };
}

export const SyncedOrbitControlsInstance = new SyncedOrbitControls();

interface ScanSyncProps {}

export const ScanSync: FC<ScanSyncProps> = (props) => {
  const { splitMode, synced, setSynced } = useScanCompareContext();

  const handleChange = () => {
    setSynced(!synced);
  };

  const shouldShowControls = splitMode && synced;

  return splitMode ? (
    <>
      <FormControlLabel
        control={<Checkbox onChange={handleChange} checked={synced} />}
        label='sync'
      />
      {shouldShowControls && (
        <>
          <MeshTypeControls />
          <Controls />
        </>
      )}
    </>
  ) : null;
};

const Controls: FC = () => {
  const { syncedLayerState, toggleSyncedLayer, meshType } =
    useScanCompareContext();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <ControlsButton onClick={handleClick}>
        <HeaderContainer>
          <Title>Controls</Title>
          {open ? (
            <CollapseIcon fontSize='inherit' />
          ) : (
            <ExpandIcon fontSize='inherit' />
          )}
        </HeaderContainer>
      </ControlsButton>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <LayersContainer>
          {layersInfo.map((value) => {
            return value.display_type === meshType ||
              value.display_type === 'dual' ? (
              <LayerRow id={value.id} key={value.id}>
                <RowContainer>
                  <MeshLegendIcons src={value.icon} alt={value.alt} />
                  <label
                    style={{
                      fontSize: '0.8rem',
                      textAlign: 'center',
                      lineHeight: 1.25,
                    }}
                  >
                    {value.label}{' '}
                  </label>
                  <Tooltip
                    title={value.description}
                    placement={'right'}
                    enterDelay={300}
                  >
                    <span>
                      <InformationCircleIcon fontSize='inherit' />
                    </span>
                  </Tooltip>
                </RowContainer>
                <input
                  type='checkbox'
                  checked={syncedLayerState[value.id]}
                  onChange={() => toggleSyncedLayer(value.id)}
                />
              </LayerRow>
            ) : null;
          })}
        </LayersContainer>
      </Popper>
    </>
  );
};

const MeshTypeControls = () => {
  const { meshType, setMeshType } = useScanCompareContext();

  const onChangeViewMode = (e: any) => {
    setMeshType(e.target.value);
  };

  return (
    <div>
      <Select
        value={meshType}
        onChange={onChangeViewMode}
        variant='filled'
        disableUnderline
      >
        <MenuItem value='bent'>{ViewModeLabel.bent}</MenuItem>
        <MenuItem value='standing'>{ViewModeLabel.standing}</MenuItem>
      </Select>
    </div>
  );
};
