import { css, styled, Typography } from '@mui/material';

export const ProgressionValue = styled(Typography)<{
  direction?: string;
}>`
  ${({ direction }) => {
    if (direction === 'up') {
      return css`
        color: #dd4234;
      `;
    }
    if (direction === 'down') {
      return css`
        color: #04bc00;
      `;
    }
  }}
`;

export const ProgressionContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
`;
