import { AuthData } from '../types';

const AUTH_DATA_KEY = 'auth';

class AuthStorage {
  getAuthData = async (): Promise<AuthData | null> => {
    const data = localStorage.getItem(AUTH_DATA_KEY);
    if (data) {
      try {
        const parsedData = JSON.parse(atob(data));
        return parsedData as AuthData;
      } catch (e) {
        // pass
      }
    }

    return null;
  };

  setAuthData = async (data: AuthData) => {
    const str = btoa(JSON.stringify(data));
    return localStorage.setItem(AUTH_DATA_KEY, str);
  };

  clear = async () => {
    localStorage.removeItem(AUTH_DATA_KEY);
  };
}

export const authStorage = new AuthStorage();
