import { FC } from 'react';
import {
  DataGridFieldBaseProps,
  DataGridFieldInjectedProps,
} from '../../../ui';
import { StatusContainer, StatusItem } from './styles';
import NewIcon from '../../../../assets/image/icons/bolt.png';
import ProcessingIcon from '../../../../assets/image/icons/clock.png';
import Completed from '../../../../assets/image/icons/check-circle.png';
import Failed from '../../../../assets/image/icons/minus-circle.png';
import { GridRenderCellParams } from '@mui/x-data-grid';

export type DataGridScanStatusFieldProps = DataGridFieldBaseProps &
  DataGridFieldInjectedProps;

export const DataGridScanStatusField: FC<DataGridScanStatusFieldProps> = (
  props
) => {
  const { record } = props;

  return (
    <StatusContainer>
      {record.status === 'Uploaded' ? (
        <StatusItem status='new'>
          <img alt='New' src={NewIcon}></img> New
        </StatusItem>
      ) : null}
      {record.status === 'Processing' ? (
        <StatusItem status='processing'>
          <img alt='Processing' src={ProcessingIcon}></img> Processing
        </StatusItem>
      ) : null}
      {record.status === 'Completed' ? (
        <StatusItem status='completed'>
          <img alt='Completed' src={Completed}></img> Completed
        </StatusItem>
      ) : null}
      {record.status === 'Failed' ? (
        <StatusItem status='failed'>
          <img alt='Failed' src={Failed}></img> Failed
        </StatusItem>
      ) : null}
    </StatusContainer>
  );
};

export const ScanStatusCellRenderer: FC<GridRenderCellParams> = (props) => {
  const { value: status } = props;

  return (
    <StatusContainer>
      {status === 'Uploaded' ? (
        <StatusItem status='new'>
          <img alt='New' src={NewIcon}></img> New
        </StatusItem>
      ) : null}
      {status === 'Processing' ? (
        <StatusItem status='processing'>
          <img alt='Processing' src={ProcessingIcon}></img> Processing
        </StatusItem>
      ) : null}
      {status === 'Completed' ? (
        <StatusItem status='completed'>
          <img alt='Completed' src={Completed}></img> Completed
        </StatusItem>
      ) : null}
      {status === 'Failed' ? (
        <StatusItem status='failed'>
          <img alt='Failed' src={Failed}></img> Failed
        </StatusItem>
      ) : null}
    </StatusContainer>
  );
};
