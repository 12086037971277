import { Button, Grid } from '@mui/material';
import { ValidationErrors } from 'final-form';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useEffect } from 'react';
import { Form } from 'react-final-form';
import { FormStackVertical, TextInputFF } from '../../../ui';
import { useAccountContext } from '../../containers';
import { useUserUpdate } from '../../hooks';

export const AccountEditForm: FC = () => {
  const { user, updating, reload } = useAccountContext();
  const [result, loading, , request] = useUserUpdate();

  const onSubmit = useCallback(
    (values: any) => {
      request(values);
    },
    [request]
  );

  const validate = useCallback((values: any) => {
    const result: ValidationErrors = {};

    if (!values.first_name) {
      result.first_name = true;
    }
    if (!values.last_name) {
      result.last_name = true;
    }

    return result;
  }, []);

  useEffect(() => {
    if (result) {
      enqueueSnackbar('Account updated successfully', { variant: 'success' });
      reload();
    }
  }, [result, reload]);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={user}
      render={({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container rowSpacing={0} columnSpacing={10} mt={0}>
            <Grid item xs={3.7}>
              <FormStackVertical>
                <TextInputFF name='first_name' label='First name' fullWidth />
                <TextInputFF name='last_name' label='Last name' fullWidth />
                <TextInputFF name='email' label='Email' fullWidth />
              </FormStackVertical>
            </Grid>
            <Grid item xs={3.7}>
              <FormStackVertical alignItems='flex-start'>
                <TextInputFF name='phone' label='Phone number' fullWidth />
                <TextInputFF name='npi_number' label='NPI #' fullWidth />
                <TextInputFF name='healthcare_role' label='Role' disabled fullWidth />
                <Button type='submit' variant='containedLight' disabled={!dirty || updating || loading}>
                  Save
                </Button>
              </FormStackVertical>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};
