import { Popover } from '@mui/material';
import { styled } from '@mui/system';

export const Root = styled(Popover)`
  & .MuiPaper-root {
    ]position: relative;
    max-height: 500px;
    width: 512px;
    padding: 24px 0;
    margin-left: 20px;
    margin-top: 20px;
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0px 27px 107px rgba(105, 105, 106, 0.07),
      0px 6.0308px 23.8999px rgba(105, 105, 106, 0.0417275),
      0px 1.79553px 7.11561px rgba(105, 105, 106, 0.0282725);
  }
`;

export const PaddedContent = styled('div')`
  padding: 0 40px;
`;

export const StatusView = styled('div')`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotificationItem = styled('div')`
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  margin: 0 12px;
  padding: 12px 8px;
  border-radius: 8px;
  background: #ffffff;

  &:hover {
    background: #f3f3f3;
  }
`;

export const NotificationItemHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 16px;
`;

export const NotificationItemTitleContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
  gap: 8px;
`;

export const NotificationItemContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const UnreadDot = styled('div')`
  background: blue;
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

export const ActionItems = styled('div')`
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
`;

export const LoadMoreContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding: 16px;
`;
