import { Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AuthCard = styled(Card)`
  width: 512px;
  height: fit-content;
  margin-top: 30px;
  padding: 40px;
  border-radius: 24px;
  box-shadow: 0px 27px 107px rgba(55, 9, 130, 0.07),
    0px 6.0308px 23.8999px rgba(55, 9, 130, 0.0417275),
    0px 1.79553px 7.11561px rgba(55, 9, 130, 0.0282725);
`;

export const AuthCardHeader = styled('div')`
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
`;

export const AuthCardTitle = styled(Typography)`
  display: block;
  margin-bottom: 12px;
`;

export const ConfirmCardHeader = styled('div')`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
