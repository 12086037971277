import { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { InvitePatientModal } from '../InvitePatientModal';
import { AddButton, InvitePatientBody, InvitePatientWrapper } from './styles';
import { PlusGradientIcon } from '../../../icon/PlusGradientIcon';

export const InvitePatientWidget = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <InvitePatientWrapper>
        <InvitePatientBody onClick={handleOpen}>
          <Typography variant='h3' color='#fff'>
            Invite a new
            <br />
            Patient
          </Typography>
          <AddButton
            aria-label={'add'}
            color='primary'
            size='large'
            // onClick={handleOpen}
            // disableRipple
          >
            <PlusGradientIcon fontSize='inherit' />
          </AddButton>
        </InvitePatientBody>
      </InvitePatientWrapper>
      <InvitePatientModal open={open} onClose={handleClose} />
    </>
  );
};
