import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ScanModel, getScan } from '../../network';
import { ScanCompareContext } from './ScanCompareContext';
import { SyncedOrbitControlsInstance } from './ScanSync';
import { useScanLayerControls } from '../../hooks/useScanLayerControls';

export interface ScanCompareContainerProps {
  scans: Array<ScanModel>;
  children: ReactNode;
  selectedScanFirst?: ScanModel | null;
}

export const ScanCompareContainer: FC<ScanCompareContainerProps> = (props) => {
  const {
    scans,
    children,
    selectedScanFirst: selectedScanFirstProp = null,
  } = props;

  const [scanFirst, setScanFirst] = useState<ScanModel | null>(
    selectedScanFirstProp
  );
  const [scanSecond, setScanSecond] = useState<ScanModel | null>(null);
  const [splitMode, setSplitMode] = useState<boolean>(false);
  const [synced, setSynced] = useState(SyncedOrbitControlsInstance.syncEnabled);
  const { layerState, toggleLayer, meshType, setMeshType } =
    useScanLayerControls();
  const [scanCache, setScanCache] = useState<ScanModel[]>([]);
  const [loading, setLoading] = useState(false);

  const lastSelectedField = useRef<'first' | 'second' | null>(null);

  const getScanById = useCallback(
    async (id: number) => {
      if (!id) {
        return;
      }

      const scan = scanCache.find((scan) => scan.id === id);
      if (scan) {
        return scan;
      }

      try {
        setLoading(true);
        const result = await getScan(id);
        setScanCache([...scanCache, result]);
        return result;
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [scanCache, setScanCache]
  );

  const selectScan = useCallback(
    async (scan: ScanModel, position?: 'first' | 'second') => {
      const scanToDisplay = await getScanById(scan.id);
      if (!scanToDisplay) {
        return;
      }
      if (position === 'first') {
        setScanFirst(scanToDisplay);
        return;
      }
      if (position === 'second') {
        setScanSecond(scanToDisplay);
        return;
      }
      if (splitMode) {
        if (scanSecond === null) {
          lastSelectedField.current = 'second';
          setScanSecond(scanToDisplay);
        } else if (scanFirst === null) {
          lastSelectedField.current = 'first';
          setScanFirst(scanToDisplay);
        } else if (lastSelectedField.current === 'first') {
          lastSelectedField.current = 'second';
          setScanSecond(scanToDisplay);
        } else {
          lastSelectedField.current = 'first';
          setScanFirst(scanToDisplay);
        }
      } else {
        lastSelectedField.current = 'first';
        setScanFirst(scanToDisplay);
      }
    },
    [splitMode, scanSecond, scanFirst, getScanById]
  ); // #TODO: refacting (Not good use this data in dependencies)

  useEffect(() => {
    SyncedOrbitControlsInstance.setSplitMode(splitMode);
  }, [splitMode]);

  useEffect(() => {
    // keep the SyncedOrbitControlsInstance... synced...
    SyncedOrbitControlsInstance.setSyncEnabled(synced);
  }, [synced]);

  const contextValue = useMemo(() => {
    return {
      scans,
      selectedScanFirst: scanFirst,
      selectedScanSecond: splitMode ? scanSecond : null,
      splitMode,
      synced,
      syncedLayerState: layerState,
      meshType,
      loading,
      setMeshType,
      toggleSyncedLayer: toggleLayer,
      setSynced,
      setSelectedScanFirst: (item: ScanModel) => selectScan(item, 'first'),
      setSelectedScanSecond: (item: ScanModel) => selectScan(item, 'second'),
      setSplitMode,
      selectScan,
    };
  }, [
    meshType,
    scans,
    scanFirst,
    scanSecond,
    splitMode,
    synced,
    layerState,
    loading,
    setSynced,
    selectScan,
    toggleLayer,
    setMeshType,
  ]);

  return (
    <ScanCompareContext.Provider value={contextValue}>
      {children}
    </ScanCompareContext.Provider>
  );
};
