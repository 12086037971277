import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export const BreadcrumbItem = styled(NavLink)`
  color: #878787;
  text-decoration: none;

  &:hover,
  &.active {
    color: #353535;
  }
`;
