import { Card, ListItemButton, styled, Typography } from '@mui/material';

export const Wrapper = styled(Card)``;

export const Title = styled(Typography)`
  padding: 24px 24px 12px;
`;

export const Content = styled('div')`
  height: calc(100vh - 278px);
  overflow: scroll;
`;

export const ScanItemWrapper = styled(ListItemButton)`
  padding: 0;

  &.Mui-selected {
    background: #f1eeff;
  }
  & .MuiListItemButton-root {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 24px;
  }
`;

export const ScanItemTitle = styled(Typography)``;

export const ScanItemProperty = styled(Typography)`
  color: #878787;
`;

export const ScanItemPropertyValue = styled('span')<{
  valid?: boolean;
}>`
  color: ${({ theme, valid }) =>
    valid ? theme.palette.success.main : theme.palette.error.main};

  &:before {
    content: ' ';
  }
`;
