import { FC, ReactNode, useCallback, useState } from 'react';
import { DeleteAccountModal } from '../DeleteAccountModal';

export interface DeleteAccountButtonProps {
  render: (data: { handleOpen: () => void }) => ReactNode;
}

export const DeleteAccountButton: FC<DeleteAccountButtonProps> = (props) => {
  const { render } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      {render && render({ handleOpen })}
      <DeleteAccountModal open={open} onClose={handleClose} />
    </>
  );
};
