import { styled } from '@mui/material';

export const Wrapper = styled('div')`
  padding: 26px 32px;
`;

export const Header = styled('div')`
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 26px;
`;

export const MeshViewContainer = styled('div')`
  position: relative;
`;

export const LoadingContainer = styled('div')`
  position: absolute;
  z-index: 1000;
  height: 100%;
  width: 100%;
`;
