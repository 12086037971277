import { useState } from 'react';
import { handlePatientRequest } from '../network';

interface UseSignupRequestsProps {
  reload: () => void;
}

export const useSignupRequest = ({ reload }: UseSignupRequestsProps) => {
  const [result, setResult] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const acceptPatient = async (userId: number | string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await handlePatientRequest({ userId, accepted: true });
      setResult(response);
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setError(e);
      } else {
        const error = new Error('Unknown error');
        setError(error);
      }
    }

    setIsLoading(false);
    reload();
  };

  const rejectPatient = async (userId: number | string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await handlePatientRequest({ userId, accepted: false });
      setResult(response);
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setError(e);
      } else {
        const error = new Error('Unknown error');
        setError(error);
      }
    }

    setIsLoading(false);
    reload();
  };

  return {
    result,
    isLoading,
    error,
    acceptPatient,
    rejectPatient,
  };
};
