import { FC } from 'react';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { ListContainer, ListLayout, ListTitle } from '../../../ui';
import { PatientDataGrid } from '../../components';
import { getPatientList } from '../../network';

export const PatientListScreen: FC = () => {
  return (
    <>
      <ListContainer dataRequest={getPatientList}>
        <DocumentTitle title='Patients' />
        <ListTitle variant='h1'>Patients</ListTitle>
        <ListLayout>
          <PatientDataGrid height='80vh' />
        </ListLayout>
      </ListContainer>
    </>
  );
};
