import { useXScale, useYScale } from '@mui/x-charts';
import { FC } from 'react';

interface CurrentPositionCircleProps {
  x: number;
  y: number;
  color?: string;
}

export const CurrentPositionCircle: FC<CurrentPositionCircleProps> = ({
  x,
  y,
  color = 'red',
}) => {
  const xScale = useXScale();
  const yScale = useYScale();

  const xPosition = xScale(x) || 0;
  const yPosition = yScale(y) || 0;

  return (
    <>
      <circle
        cx={xPosition}
        cy={yPosition}
        r={3}
        style={{
          fill: color,
          stroke: color,
          strokeWidth: 1,
        }}
      />
    </>
  );
};
