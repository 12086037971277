import { createContext } from 'react';
import { AuthData } from '../../types';

export interface AuthContextValue {
  initialized: boolean;
  authorized: boolean;
  setAuthData: (AuthData: AuthData) => Promise<void>;
  logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextValue>(
  {} as AuthContextValue
);
