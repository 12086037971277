import { FC, useCallback, useEffect } from 'react';
import { Button } from '@mui/material';
import { ValidationErrors } from 'final-form';
import { Form } from 'react-final-form';
import {
  CheckboxInputFF,
  FormStackVertical,
  Modal,
  ModalProps,
  TextInputFF,
} from '../../../ui';
import { useEmployeeCreate } from '../../hooks';
import { enqueueSnackbar } from 'notistack';

export type AddEmployeeModalProps = ModalProps & {
  onClose?: (reason?: 'cancel' | 'success') => void;
};

export const AddEmployeeModal: FC<AddEmployeeModalProps> = (props) => {
  const { open = false, onClose } = props;
  const [result, loading, , request] = useEmployeeCreate();

  const onSubmit = useCallback(
    (values: any) => {
      request(values);
    },
    [request]
  );

  const validate = useCallback((values: any) => {
    const result: ValidationErrors = {};

    if (!values.first_name) {
      result.first_name = true;
    }
    if (!values.last_name) {
      result.last_name = true;
    }
    if (!values.email) {
      result.email = true;
    }

    return result;
  }, []);

  useEffect(() => {
    if (result) {
      enqueueSnackbar('Employee created successfully', { variant: 'success' });
      if (onClose) {
        onClose('success');
      }
    }
  }, [result, onClose]);

  return (
    <Modal open={open} onClose={onClose} title='Add Member'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormStackVertical alignItems='center'>
              <TextInputFF name='healthcare_role' label='Title' fullWidth />
              <TextInputFF name='first_name' label='First name' fullWidth />
              <TextInputFF name='last_name' label='Last name' fullWidth />
              <TextInputFF name='email' label='Email' fullWidth />
              <TextInputFF name='phone' label='Phone' fullWidth />
              <CheckboxInputFF name='is_admin' label='Is admin' fullWidth />
              <Button type='submit' disabled={loading}>
                Submit
              </Button>
            </FormStackVertical>
          </form>
        )}
      />
    </Modal>
  );
};
