import { FC, useState, useContext, useRef } from 'react';
import { DataGridResendIcon, DataGridTable, ListContext } from '../../../ui';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { EmployeeModel } from '../../network';
import {
  formatNullValues,
  TRowClickFunction,
} from '../../../ui/components/DataGridPro/helpers';
import { CombinedTableModel } from '../../../ui';

interface EmployeeDataGridProps {
  height: string;
}

export const EmployeeDataGrid: FC<EmployeeDataGridProps> = (props) => {
  const { height } = props;
  const { data, loading } = useContext(ListContext);
  const [selectedRowID, setSelectedRowID] = useState<number | null>(null);

  const buttonHoverRef = useRef(false);

  const handleMouseOver = () => {
    buttonHoverRef.current = true;
  };

  const handleMouseOut = () => {
    buttonHoverRef.current = false;
  };

  const onRowClick: TRowClickFunction<any> = (
    item: GridRowParams<EmployeeModel>
  ) => {
    setSelectedRowID(Number(item.id));
  };

  const columns: GridColDef<CombinedTableModel>[] = [
    {
      field: 'first_name',
      headerName: 'First Name',
      flex: 1,
      minWidth: 125,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      flex: 1,
      minWidth: 125,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      minWidth: 125,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'healthcare_role',
      headerName: 'Healthcare Role',
      flex: 1,
      minWidth: 125,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: '',
      headerName: 'Actions',
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => {
        const row = params.row as EmployeeModel;
        return (
          <>
            <DataGridResendIcon
              label=''
              record={params.row}
              source='email_verified'
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              selectedRowID={selectedRowID}
              resendtype='invitation'
              hide={row?.email_verified}
            />
          </>
        );
      },
    },
  ];

  return (
    <DataGridTable
      height={height}
      loading={loading}
      data={data}
      columns={columns}
      tableType='coordinator'
      onRowClick={onRowClick}
    />
  );
};
