import { FC } from 'react';
import { useGetQRCode } from '../hooks';
import { QrCode } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { Loader } from '../../ui';

export const QRCode: FC = () => {
  const { qrCode, isLoading, error } = useGetQRCode();

  const handleOpenQrCode = () => {
    if (qrCode) {
      window.open(qrCode, '_blank');
    }
  };

  const errorMsg =
    'There was an error generating the Sign-Up QR Code. Please contact support.';

  const successTooltip = 'View Sign-Up QR Code';

  const tooltip = error ? errorMsg : successTooltip;

  return (
    <div>
      {isLoading ? (
        <Loader size={20} />
      ) : (
        <Tooltip title={tooltip}>
          <IconButton onClick={handleOpenQrCode}>
            <QrCode />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};
