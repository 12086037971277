import { FC, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGridResendIcon } from '../../../ui';
import { TRowClickFunction } from '../../../ui/components/DataGridPro/helpers';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { DataGridProgressionField } from '../DataGridProgressionField';
import { ListContext } from '../../../ui';
import { PatientModel } from '../../network';
import {
  DataGridTable,
  CombinedTableModel,
} from '../../../ui/components/DataGridPro';
import {
  formatNullValues,
  formatMostRecentScanDate,
  formatNextScanDate,
} from '../../../ui/components/DataGridPro/helpers';

interface PatientDataGridProps {
  height: string;
}

export const PatientDataGrid: FC<PatientDataGridProps> = (props) => {
  const { height } = props;
  const { data, loading } = useContext(ListContext);
  const navigate = useNavigate();
  const [selectedRowID, setSelectedRowID] = useState<number | null>(null);

  const buttonHoverRef = useRef(false);

  const handleMouseOver = () => {
    buttonHoverRef.current = true;
  };

  const handleMouseOut = () => {
    buttonHoverRef.current = false;
  };

  const onRowClick: TRowClickFunction<any> = (
    item: GridRowParams<PatientModel>
  ) => {
    setSelectedRowID(Number(item.id));
    if (!buttonHoverRef.current) {
      navigate(`/patients/${item.id}`);
    }
  };

  const columns: GridColDef<CombinedTableModel>[] = [
    {
      field: 'first_name',
      headerName: 'First Name',
      flex: 1,
      minWidth: 125,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      flex: 1,
      minWidth: 125,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'age',
      headerName: 'Age',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'cobb_angle',
      headerName: 'AI Predicted Cobb Angle',
      flex: 1,
      minWidth: 175,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'progression_since_last_scan',
      headerName: 'Progression since last scan',
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
      renderCell: (params) => {
        return (
          <DataGridProgressionField
            record={params.row}
            label='Progression since last scan'
            source='progression_since_last_scan'
          />
        );
      },
    },
    {
      field: 'total_scans',
      headerName: 'Total scans',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'most_recent_scan_date',
      headerName: 'Most recent scan date',
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        return formatMostRecentScanDate(params.value);
      },
    },
    {
      field: 'next_scan_date',
      headerName: 'Next scan date',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return formatNextScanDate(params.value);
      },
      renderCell: (params) => {
        const { formattedDate, isPastDate } = params.value;
        return (
          <span style={{ color: isPastDate ? 'red' : 'inherit' }}>
            {formattedDate}
          </span>
        );
      },
    },
    {
      field: '',
      headerName: 'Actions',
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => {
        const patientRow = params.row as PatientModel;
        const { isPastDate } = formatNextScanDate(
          patientRow?.next_scan_date || null
        );
        return (
          <>
            <DataGridResendIcon
              label=''
              record={params.row}
              source='next_scan_date'
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              selectedRowID={selectedRowID}
              resendtype='next_scan'
              hide={!isPastDate}
            />

            <DataGridResendIcon
              label=''
              record={params.row}
              source='email_verified'
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              selectedRowID={selectedRowID}
              resendtype='invitation'
              hide={patientRow?.email_verified}
            />
          </>
        );
      },
    },
  ];

  return (
    <DataGridTable
      height={height}
      loading={loading}
      data={data}
      columns={columns}
      tableType='patient'
      onRowClick={onRowClick}
    />
  );
};
