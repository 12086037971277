import { Typography, Link as MuiLink } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ListContainer, ListFiltersState } from '../../../ui';
import { getScanList } from '../../network';
import { ScanDataGrid } from '../ScanDataGrid';
import { Header } from './styles';

export interface LastScansWidgetProps {
  title?: string;
  showLink?: boolean;
  filters?: ListFiltersState;
}

export const LastScansWidget: FC<LastScansWidgetProps> = (props) => {
  const { title = 'Latest Scans', showLink = true, filters } = props;
  return (
    <div>
      <Header>
        <Typography variant='h2'>{title}</Typography>
        {showLink && (
          <MuiLink
            component={Link}
            to='/scans'
            underline='always'
            color='primary'
          >
            View all list
          </MuiLink>
        )}
      </Header>
      <ListContainer dataRequest={getScanList} filters={filters}>
        <ScanDataGrid height='60vh' />
      </ListContainer>
    </div>
  );
};
