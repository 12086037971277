import { Outlet, Navigate } from 'react-router-dom';
import { AuthorizedLayout } from '../../components/AuthorizedLayout';
import { AccountContainer } from '../../modules/account';
import { useAuthContext } from '../../modules/auth';
import { FullscreenStatus } from '../../modules/ui';

export const AuthorizedContainer = () => {
  const { authorized, initialized } = useAuthContext();

  if (!initialized) {
    return <FullscreenStatus loading />;
  }

  if (!authorized) {
    return <Navigate to='/auth' />;
  }

  return (
    <AccountContainer>
      <AuthorizedLayout>
        <Outlet />
      </AuthorizedLayout>
    </AccountContainer>
  );
};
