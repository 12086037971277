import { FC, ReactElement } from 'react';
import {
  CardWrapper,
  CardBody,
  CardHeader,
  CardContent,
  TitleContainer,
  CardLoader,
} from './styles';
import { CardActionArea, Typography } from '@mui/material';
import { Badge } from '../Badge';
import { Loader } from '../Loader';

export interface CardProps {
  title?: string | null;
  children?: React.ReactNode;
  badgeLabel?: string | null;
  onClick?: () => void;
  rightActions?: ReactElement;
  loading?: boolean;
  height?: string;
}

export const Card: FC<CardProps> = ({
  title,
  badgeLabel,
  children,
  onClick,
  rightActions,
  loading = false,
  height,
}) => {
  const handleInternalClick = (e: any) => {
    e.stopPropagation();
  };

  let header = <></>;

  if (title || badgeLabel || rightActions) {
    header = (
      <CardHeader>
        <TitleContainer>
          <Typography variant='h3'>{title}</Typography>
          {badgeLabel && <Badge>{badgeLabel}</Badge>}
        </TitleContainer>
        {rightActions && (
          <div onClick={handleInternalClick}>{rightActions}</div>
        )}
      </CardHeader>
    );
  }

  let content = (
    <CardBody>
      {header}
      <CardContent>
        {children}
        {loading && (
          <CardLoader>
            <Loader />
          </CardLoader>
        )}
      </CardContent>
    </CardBody>
  );

  if (onClick) {
    content = <CardActionArea onClick={onClick}>{content}</CardActionArea>;
  }

  return <CardWrapper height={height}>{content}</CardWrapper>;
};
