import { styled, Typography } from '@mui/material';

export const ScreenTtile = styled(Typography)`
  margin-bottom: 24px;
`;

export const TrademarkContainer = styled('div')`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 2px;
  margin-left: -104px;
`;
