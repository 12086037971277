import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FunctionalDynamicAssessmentProvider } from '../../../functionalDynamicAssessment/context';
import { FDALayout } from '../../../functionalDynamicAssessment/components/FDALayout';
import { useFunctionalDynamicAssessment } from '../../../functionalDynamicAssessment/hooks/useFunctionalDynamicAssessment';
import { Loader } from '../../../ui';

export const PatientFunctionalDynamicAssessmentScreen: FC = () => {
  const [params] = useSearchParams();

  const fdaId = params.get('fdaId');
  const { data, authToken, loading, error } =
    useFunctionalDynamicAssessment(fdaId);

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;
  // with the patientID and FDA id, we can fetch the FDA data
  // then pass it into this FunctionalDynamicAssessmentProvider
  if (!data) return <div>No Data Found</div>;
  if (!authToken) return <div>Authentication Error</div>;

  if (data.is_standing_scan_valid === false)
    return <div>Data is not valid</div>;
  if (data.is_walking_scan_valid === false) return <div>Data is not valid</div>;

  return (
    <FunctionalDynamicAssessmentProvider fdaData={data} authToken={authToken}>
      <FDALayout />
    </FunctionalDynamicAssessmentProvider>
  );
};
