import { request, requestList } from '../../../network';
import { ListData, ListDataRequestParams } from '../../ui';

export interface EmployeeModel {
  id: number;
  first_name?: string;
  last_name?: string;
  phone?: string;
  npi_number?: string;
  healthcare_role?: string;
  email_verified: boolean;
  // #TODO: describe model
}

export const getEmployeeList = async (
  data?: ListDataRequestParams
): Promise<ListData> => {
  const response = await requestList('/api/coordinators/', data);

  return response;
};

export const createEmployee = async (
  data: EmployeeModel
): Promise<EmployeeModel> => {
  const response = await request({
    path: '/api/coordinators/',
    method: 'POST',
    body: data,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};
