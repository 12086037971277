import { Typography, TypographyProps } from '@mui/material';
import { FC, ReactNode } from 'react';

export type ListTitleProps = TypographyProps & {
  children: ReactNode;
};

export const ListTitle: FC<ListTitleProps> = (props) => {
  const { children, ...rest } = props;
  return <Typography {...rest}>{children}</Typography>;
};
