import { FC, useEffect, useState } from 'react';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { useVerifyEmail } from '../hooks';
import { FullscreenStatus } from '../../ui';
import { CheckCircleRounded, Error } from '@mui/icons-material';
import { Card as MuiCard, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const VerifyEmailScreen: FC = () => {
  const params = new URLSearchParams(window.location.search);
  const userId = params.get('user_id') || '';
  const confirmationToken = params.get('confirmation_token') || '';

  const [count, setCount] = useState(3);

  const { result, error, isLoading, request, redirect } = useVerifyEmail({
    user_id: userId,
    confirmation_token: confirmationToken,
  });

  useEffect(() => {
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (!isLoading && !error && result) {
      timer = setInterval(() => {
        if (count === 0) {
          clearInterval(timer);
          redirect();
        } else {
          setCount((prevCount) => prevCount - 1);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [result, error, isLoading, count, redirect]);

  const icon = error ? (
    <Error
      style={{
        color: 'red',
        marginBottom: '12px',
        fontSize: '2rem',
      }}
    />
  ) : (
    <CheckCircleRounded
      style={{
        color: 'green',
        marginBottom: '12px',
        fontSize: '2rem',
      }}
    />
  );

  const title = error ? 'Failed!' : 'Success!';

  const message = error ? (
    <>
      <Typography variant='h3'>Email verification </Typography>
      <br />
      <Typography variant='body1'>
        Looks like something went wrong.
        <br />
        Please try signing in again and request a new verification email.
      </Typography>
      <br />
      <Typography variant='body2'>
        error: {error?.message || 'Unknown error'}
      </Typography>
    </>
  ) : (
    <>
      <Typography variant='h3'>Email verified successfully</Typography>
      <br />
      <Typography variant='body1'>redirecting you in {count}...</Typography>
    </>
  );

  return (
    <>
      <DocumentTitle title='Verify Email' />
      {isLoading && <FullscreenStatus loading />}
      {!isLoading && (
        <Layout>
          <Card>
            <CardContainer>
              <CardHeader>
                {icon}
                <CardTitle variant='h1' style={{ marginLeft: '0.5rem' }}>
                  {title}
                </CardTitle>
              </CardHeader>
              <Typography variant='bodyMedium'>{message}</Typography>
            </CardContainer>
          </Card>
        </Layout>
      )}
    </>
  );
};

const Layout = styled('div')`
  padding: 0 20px;
`;

const Card = styled(MuiCard)`
  max-width: 512px;
  height: fit-content;
  margin-top: 30px;
  padding: 40px;
  border-radius: 24px;
  box-shadow: 0px 27px 107px rgba(55, 9, 130, 0.07),
    0px 6.0308px 23.8999px rgba(55, 9, 130, 0.0417275),
    0px 1.79553px 7.11561px rgba(55, 9, 130, 0.0282725);
`;

const CardContainer = styled('div')`
  width: 100%;
  text-align: center;
`;

const CardTitle = styled(Typography)`
  display: block;
  margin-bottom: 12px;
`;

const CardHeader = styled('div')`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
