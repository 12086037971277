import { isBefore, parseISO, format } from 'date-fns';

type RecordId = any;
type Record = { id: RecordId; email_verified: boolean };

export const formatNullValues = (value: string | null) => {
  return value === null ? '—' : value;
};

export const formatMostRecentScanDate = (value: string | null) => {
  let formattedDate: string = '—';
  if (value !== null && value !== undefined) {
    const date = parseISO(value);
    formattedDate = format(date, 'MMM dd y');
  }

  return formattedDate;
};

export const formatNextScanDate = (value: string | null | undefined) => {
  let formattedDate: string = '—';
  let isPastDate: boolean = false;

  if (value !== null && value !== undefined) {
    const currentDate = new Date();
    const date = parseISO(value);
    isPastDate = isBefore(date, currentDate);
    formattedDate = format(date, 'MMM dd y');
  }

  return { formattedDate, isPastDate };
};

export type TRowClickFunction<T = Record> = (record: T) => void;
