import { FC, useState } from 'react';
import {
  WidgetWrapper,
  WidgetCardBody,
  WidgetHeader,
  WidgetContent,
  TitleContainer,
  WidgetLoader,
} from './styles';
import { CardActionArea, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Badge } from '../Badge';
import { ExpandMore } from '../ExpandMore';
import { Loader } from '../Loader';

type TWidgetCard = {
  title: string | null;
  children: React.ReactNode;
  badgeLabel?: string | null;
  onClick?: () => void;
  collapsed?: string;
  loading?: boolean;
};

export const WidgetCard: FC<TWidgetCard> = ({
  title,
  badgeLabel,
  children,
  onClick,
  collapsed,
  loading = false,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = (e: any) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };
  return (
    <WidgetWrapper>
      <CardActionArea onClick={onClick}>
        <WidgetCardBody>
          <WidgetHeader>
            <TitleContainer>
              <Typography variant='h3'>{title}</Typography>
              {badgeLabel && <Badge>{badgeLabel}</Badge>}
            </TitleContainer>
            {collapsed && (
              <div>
                <Typography variant='bodyMedium'>All time</Typography>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label='show more'
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </div>
            )}
          </WidgetHeader>
          <WidgetContent>
            {children}
            {loading && (
              <WidgetLoader>
                <Loader />
              </WidgetLoader>
            )}
          </WidgetContent>
        </WidgetCardBody>
      </CardActionArea>
    </WidgetWrapper>
  );
};
