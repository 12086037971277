import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccountContext } from '../../../account';

// This component handles routes to /notifications and sets a flag
// if the NotificationButton component is mounted and the flag
// the NotificationButton component will open the modal
// Then the NotificationButton component will remove the flag
// This enables the handling of routes to /notifications instead of creating a new view for notifications

export const NotificationsRedirect: FC = () => {
  const navigate = useNavigate();
  const { setNotificationModalOpen } = useAccountContext();

  useEffect(() => {
    setNotificationModalOpen(true);
    navigate('/dashboard');
  }, [navigate, setNotificationModalOpen]);

  return null;
};
