import { FC, useCallback, useEffect } from 'react';
import { Button } from '@mui/material';
import { ValidationErrors } from 'final-form';
import { Form } from 'react-final-form';
import { FormStackVertical, Modal, ModalProps, TextInputFF } from '../../../ui';
import { useChangePassword } from '../../hooks';
import { enqueueSnackbar } from 'notistack';

export type ChangePasswordModalProps = ModalProps;

export const ChangePasswordModal: FC<ChangePasswordModalProps> = (props) => {
  const { open = false, onClose } = props;
  const [result, loading, , request] = useChangePassword();

  const onSubmit = useCallback(
    (values: any) => {
      request(values);
    },
    [request]
  );

  const validate = useCallback((values: any) => {
    const result: ValidationErrors = {};

    if (!values.old_password) {
      result.old_password = true;
    }
    if (!values.new_password) {
      result.new_password = true;
    }
    if (
      !values.repeat_new_password ||
      values.new_password !== values.repeat_new_password
    ) {
      result.repeat_new_password = true;
    }

    return result;
  }, []);

  useEffect(() => {
    if (result) {
      enqueueSnackbar('Password changed successfully', { variant: 'success' });
      if (onClose) {
        onClose();
      }
    }
  }, [result, onClose]);

  return (
    <Modal open={open} onClose={onClose} title='Change password'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormStackVertical alignItems='center'>
              <TextInputFF
                name='old_password'
                label='Current password'
                type='password'
                fullWidth
              />
              <TextInputFF
                name='new_password'
                label='New password'
                type='password'
                fullWidth
              />
              <TextInputFF
                name='repeat_new_password'
                label='Repeat new password'
                type='password'
                fullWidth
              />
              <Button type='submit' disabled={loading}>
                Submit
              </Button>
            </FormStackVertical>
          </form>
        )}
      />
    </Modal>
  );
};
