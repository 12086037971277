export const sanitizeDataGridFieldRestProps: (props: any) => any = ({
  emptyText,
  label,
  record,
  source,
  format,
  buttonText,
  selectedRowID,
  ...props
}) => props;
