import { styled } from '@mui/material';

export const Content = styled('div')`
  padding-top: 24px;
`;

export const FormInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
