import { useCallback, useState } from 'react';
import { deleteUser, DeleteUserResponse } from '../network';

export const useUserDelete = (): [
  DeleteUserResponse | null,
  boolean,
  Error | null,
  (id: string | number) => void
] => {
  const [result, setResult] = useState<DeleteUserResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async (id: string | number) => {
    setIsLoading(true);
    setError(null);

    try {
      const result = await deleteUser(id);
      setResult(result);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        const error = new Error('Unknown error');
        setError(error);
      }
    }

    setIsLoading(false);
  }, []);

  return [result, isLoading, error, request];
};
