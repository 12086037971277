import { type ScanModel } from '../../scan';
import { type ListData } from '../../ui';

export const formatSuperRecordsData = (suc: ListData<ScanModel>) => {
  if (!suc.data) {
    throw new Error('no data was returned from super records');
  }

  const formattedData = suc.data.map((data) => {
    const newData = { ...data };

    newData.bent_mesh_stream = newData.bent_mesh;
    newData.standing_mesh_stream = newData.standing_mesh;
    newData.bent_mesh_texture_stream = newData.bent_mesh_texture;
    newData.standing_mesh_texture_stream = newData.standing_mesh_texture;
    if (
      newData.featureset_values &&
      newData.featureset_values.length &&
      newData.featureset_values[0]
    ) {
      newData.featureset_values[0] = {
        ...newData.featureset_values[0],
        bent_deviation_mesh_stream:
          newData.featureset_values[0].bent_deviation_mesh,
      };
    }
    return newData;
  });

  return {
    count: suc.count,
    data: formattedData,
  };
};
