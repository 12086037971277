import { FieldMetaState } from 'react-final-form';

export interface ShowErrorProps {
  meta: FieldMetaState<any>;
}

export type ShowErrorFunc = (props: ShowErrorProps) => boolean;

export const showErrorOnChange: ShowErrorFunc = ({
  meta: { submitError, dirtySinceLastSubmit, error, touched, modified },
}: ShowErrorProps) =>
  !!(
    ((submitError && !dirtySinceLastSubmit) || error) &&
    (touched || modified)
  );
  
export const showErrorOnBlur: ShowErrorFunc = ({
  meta: { submitError, dirtySinceLastSubmit, error, touched },
}: ShowErrorProps) =>
  !!(((submitError && !dirtySinceLastSubmit) || error) && touched);
