import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import {
  LogoItem,
  QuestionItem,
  SideBarItem,
  SideBarWrapper,
  MomentumSpineLogo,
} from './styles';
import { DashboardIcon } from '../../modules/icon/DashboardIcon';
import { PatientsIcon } from '../../modules/icon/PatientsIcon';
import { SettingsIcon } from '../../modules/icon/SettingsIcon';
import { ScanIcon } from '../../modules/icon/ScanIcon';
import { QuestionIcon } from '../../modules/icon/QuestionIcon';
import primaryLogo from '../../assets/image/icons/primaryLogo.svg';
import { useNavigate } from 'react-router-dom';

const icons = [
  {
    icon: DashboardIcon,
    alt: 'Dashboard',
    path: '/dashboard',
    end: true,
  },
  {
    icon: PatientsIcon,
    alt: 'Patients',
    path: '/patients',
  },
  {
    icon: ScanIcon,
    alt: 'Scans',
    path: '/scans',
  },
  {
    icon: SettingsIcon,
    alt: 'Settings',
    path: '/settings',
  },
];

export const SideBar: FC = () => {
  const navigate = useNavigate();

  const handleContactUs = () => {
    navigate('/contact_us');
  };

  return (
    <SideBarWrapper>
      <LogoItem to={'/'}>
        <MomentumSpineLogo src={primaryLogo} alt='logo' />
      </LogoItem>
      {icons.map(({ icon: Icon, alt, path, end = false }) => (
        <SideBarItem
          key={path}
          aria-label={alt}
          color='primary'
          size='large'
          component={NavLink}
          to={path}
          end={end}
          // disableRipple
        >
          <Icon fontSize='inherit' />
        </SideBarItem>
      ))}

      <QuestionItem size='large' onClick={handleContactUs}>
        <QuestionIcon fontSize='inherit' />
      </QuestionItem>
    </SideBarWrapper>
  );
};
