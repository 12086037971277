import { FC, ReactNode, useMemo } from 'react';
import { PatientModel } from '../../network';
import { PatientContext } from './PatientContext';

export interface PatientContainerProps {
  patient: PatientModel;
  children: ReactNode;
  reload: () => void;
}

export const PatientContainer: FC<PatientContainerProps> = (props) => {
  const { patient, children, reload } = props;

  const contextValue = useMemo(() => {
    return {
      patient,
      reload,
    };
  }, [patient, reload]);

  return (
    <PatientContext.Provider value={contextValue}>
      {children}
    </PatientContext.Provider>
  );
};
