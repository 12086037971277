import { IconButton, Select, styled } from '@mui/material';
import { Expand } from '../../../icon/Expand';
import { Collapse } from '../../../icon/Collapse';
import { InformationCircle } from '../../../icon/InformationCircle';

interface IWrapper {
  isLightBackground?: boolean;
  darkBackgroundColor: string;
}

type ResultType = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  boxShadow?: string;
  borderRadius?: number;
  backgroundColor?: string;
  paddingBottom?: string;
  display?: string;
  flexDirection?: string;
  '& > :not(style) + :not(style)'?: {
    marginRight?: number;
    marginLeft?: number;
    marginTop?: number;
    marginBottom?: number;
  };
};

export const Wrapper = styled('div')<IWrapper>`
  height: 100%;
  width: 100%;
  position: relative;
  background: ${(props) =>
    props.isLightBackground ? '#FFFFFF' : props.darkBackgroundColor};
  color: ${(props) => (props.isLightBackground ? '#000000' : '#FFFFFF')};
  text-align: center;
`;

export const PositionedStack = styled('div')<{
  position: 'tl' | 'tr' | 'bl' | 'br';
  direction?: 'h' | 'v';
  variant?: 'custom' | 'default';
}>`
  position: absolute;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  max-width: 45%;
  ${({ position, direction, variant }) => {
    const result: ResultType = {};
    if (position === 'tr') {
      result.top = 16;
      result.right = 16;
    } else if (position === 'br') {
      result.bottom = 16;
      result.right = 16;
    } else if (position === 'bl') {
      result.bottom = 16;
      result.left = 16;
    } else {
      // like TL
      result.top = 16;
      result.left = 16;
    }

    if (direction) {
      result.display = 'flex';
      if (direction === 'h') {
        if (position === 'tr' || position === 'br') {
          result.flexDirection = 'row-reverse';
          result['& > :not(style) + :not(style)'] = {
            marginRight: 12,
          };
        } else {
          result.flexDirection = 'row';
          result['& > :not(style) + :not(style)'] = {
            marginLeft: 12,
          };
        }
      } else {
        if (position === 'tr' || position === 'tl') {
          result.flexDirection = 'column';
          result['& > :not(style) + :not(style)'] = {
            marginTop: 12,
          };
        } else {
          result.flexDirection = 'column-reverse';
          result['& > :not(style) + :not(style)'] = {
            marginBottom: 12,
          };
        }
      }
    }

    if (variant === 'custom') {
      result.boxShadow = 'rgba(0, 0, 0, 0.2) 0px 0px 10px 3px';
      result.borderRadius = 8;
      result.backgroundColor = 'white';
      result.paddingBottom = '0.5vh';
    }

    return result as string;
  }}
`;

export const ViewModeSelect = styled(Select)`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;

  & .MuiSelect-select {
    padding: 4px 16px;
    min-width: 175px;
    background: #fff;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 145%;
    color: #353535;

    &.Mui-focused {
      background: #fff !important;
    }
  }
`;

export const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const RowContainer = styled('div')`
  display: flex;
  align-items: center;
  > label {
    margin: 0 4px;
  }
`;

export const Title = styled('p')`
  margin: 4px 0;
  font-size: 1rem;
`;

export const ControlsButton = styled('button')`
  border: none;
  cursor: pointer;
  padding: 12px;
  border-radius: 8px;
  color: #353535;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const ExpandIcon = styled(Expand)`
  cursor: pointer;
  margin-left: 1rem;
  &:hover {
    opacity: 0.5;
  }
`;

export const CollapseIcon = styled(Collapse)`
  cursor: pointer;
  margin-left: 1rem;
  &:hover {
    opacity: 0.5;
  }
`;

export const InformationCircleIcon = styled(InformationCircle)`
  cursor: pointer;
  font-size: 1rem;
  margin-top: 5px;
  &:hover {
    opacity: 0.5;
  }
`;

export const Analysis3DImageContainer = styled('div')`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Analysis3dPngImage = styled('img')`
  position: absolute;
  height: 100%;
  padding: 3rem;
`;

export const AsymmetryPngImage = styled('img')`
  height: 100%;
  padding: 40px 0;
`;

export const LayersContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  padding: 16px;
  border-radius: 8px;
`;

export const LayerRow = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const EmptyView = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IInfoBadge {
  backgroundColor?: string;
}

export const InfoBadge = styled('div')<IInfoBadge>`
  min-width: 32px;
  min-height: 32px;
  padding: 4px 12px;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#FFFFFF'};
  border-radius: 8px;
  color: #353535;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
`;

export const IdBadge = styled(InfoBadge)`
  padding: 4px 11px;
  font-weight: 600;
  text-align: center;
`;

export const ViewerIconButton = styled(IconButton)`
  padding: 6px;
  font-size: 20px;
  border-radius: 8px;
  background: #ffffff;
  color: #353535;

  &:hover {
    background: #ffffffcc;
  }
`;

export const MeshLegendIcons = styled('img')`
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  margin: 0.25rem;
`;

export const DeviationMeshViewerControlPanelWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const DeviationMeshViewerControlPanel = styled('div')`
  position: absolute;
  bottom: 2%;
  width: 96%;
  height: fit-content;
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 3px;
`;
