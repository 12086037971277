import { useEffect, useMemo, useState } from 'react';
import { getPatientChart, PatientChartDataModel } from '../network';
import { dateToDateString, subThreeMonths } from './usePatientHealthData';
import { HealthDataModel, getPatientHealthData } from '../network/healthData';

class PatientChartDataObject {
  id: number;
  created_at: Date;

  // clinical data
  forward_bend: number | null = null;
  msl: number | null = null;
  plumb_shift: number | null = null;
  predicted_cobb: number | null = null;
  shoulder_asymmetry: number | null = null;
  trunk_shift: number | null = null;
  waist_asymmetry: number | null = null;

  // health data
  steps: number | null = null;
  floors_climbed: number | null = null;
  double_support_time: number | null = null;
  step_cadence: number | null = null;
  walking_asymmetry: number | null = null;
  walking_speed: number | null = null;
  walking_step_length: number | null = null;

  constructor(date: string) {
    this.id = new Date(date).getTime();
    this.created_at = new Date(date);
  }

  setPatientChartData(data: PatientChartDataModel) {
    this.forward_bend = data.forward_bend;
    this.msl = data.msl;
    this.plumb_shift = data.plumb_shift;
    this.predicted_cobb = data.predicted_cobb;
    this.shoulder_asymmetry = data.shoulder_asymmetry;
    this.trunk_shift = data.trunk_shift;
    this.waist_asymmetry = data.waist_asymmetry;
  }

  setHealthData(data: HealthDataModel) {
    this.steps = data.steps;
    this.floors_climbed = data.floors_climbed;
    this.double_support_time = data.double_support_time.avg;
    this.step_cadence = data.step_cadence.avg;
    this.walking_asymmetry = data.walking_asymmetry.avg;
    this.walking_speed = data.walking_speed.avg;
    this.walking_step_length = data.walking_step_length.avg;
  }
}

const splitDatesByYear = (startDate: Date, endDate: Date) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const oneYear = 365 * 24 * 60 * 60 * 1000; // milliseconds in one year

  if (end.getTime() - start.getTime() <= oneYear) {
    return [{ start: startDate, end: endDate }];
  }

  const result = [];
  let currentStart = start;
  while (end.getTime() - currentStart.getTime() > oneYear) {
    let currentEnd = new Date(currentStart);
    currentEnd.setFullYear(currentEnd.getFullYear() + 1);
    currentEnd.setDate(currentEnd.getDate() - 1); // One day before the next year
    result.push({
      start: currentStart,
      end: currentEnd,
    });
    currentStart = new Date(currentEnd);
    currentStart.setDate(currentStart.getDate() + 1); // Start the next day
  }
  result.push({
    start: currentStart,
    end: endDate,
  });

  return result;
};

// need a hook to get patient graph data AND health data
// should be able to pass in a date range
// should return the data and a loading state and an error state
// should parse the data so it is usable by the chart

export const useChartData = (patientId: number) => {
  const [dateRange, setDateRange] = useState({
    start: subThreeMonths(new Date()),
    end: new Date(),
  });

  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [patientChartResult, setPatientChartResult] = useState<any[]>([]);
  const [healthData, setHealthData] = useState<HealthDataModel[]>([]);

  const getPatientChartDataByDateRange = async (
    id: number,
    start: Date,
    end: Date
  ) => {
    try {
      setLoading(true);
      const result = await getPatientChart(
        id,
        dateToDateString(start),
        dateToDateString(end)
      );

      setPatientChartResult(result.data);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('An unknown error occurred'));
      }
    } finally {
      setLoading(false);
    }
  };

  const getHealthDataByDateRange = async (
    id: number,
    start: Date,
    end: Date
  ) => {
    try {
      setLoading(true);

      // needed to handle the limit of 1 year of health data returned.
      // ideally we change this to be a paginated response
      const dateRanges = splitDatesByYear(start, end);
      const promises = dateRanges.map((range) =>
        getPatientHealthData(
          id,
          dateToDateString(range.start),
          dateToDateString(range.end)
        )
      );

      const resultsArray = await Promise.all(promises);
      const res = resultsArray.flat();

      setHealthData(res);
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
      } else {
        setError(
          new Error(
            'An unknown error occurred will getting patient health data.',
            error || undefined
          )
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const formattedChartData = useMemo(() => {
    return patientChartResult.map((x) => {
      const obj = new PatientChartDataObject(x.created_at);
      obj.setPatientChartData(x);
      return obj;
    });
  }, [patientChartResult]);

  const formattedHealthData = useMemo(() => {
    return healthData.map((x) => {
      const obj = new PatientChartDataObject(x.date); // use date, not created_at
      obj.setHealthData(x);
      return obj;
    });
  }, [healthData]);

  const chartData = useMemo(() => {
    // combine the two arrays
    // make sure that duplicate dates get combined as well.

    const allData = [...formattedChartData, ...formattedHealthData];
    const objMap: {
      [key: number]: PatientChartDataObject;
    } = {};

    allData.forEach((x) => {
      if (objMap[x.id]) {
        // @ts-ignore
        objMap[x.id] = {
          ...objMap[x.id],
          ...x,
        };
      } else {
        objMap[x.id] = x;
      }
    });

    const filteredData = Object.values(objMap)
      .filter((item) => {
        const { id, created_at, ...rest } = item;
        return Object.values(rest).some((value) => value !== null);
      })
      .sort((a, b) => a.created_at.getTime() - b.created_at.getTime());

    return filteredData;
  }, [formattedChartData, formattedHealthData]);

  useEffect(() => {
    getPatientChartDataByDateRange(patientId, dateRange.start, dateRange.end);
    getHealthDataByDateRange(patientId, dateRange.start, dateRange.end);
  }, [patientId, dateRange]);

  return {
    dateRange,
    setDateRange,
    error,
    loading,
    chartData,
  };
};
