import { useCallback, useEffect, useMemo, useState } from 'react';
import { HealthDataModel, getPatientHealthData } from '../network/healthData';
import {
  averagesOfDataGroupedByKey,
  groupedByDay,
  groupedByMonth,
  groupedByWeek,
  groupedByYear,
} from '../utils';

interface HealthDataByDayKey {
  [dateString: string]: HealthDataModel;
}

export const separateByDay = (data: HealthDataModel[]) => {
  return data.reduce<HealthDataByDayKey>((acc, item) => {
    acc[item.date] = item;
    return acc;
  }, {});
};

export const dateToDateString = (date: Date) => {
  return date.toISOString().split('T')[0];
};

const subOneDay = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
};

const getEndOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0); // underflow is handled by Date constructor
};

export const subThreeMonths = (endDate: Date) => {
  return new Date(endDate.getFullYear(), endDate.getMonth() - 2, 1); // underflow is handled by Date constructor
};

export const usePatientHealthData = (patientId: number) => {
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [endReached, setEndReached] = useState(false);
  const [dateRange, setDateRange] = useState({
    endDate: getEndOfMonth(new Date()),
    startDate: subThreeMonths(getEndOfMonth(new Date())),
  });

  const [groupingMethod, setGroupingMethod] = useState<
    'day' | 'week' | 'month' | 'year'
  >('day');

  const [healthData, setHealthData] = useState<HealthDataByDayKey>({});

  const healthDataArray = useMemo(() => {
    switch (groupingMethod) {
      case 'day':
        return averagesOfDataGroupedByKey(
          groupedByDay(Object.values(healthData))
        );
      case 'week':
        return averagesOfDataGroupedByKey(
          groupedByWeek(Object.values(healthData))
        );
      case 'month':
        return averagesOfDataGroupedByKey(
          groupedByMonth(Object.values(healthData))
        );
      case 'year':
        return averagesOfDataGroupedByKey(
          groupedByYear(Object.values(healthData))
        );
      default:
        return averagesOfDataGroupedByKey(
          groupedByDay(Object.values(healthData))
        );
    }
  }, [healthData, groupingMethod]);

  const getHealthDataByDateRange = async (
    id: number,
    start: Date,
    end: Date
  ) => {
    try {
      setLoading(true);
      const res = await getPatientHealthData(
        id,
        dateToDateString(start),
        dateToDateString(end)
      );

      if (res.length === 0) {
        setEndReached(true);
      }

      setHealthData((prev) => {
        return {
          ...prev,
          ...separateByDay(res),
        };
      });
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
      } else {
        setError(
          new Error(
            'An unknown error occurred will getting patient health data.',
            error || undefined
          )
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const loadMore = useCallback(() => {
    if (endReached) {
      return;
    }
    const newEndDate = subOneDay(dateRange.startDate);
    const newStartDate = subThreeMonths(newEndDate);

    // changing the date range will trigger the effect to fetch the data
    setDateRange({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  }, [dateRange, endReached]);

  useEffect(() => {
    if (patientId === undefined) {
      throw new Error('patientId is required to retrieve health data');
    }

    // this should trigger every time the date range changes
    getHealthDataByDateRange(patientId, dateRange.startDate, dateRange.endDate);
  }, [patientId, dateRange]);

  return {
    error,
    loading,
    healthData: healthDataArray,
    endReached,
    groupingMethod,
    loadMore, // to be used to load more table data
    setGroupingMethod,
  };
};
