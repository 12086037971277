import { FC } from 'react';

const BASE_TITLE = 'Momentum Spine';

export interface DocumentTitleProps {
  title: string;
}

export const DocumentTitle: FC<DocumentTitleProps> = (props) => {
  const { title } = props;
  if (title) {
    document.title = `${title} | ${BASE_TITLE}`;
  } else {
    document.title = BASE_TITLE;
  }

  return null;
};
