export const findTwoClosestItemsInArray = (
  array: any[],
  indexOfItem: number
) => {
  if (array === undefined || indexOfItem === undefined) {
    console.warn('[findTwoClosestItemsInArray]: missing attribute');
    return [];
  }
  const baseItem = array[indexOfItem];
  if (indexOfItem === 0) {
    // If the selected item is the first, get the next 2 items
    return array.slice(1, 3);
  }

  if (indexOfItem === array.length - 1) {
    // If the selected item is the last, get the 2 items before it
    return array.slice(-3, -1);
  }

  // If the selected item is not the first and not the last, get the items before and after it
  return array
    .slice(indexOfItem - 1, indexOfItem + 2)
    .filter((item) => item !== baseItem);
};
