import { FC } from 'react';
import { format } from 'date-fns';
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

interface ChartProps {
  chartData: any[];
  leftAxis: { label: string };
  rightAxis: { label: string };
}

export const Chart: FC<ChartProps> = ({ chartData, leftAxis, rightAxis }) => {
  return (
    <div>
      <LineChart
        skipAnimation
        series={[leftAxis, rightAxis]}
        slotProps={{
          legend: {
            labelStyle: {
              fontSize: 16,
              fontWeight: 400,
            },
          },
        }}
        xAxis={[
          {
            label: 'Date',
            dataKey: 'created_at',
            scaleType: 'time',
            valueFormatter: (value: any) =>
              value ? format(value, 'yyyy-MM-dd') : '',
          },
        ]}
        leftAxis='leftAxis'
        rightAxis='rightAxis'
        yAxis={[
          {
            id: 'leftAxis',
            disableTicks: true,
            label: leftAxis.label,
          },
          {
            id: 'rightAxis',
            disableTicks: true,
            label: rightAxis.label,
          },
        ]}
        dataset={chartData}
        height={400}
        margin={{ left: 70, right: 70 }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-25px, 0)',
          },
          [`.${axisClasses.right} .${axisClasses.label}`]: {
            transform: 'translate(30px, 0)',
          },
        }}
      />
    </div>
  );
};
