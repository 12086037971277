import { FC, useContext } from 'react';
import {
  CombinedTableModel,
  DataGridTable,
  ListContainer,
  ListContext,
  ListLayout,
  Loader,
} from '../../ui';
import { useSignupRequest } from '../hooks';
import { getSignupRequests } from '../network';
import { GridCheckCircleIcon, GridColDef } from '@mui/x-data-grid';
import { formatNullValues } from '../../ui/components/DataGridPro/helpers';
import { Chip, IconButton, Link, Tooltip, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import styled from '@emotion/styled';
import { QRCode } from './QRCode';

export const SignupRequestsTable: FC = () => {
  return (
    <div>
      <ListContainer dataRequest={getSignupRequests}>
        <ListLayout>
          <SignupDataGrid />
        </ListLayout>
      </ListContainer>
    </div>
  );
};

const SignupDataGrid: FC = () => {
  const { data, loading, reload, filters, setFilters } =
    useContext(ListContext);
  const { isLoading, acceptPatient, rejectPatient } = useSignupRequest({
    reload,
  });

  const columns: GridColDef<CombinedTableModel>[] = [
    {
      field: 'first_name',
      headerName: 'First Name',
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      flex: 1,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'date_joined',
      flex: 1,
      headerName: 'Joined',
      valueGetter: (params) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: 'signup_status',
      flex: 1,
      headerName: 'Application Status',
      renderCell: (params) => {
        return (
          <Chip
            label={params.value === 'rejected' ? 'Rejected' : 'Pending'}
            color={params.value === 'rejected' ? 'error' : 'default'}
          />
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      getActions: (test) => {
        const { id, row } = test;
        let status;
        if ('signup_status' in row) {
          status = row.signup_status;
        }

        if (status === 'rejected') {
          return isLoading
            ? [<Loader size={20} />]
            : [
                <GridIconButton
                  toolTip='Accept'
                  onClick={() => acceptPatient(id)}
                  icon={<GridCheckCircleIcon />}
                />,
              ];
        }
        return isLoading
          ? [<Loader size={20} />]
          : [
              <GridIconButton
                toolTip='Accept'
                onClick={() => acceptPatient(id)}
                icon={<GridCheckCircleIcon />}
              />,
              <GridIconButton
                toolTip='Decline'
                onClick={() => rejectPatient(id)}
                icon={<CancelIcon />}
              />,
            ];
      },
    },
  ];

  const showRejectedRequests = () => {
    setFilters({ ...filters, signup_status: 'rejected' });
  };

  const hideRejectedRequests = () => {
    setFilters({ ...filters, signup_status: undefined });
  };

  const isShowingRejected = filters?.signup_status === 'rejected';

  return (
    <div style={{ paddingTop: '2rem' }}>
      <Header>
        <HeaderTitleContainer>
          <Typography variant='h2'>Pending Patient Sign-Ups</Typography>
          <QRCode />
        </HeaderTitleContainer>
        {isShowingRejected ? (
          <Link onClick={hideRejectedRequests} underline='always'>
            Hide Rejected Sign-Ups
          </Link>
        ) : (
          <Link onClick={showRejectedRequests} underline='always'>
            Show Rejected Sign-Ups
          </Link>
        )}
      </Header>

      {data?.length === 0 ? (
        <WidgetCard>
          No {isShowingRejected ? 'rejected' : 'pending'} sign-ups...
        </WidgetCard>
      ) : (
        <DataGridTable
          loading={loading}
          data={data}
          columns={columns}
          tableType='signup'
          onRowClick={() => {}}
        />
      )}
    </div>
  );
};

const WidgetCard = styled('div')`
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  margin-top: 3rem;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const HeaderTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

interface GridIconButtonProps {
  toolTip: string;
  icon: JSX.Element;
  disabled?: boolean;
  onClick: () => void;
}

const GridIconButton: FC<GridIconButtonProps> = ({
  toolTip,
  onClick,
  icon,
  disabled,
}) => {
  return (
    <Tooltip title={toolTip}>
      <IconButton
        onClick={onClick}
        disabled={disabled}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            background:
              'linear-gradient(114.04deg, #52489C 3.25%, #8F78F0 100%)',
            color: 'white',
          },
        }}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};
