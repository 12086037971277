import { IconButton, styled } from '@mui/material';

export const ContentWrapper = styled('div')`
  margin-left: 104px;
  padding: 32px 60px 40px 60px;
`;

export const BreadcrumbsWrapper = styled('div')`
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const HeaderContent = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: -8px;
`;

export const HeaderButton = styled(IconButton)`
  padding: 17px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 16px;
`;
