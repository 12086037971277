import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { FC, ReactNode, useCallback } from 'react';
import { ScanModel } from '../../../network';
import { useScanCompareContext } from '../useScanCompareContext';

export interface DndContainerProps {
  children: ReactNode;
}

const sensorOptions = {
  activationConstraint: {
    delay: 150,
    tolerance: 5,
  },
};

export const DndContainer: FC<DndContainerProps> = (props) => {
  const { children } = props;
  const { setSelectedScanFirst, setSelectedScanSecond } =
    useScanCompareContext();
  const mouseSensor = useSensor(MouseSensor, sensorOptions);
  const touchSensor = useSensor(TouchSensor, sensorOptions);
  const sensors = useSensors(mouseSensor, touchSensor);

  const onDrop = useCallback(
    (data: DragEndEvent) => {
      const item = (data.active?.data?.current as ScanModel) || undefined;
      if (!item) {
        return;
      }
      if (data.over?.id === 'first') {
        setSelectedScanFirst(item);
      }
      if (data.over?.id === 'second') {
        setSelectedScanSecond(item);
      }
    },
    [setSelectedScanFirst, setSelectedScanSecond]
  );

  return (
    <DndContext sensors={sensors} onDragEnd={onDrop}>
      {children}
    </DndContext>
  );
};
