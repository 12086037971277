import { FC, useContext, useState } from 'react';
import { Card } from '../../../ui';
import { BalanceChart } from './BalanceChart';
import { styled } from '@mui/material';
import { FDAStaticSlider } from '../FDAStaticSlider';
import { FunctionalDynamicAssessmentContext } from '../../context';

export const BalanceAssessment: FC = () => {
  const { balanceData } = useContext(FunctionalDynamicAssessmentContext);
  const [expanded, setExpanded] = useState(false);
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Card title='Standing Assessment'>
      <Root>
        <ChartTitle>Cone of Balance (cm)</ChartTitle>
        <FDAStaticSlider
          value={balanceData.cervical_std || 0}
          min={-20}
          max={20}
          step={5}
        />
        {expanded && <BalanceChart />}
        <CustomButton onClick={handleToggle}>
          {expanded ? 'Collapse graph' : 'Show expanded graph'}
        </CustomButton>
      </Root>
    </Card>
  );
};

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
`;

const ChartTitle = styled('div')`
  font-weight: 600;
  font-size: 16px;
`;

const CustomButton = styled('button')`
  background: none;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  border: none;
  cursor: pointer;
  color: #6e6e6e;
`;
