export type ListenerHandler<T = any> = (
  data: T,
  event?: string
) => void | Promise<void>;

export type ListenerUnsubscriber = () => void;
export interface ListenerEventMap {
  '@': any;
}

export class Listener<
  EventMap extends ListenerEventMap = ListenerEventMap & { [key: string]: any }
> {
  static offCompose(
    unsubscribers: Array<ListenerUnsubscriber>
  ): ListenerUnsubscriber {
    return () => {
      unsubscribers.forEach((x) => {
        if (typeof x === 'function') {
          x();
        }
      });
    };
  }

  private eventHandlers: {
    [EventType in keyof EventMap]?: Array<ListenerHandler<EventMap[EventType]>>;
  } = {};

  on<EventType extends keyof EventMap>(
    event: EventType,
    handler: ListenerHandler<EventMap[EventType]>
  ): ListenerUnsubscriber {
    if (!(event in this.eventHandlers)) {
      this.eventHandlers[event] = [];
    }

    this.eventHandlers[event]!.push(handler);

    return () => {
      this.off(event, handler);
    };
  }

  off<EventType extends keyof EventMap>(
    event: EventType,
    handler: ListenerHandler<EventMap[EventType]>
  ) {
    if (!(event in this.eventHandlers)) {
      return;
    }

    this.eventHandlers[event] = this.eventHandlers[event]!.filter(
      (x) => x !== handler
    );
  }

  async trigger<EventType extends keyof EventMap>(
    event: EventType,
    data: EventMap[EventType]
  ): Promise<void> {
    const promises: Array<Promise<any>> = [];
    if ('@' in this.eventHandlers) {
      this.eventHandlers['@']!.forEach((handler) => {
        const res = handler(data, event as string);
        if (res instanceof Promise) {
          promises.push(res);
        }
      });
    }

    if (event in this.eventHandlers) {
      this.eventHandlers[event]!.forEach((handler) => {
        const res = handler(data, event as string);
        if (res instanceof Promise) {
          promises.push(res);
        }
      });
    }

    if (promises.length > 0) {
      try {
        await Promise.all(promises);
      } catch (e) {
        // pass
      }
    }
  }
}
