import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const UnhandledDeepLinkRedirect = () => {
  const href = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const pathname = href.pathname;
    if (pathname.includes('/m/')) {
      const newPathname = pathname.replace('/m/', '/');
      navigate(newPathname + href.search, { replace: true });
    }
  }, [navigate, href]);
  return null;
};
