import { FC } from 'react';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { SignUp } from '../../components';

export const SignUpScreen: FC = () => {
  return (
    <>
      <DocumentTitle title='Sign-up' />
      <SignUp />
    </>
  );
};
