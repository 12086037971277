import torsoMesh from '../../../../../assets/image/icons/torso-mesh.png';
import deviationMesh from '../../../../../assets/image/icons/deviation-mesh.png';
import midSacPlane from '../../../../../assets/image/icons/mid-line.png';
import shoulderMeshLegend from '../../../../../assets/image/icons/shoulder-mesh-legend.png';
import pseudoSpine from '../../../../../assets/image/icons/pseudo-spine.png';
import trunkShiftPlane from '../../../../../assets/image/icons/trunk-shift.png';
import plumbLine from '../../../../../assets/image/icons/plumb_line.png';
import { MeshViewerProps } from '.';

export type LayerId =
  | 'torso_mesh'
  | 'bent_deviation_mesh'
  | 'mid_sac_plane'
  | 'shoulders'
  | 'pseudo_spine'
  | 'trunk_shift_plane'
  | 'plumb_shift_plane';

export interface LayerInfo {
  id: LayerId;
  label: string;
  description: string;
  icon: string;
  alt: string;
  unit: string | null;
  featureset_key: keyof MeshViewerProps | null; // This line ensures that featureset_key is a key of MeshViewerProps
  display_type: string;
}

export type LayerVisibility = {
  [K in LayerId]: boolean;
};

export const defaultLayerVisibility: LayerVisibility = {
  torso_mesh: true,
  bent_deviation_mesh: false,
  mid_sac_plane: false,
  shoulders: false,
  pseudo_spine: false,
  trunk_shift_plane: false,
  plumb_shift_plane: false,
};

export type MeshType = 'bent' | 'standing' | null;

export const ViewModeLabel = Object.freeze({
  bent: 'Bent 3D model',
  standing: 'Standing 3D model',
});

export const layersInfo: Array<LayerInfo> = [
  {
    id: 'torso_mesh',
    label: 'Torso Mesh',
    description:
      'A mesh of the torso from the hips to the neck used to display standing and bent spine metrics/features.',
    icon: torsoMesh,
    alt: 'Torso Mesh legend',
    unit: null,
    featureset_key: null,
    display_type: 'dual',
  },
  {
    id: 'bent_deviation_mesh',
    label: 'Deviation Mesh',
    description:
      'A mesh of the back coloured based on the difference between left and right sides of the body. The colours highlight regions of asymmetry.',
    icon: deviationMesh,
    alt: 'Deviation Mesh legend',
    unit: null,
    featureset_key: null,
    display_type: 'bent',
  },
  {
    id: 'mid_sac_plane',
    label: 'Mid Sac. Plane',
    description: 'A vertical plane passing through the midline of the hips.',
    icon: midSacPlane,
    alt: 'Mid sac. plane legend',
    unit: null,
    featureset_key: null,
    display_type: 'standing',
  },
  {
    id: 'shoulders',
    label: 'Shoulders',
    description:
      'Points identifying the right and left shoulders. The value displayed is the angle between the shoulder and the floor level, also known as shoulder asymmetry.',
    icon: shoulderMeshLegend,
    alt: 'Shoulders legend',
    unit: '°',
    featureset_key: 'shoulder_asymmetry',
    display_type: 'standing',
  },
  {
    id: 'pseudo_spine',
    label: 'Pseudo Spine',
    description:
      'A number of pseudo vertebrae showing the estimated location of the spine based on the centroid of the body and the rotation observed in that region of the torso.',
    icon: pseudoSpine,
    alt: 'Pseudo spine legend',
    unit: null,
    featureset_key: null,
    display_type: 'standing',
  },
  {
    id: 'trunk_shift_plane',
    label: 'Trunk Shift Plane',
    description:
      'A vertical plane showing the right-to-left shift of the trunk. The trunk is the region of the back that contains the ribcage. The value displayed is the distance between the centre of the trunk and the mid sacral line.',
    icon: trunkShiftPlane,
    alt: 'Trunk shift plane legend',
    unit: 'cm',
    featureset_key: 'trunk_shift',
    display_type: 'standing',
  },
  {
    id: 'plumb_shift_plane',
    label: 'Plumb Shift Plane',
    description:
      'The plumb line is calculated as the center of the neck on the subject. The value displayed is the distance between the centre of the neck and the mid sacral line.',
    icon: plumbLine,
    alt: 'Plumb shift plane legend',
    unit: 'cm',
    featureset_key: 'plumb_shift',
    display_type: 'standing',
  },
];
