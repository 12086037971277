import { useCallback, useEffect, useState } from 'react';
import { getPatient, PatientModel } from '../network';

export const usePatient = (id?: string | number): [
  PatientModel | null,
  boolean,
  Error | null,
  () => void
] => {
  const [result, setResult] = useState<PatientModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(Boolean(id));
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    if (!id) {
      setError(new Error('Id is not provided'));
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const result = await getPatient(id);
      setResult(result);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        const error = new Error('Unknown error');
        setError(error);
      }
    }

    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) {
      request();
    }
  }, [request, id]);

  return [result, isLoading, error, request];
};
