import { useDndMonitor, useDroppable } from '@dnd-kit/core';
import { Typography } from '@mui/material';
import { FC, ReactNode, useCallback, useState } from 'react';
import { MoveIcon } from '../../../../icon/MoveIcon';
import { ScanModel } from '../../../network';
import { ScanItemViewer } from '../../ScanItemViewer';
import {
  Content,
  DropContainer,
  EmptyContainer,
  MoveIconContainer,
  Wrapper,
} from './styles';

export interface ScanCompareViewItemProps {
  item: ScanModel | null;
  name: 'first' | 'second';
}

export const ScanCompareViewItem: FC<ScanCompareViewItemProps> = (props) => {
  const { item, name } = props;
  const [dragging, setDragging] = useState(false);

  const { isOver, setNodeRef } = useDroppable({
    id: name,
  });

  const onDragStart = useCallback(() => {
    setDragging(true);
  }, []);

  const onDragStop = useCallback(() => {
    setDragging(false);
  }, []);

  useDndMonitor({
    onDragStart,
    onDragEnd: onDragStop,
    onDragCancel: onDragStop,
  });

  let content: ReactNode = '';
  if (!item) {
    content = (
      <EmptyContainer isOver={isOver} dragging={dragging}>
        <MoveIconContainer>
          <MoveIcon color='inherit' fontSize='inherit' />
        </MoveIconContainer>
        <Typography variant='subtitle1'>Move scan to this area</Typography>
      </EmptyContainer>
    );
  }

  if (item) {
    content = (
      <>
        <Content>
          <ScanItemViewer item={item} name={name} />
        </Content>
        <DropContainer isOver={isOver} dragging={dragging}>
          <MoveIconContainer>
            <MoveIcon color='inherit' fontSize='inherit' />
          </MoveIconContainer>
          <Typography variant='subtitle1'>Move scan to this area</Typography>
        </DropContainer>
      </>
    );
  }

  return <Wrapper ref={setNodeRef}>{content}</Wrapper>;
};
