import { FC } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { CheckboxInput, CheckboxInputProps } from './CheckboxInput';

export type CheckboxInputFFProps = CheckboxInputProps & {
  name: string;
  fieldProps?: Partial<FieldProps<any, any>>;
};

export const CheckboxInputFFWrapper: FC<
  FieldRenderProps<CheckboxInputProps>
> = (props) => {
  const {
    input: { name, value, checked, type, onChange, onBlur, onFocus, ...restInput },
    meta,
    required,
    helperText,
    ...rest
  } = props;

  return (
    <CheckboxInput
      // error={isError}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      value={value}
      checked={checked}
      required={required}
      inputProps={{ required, ...restInput }}
      {...rest}
    />
  );
};

export const CheckboxInputFF: FC<CheckboxInputFFProps> = (props) => {
  const { name, fieldProps, ...rest } = props;

  return (
    <Field
      name={name}
      type='checkbox'
      render={({ input, meta }) => (
        <CheckboxInputFFWrapper
          input={input}
          meta={meta}
          name={name}
          {...rest}
        />
      )}
      {...fieldProps}
    />
  );
};
