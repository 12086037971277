import { request, requestList } from '../../../network';
import { ListData, ListDataRequestParams } from '../../ui';

export interface PatientNoteModel {
  id: number;
  coordinator: number;
  coordinator_name: string;
  created_at: string;
  note_value: string;
  patient: number;
  updated_at: string | null;
}

export const getNoteList = async (
  data?: ListDataRequestParams
): Promise<ListData<PatientNoteModel>> => {
  const response = (await requestList(
    '/api/notes/',
    data
  )) as ListData<PatientNoteModel>;

  try {
    const sortedData = response.data?.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

    if (sortedData) {
      return {
        ...response,
        data: sortedData,
      };
    }

    return response;
  } catch (error) {
    console.error('Failed to fetch note list:', error);
    throw error;
  }

  // need to sort the response by latest date first
};

export interface CreateNoteData {
  patient: number;
  note_value: string;
}

export const createNote = async (
  data: CreateNoteData
): Promise<PatientNoteModel> => {
  const response = await request({
    path: '/api/notes/',
    method: 'POST',
    body: data,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const deleteNote = async (noteId: number): Promise<PatientNoteModel> => {
  const response = await request({
    path: `/api/notes/${noteId}/`,
    method: 'DELETE',
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};
