import { styled, Card, CardContent } from '@mui/material';

export const WidgetWrapper = styled(Card)`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 16px;
  position: relative;
`;

export const WidgetCardBody = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 189px;
  padding: 24px 32px 32px;
`;

export const WidgetHeader = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`;

export const WidgetContent = styled('div')`
  display: flex;
`;

export const WidgetLoader = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 40%);
`;
