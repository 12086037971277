import { styled, Card, CardContent, IconButton } from '@mui/material';
import users from '../../../../assets/image/icons/users.svg';

export const InvitePatientWrapper = styled(Card)`
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
  background: linear-gradient(114.04deg, #52489c 3.25%, #8f78f0 100%);
  border-radius: 16px;
  cursor: pointer;
  transition: opacity .3s;
  &:hover {
    opacity: 0.9;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    left: 16px;
    right: 16px;
    bottom: 0;
    z-index: -1;
    top: 16px;
    background: linear-gradient(81.43deg, rgba(82, 72, 156, 0.6) 0%, rgba(126, 102, 225, 0.6) 100%);
    filter: blur(20px);
    border-radius: 24px;
  }
`;

export const InvitePatientBody = styled(CardContent)`
  position: relative;
  height: 189px;
  padding: 24px 32px;
  background: url(${users}) calc(100% - 16px) calc(100% - 12px) / auto no-repeat;
`;

export const AddButton = styled(IconButton)`
  position: absolute;
  bottom: 32px;
  right: 32px;
  width: 48px;
  height: 48px;
  background: #ffffff;
  pointer-events: none;
`;
