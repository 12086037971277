import { useCallback, useState } from 'react';
import {
  CreateNewUserData,
  CreateNewUserDataWithOrgId,
  createNewUser,
  signupWithOrgId,
} from '../network';

export const useSignUp = () => {
  const [result, setResult] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (data: CreateNewUserData | CreateNewUserDataWithOrgId) => {
      setIsLoading(true);
      setError(null);

      try {
        if ('organization_id' in data) {
          await signupWithOrgId(data);
        } else {
          await createNewUser(data);
        }
        setResult(true);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error('Unknown error'));
        }
      }

      setIsLoading(false);
    },
    []
  );

  return { result, isLoading, error, request };
};
