import { Tabs as MuiTabs, Tab as MuiTab, styled, css } from '@mui/material';

export const Tabs = styled(MuiTabs)`
  & .MuiTabs-indicator {
    display: none;
  }
`;

export const Tab = styled(MuiTab)`
  padding: 16px 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 145%;
  color: #878787;
  border-radius: 16px 16px 0px 0px;
  &.Mui-selected {
    color: #353535;
    background: #fff;
  }
`;

export const TabPanelWrapper = styled('div')<{
  isFirst?: boolean;
}>`
  background: #fff;
  padding: 34px 32px;
  border-radius: 16px;

  ${({ isFirst }) => isFirst && css`
    border-radius: 0px 16px 16px 16px;
  `}
`;
