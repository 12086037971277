import { useCallback, useEffect } from 'react';
import { ValidationErrors } from 'final-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Form } from 'react-final-form';
import { FormStackVertical, TextInputFF } from '../../../ui';
import { AuthCard, AuthCardHeader, AuthCardTitle } from '../ui';
import { ButtonContainer } from './styles';

import { useResetPassword } from '../../hooks';

const initialValues = {
  password: undefined,
  token: undefined,
};

export const ResetPassword = () => {
  const [result, loading, , request] = useResetPassword();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token') || '';

  const onSubmit = useCallback(
    (values: any) => {
      request({ password: values.password, token: token });
    },
    [request, token]
  );

  const validate = useCallback((values: any) => {
    const result: ValidationErrors = {};

    // Checking if the password is present
    if (!values.password) {
      result.password = 'Password is required';
    }

    // Checking if the confirm password is present
    if (!values.confirmPassword) {
      result.confirmPassword = 'Confirm password is required';
    }

    // Checking if the password has at least 8 characters
    if (values.password && values.password.length < 8) {
      result.password = 'Password must be at least 8 characters';
    }

    // Checking if the password has more than 32 characters
    if (values.password && values.password.length > 32) {
      result.password = 'Password must be less than 32 characters';
    }

    // Checking if the password contains at least one uppercase letter
    if (values.password && !/[A-Z]/.test(values.password)) {
      result.password = 'Password must contain at least one uppercase letter';
    }

    // Checking if the password contains at least one number
    if (values.password && !/\d/.test(values.password)) {
      result.password = 'Password must contain at least one number';
    }

    // Checking if the password contains at least one special character
    if (
      values.password &&
      !/[-'/`~!#*$@_%+=.,^&(){}[\]|;:"<>?\\]/g.test(values.password)
    ) {
      result.password = 'Password must contain at least one special character';
    }

    // Checking if the password is fully numeric
    if (values.password && /^\d+$/.test(values.password)) {
      result.password = 'Password must not be fully numeric';
    }

    // Checking if the password and confirm password match
    if (values.password !== values.confirmPassword) {
      result.confirmPassword = 'Password and confirm password do not match!';
    }

    return result;
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (result?.status === 'OK') {
      navigate('/reset_success', { state: { passwordReset: true } });
    }
  }, [result, navigate]);

  return (
    <AuthCard>
      <AuthCardHeader>
        <AuthCardTitle variant='h1'>Set password</AuthCardTitle>
        <Typography variant='bodyMedium'>
          Strong passwords include numbers, letters and special characters.
        </Typography>
      </AuthCardHeader>
      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormStackVertical>
              <TextInputFF
                label='Password'
                name='password'
                placeholder='8+ Characters'
                type='password'
              />

              <TextInputFF
                label='Confirm password'
                name='confirmPassword'
                placeholder='8+ Characters'
                type='password'
              />

              <ButtonContainer>
                <Button
                  type='button'
                  variant='text'
                  onClick={() => navigate('/')}
                  disabled={loading}
                  sx={{ width: '132px' }}
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  disabled={loading}
                  sx={{ width: '132px' }}
                >
                  Send
                </Button>
              </ButtonContainer>
            </FormStackVertical>
          </form>
        )}
      />
    </AuthCard>
  );
};
