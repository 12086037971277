import { useCallback, useEffect, useState } from 'react';
import { getScanCount, ScanCountResponse } from '../network';

export const useScanCount = (): [
  ScanCountResponse | null,
  boolean,
  Error | null,
  () => void
] => {
  const [result, setResult] = useState<ScanCountResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const result = await getScanCount();
      setResult(result);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        const error = new Error('Unknown error');
        setError(error);
      }
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    request();
  }, [request]);

  return [result, isLoading, error, request];
};
