import { CssBaseline } from '@mui/material';
import { Fragment } from 'react';

export const RootLayout = (props: any) => {
  const { children } = props;
  return (
    <Fragment>
      <CssBaseline />
      {children}
    </Fragment>
  );
};
