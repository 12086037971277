import { FC, useContext, useMemo, useState } from 'react';
import { FunctionalDynamicAssessmentContext } from '../../context';
import { ScatterPlot } from '@mui/x-charts/ScatterChart';
import { LinePlot } from '@mui/x-charts/LineChart';
import { ChartsReferenceLine } from '@mui/x-charts/ChartsReferenceLine';
import { frameToData } from '../../utils';
import {
  axisClasses,
  ChartsClipPath,
  ChartsLegend,
  ChartsXAxis,
  ChartsYAxis,
  ResponsiveChartContainer,
  useDrawingArea,
  useXScale,
  // useYScale,
} from '@mui/x-charts';
import { CurrentPositionCircle } from '../ChartComponents/CurrentPositionCircle';

const getEvenMinMax = (data: number[], offset: number) => {
  const smallest = Math.abs(Math.min(...data));
  const largest = Math.abs(Math.max(...data));

  const max = Math.max(smallest, largest);

  const maxWithOffset = max * offset;

  return {
    min: -maxWithOffset,
    max: maxWithOffset,
  };
};

const NormativeValueCircle = () => {
  const normativeValue = 5; // 0.02 meters (20cm)
  const { left, top, width, height } = useDrawingArea();
  const xScale = useXScale();

  const xPosition = xScale(normativeValue) || 0;
  const radius = xPosition - left - width / 2;

  return (
    <>
      <circle
        cx={left + width / 2}
        cy={top + height / 2}
        r={radius}
        style={{
          fill: 'none',
          stroke: 'blue',
          strokeWidth: 1,
          strokeDasharray: '3 3',
        }}
      />
    </>
  );
};

export const BalanceChart: FC = () => {
  const {
    balanceControls: { currentFrame },
    balanceData,
  } = useContext(FunctionalDynamicAssessmentContext);

  const [
    scaleMultiplier,
    // setScaleMultiplier
  ] = useState(115);

  const { cervical_x, cervical_z, hips_x, hips_z, knees_x, knees_z } =
    useMemo(() => {
      let output: any = {
        cervical_x: [],
        cervical_z: [],
        hips_x: [],
        hips_z: [],
        knees_x: [],
        knees_z: [],
      };

      if (balanceData.cervical_data?.cervical_x) {
        output.cervical_x = balanceData.cervical_data.cervical_x;
      }
      if (balanceData.cervical_data?.cervical_z) {
        output.cervical_z = balanceData.cervical_data.cervical_z;
      }

      if (balanceData.hip_data?.hips_x) {
        output.hips_x = balanceData.hip_data.hips_x;
      }
      if (balanceData.hip_data?.hips_z) {
        output.hips_z = balanceData.hip_data.hips_z;
      }

      if (balanceData.knee_data?.knees_x) {
        output.knees_x = balanceData.knee_data.knees_x;
      }
      if (balanceData.knee_data?.knees_z) {
        output.knees_z = balanceData.knee_data.knees_z;
      }

      return output;
    }, [balanceData]);

  const dynamicMinMax = useMemo(() => {
    const minMax = getEvenMinMax(
      [
        ...cervical_x,
        ...cervical_z,
        ...hips_x,
        ...hips_z,
        ...knees_x,
        ...knees_z,
      ],
      scaleMultiplier / 100
    );
    return minMax;
  }, [
    scaleMultiplier,
    cervical_x,
    cervical_z,
    hips_x,
    hips_z,
    knees_x,
    knees_z,
  ]);

  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setScaleMultiplier(parseInt(event.target.value));
  // };

  if (
    !balanceData.cervical_data?.cervical_x ||
    !balanceData.cervical_data?.cervical_z ||
    !balanceData.hip_data?.hips_x ||
    !balanceData.hip_data?.hips_z ||
    !balanceData.knee_data?.knees_x ||
    !balanceData.knee_data?.knees_z
  ) {
    return null;
  }

  return (
    <>
      {/* <input
        type='range'
        min={1}
        max={105}
        value={scaleMultiplier}
        onChange={handleInputChange}
      /> */}
      <ResponsiveChartContainer
        height={500}
        width={500}
        margin={{ left: 60 }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            // Move the y-axis label with CSS
            transform: 'translateX(-20px)',
          },
        }}
        series={[
          {
            // data: sampleData.cervical_z.slice(0, currentFrame),
            data: cervical_z,
            curve: 'linear',
            type: 'line',
            showMark: false,
            color: 'orange',
            label: 'cervical spine',
            xAxisKey: 'cervical-axis',
          },
          {
            data: knees_z,
            curve: 'linear',
            type: 'line',
            showMark: false,
            color: 'blue',
            label: 'knees',
            xAxisKey: 'knee-axis',
          },
          {
            data: hips_z,
            curve: 'linear',
            type: 'line',
            showMark: false,
            color: 'green',
            label: 'hips',
            xAxisKey: 'hip-axis',
          },
        ]}
        xAxis={[
          {
            id: 'cervical-axis',
            label: 'X-axis (cm)',
            data: cervical_x,
            min: dynamicMinMax.min,
            max: dynamicMinMax.max,
          },
          {
            id: 'knee-axis',
            label: 'other-x-axis (cm)',
            data: knees_x,
            min: dynamicMinMax.min,
            max: dynamicMinMax.max,
          },
          {
            id: 'hip-axis',
            label: 'other-x-axis (cm)',
            data: hips_x,
            min: dynamicMinMax.min,
            max: dynamicMinMax.max,
          },
        ]}
        yAxis={[
          {
            label: 'Y-axis (cm)',
            min: dynamicMinMax.min,
            max: dynamicMinMax.max,
          },
        ]}
      >
        <g clipPath={'url(#clippedPath)'}>
          <LinePlot skipAnimation />
          <ScatterPlot skipAnimation />
          <NormativeValueCircle />
          <CurrentPositionCircle
            x={frameToData(currentFrame, cervical_x)}
            y={frameToData(currentFrame, cervical_z)}
          />
          <CurrentPositionCircle
            x={frameToData(currentFrame, hips_x)}
            y={frameToData(currentFrame, hips_z)}
          />
          <CurrentPositionCircle
            x={frameToData(currentFrame, knees_x)}
            y={frameToData(currentFrame, knees_z)}
          />
        </g>
        <ChartsLegend
          slotProps={{
            legend: {
              labelStyle: {
                fontSize: 16,
                fontWeight: 400,
              },
            },
          }}
        />

        <ChartsClipPath id='clippedPath' />
        <ChartsXAxis />
        <ChartsYAxis />
        <ChartsReferenceLine
          lineStyle={{
            strokeDasharray: '3 3',
          }}
          y={0}
        />
        <ChartsReferenceLine
          lineStyle={{
            strokeDasharray: '3 3',
          }}
          x={0}
        />
      </ResponsiveChartContainer>
    </>
  );
};
