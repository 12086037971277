import { request, requestList } from '../../../network';
import { ListData, ListDataRequestParams } from '../../ui';
import { MeshMetaData } from '../components/ScanItemViewer/MeshViewer';

export interface FeaturesetValues {
  id: number;
  predicted_cobb: number | null;
  created_at: string;
  bent_deviation_map_display_stream: string | '';
  standing_back_display_stream: string | '';
  plumb_shift_display_stream: string | '';
  plumb_shift: number | 0;
  trunk_shift_display_stream: string | '';
  trunk_shift: number | 0;
  shoulder_asymmetry_display_stream: string | '';
  shoulder_asymmetry: number | 0;
  waist_asymmetry_display_stream: string | '';
  waist_asymmetry: number | 0;
  mid_sac_line_display_stream: string | '';
  mesh_metadata: MeshMetaData | null;
  bent_deviation_mesh_stream: string | '';
  bent_deviation_mesh: string | '';
  cleared_for_release: boolean;
}

export interface ScanModel {
  id: number;
  created_at: string;
  patient: number;
  organization: number;

  // Info:
  labeled_cobb?: number | null;
  predicted_cobb?: number | null;
  progression?: number | null;
  height?: number | null;
  weight?: number | null;
  is_valid?: boolean;
  status?: string | null;
  most_recent_featureset_id?: number | null;
  excluded_from_training?: boolean;
  cleared_for_release?: boolean;

  // Bent:
  is_bent_scan_valid?: boolean;
  bent_cobb?: number | null;
  bent_pointcloud?: string | null;
  bent_pointcloud_stream?: string | null;
  bent_mesh?: string | null;
  bent_mesh_stream?: string | null;
  bent_mesh_texture?: string | null;
  bent_mesh_texture_stream?: string | null;
  bent_progression?: number | null;
  bent_status?: string | null; // Make enum
  bent_video?: string | null;

  // Standing:
  is_standing_scan_valid?: boolean;
  standing_cobb?: number | null;
  standing_pointcloud?: string | null;
  standing_pointcloud_stream?: string | null;
  standing_mesh?: string | null;
  standing_mesh_stream?: string | null;
  standing_mesh_texture?: string | null;
  standing_mesh_texture_stream?: string | null;
  standing_progression?: number | null;
  standing_status?: string | null; // Make enum
  standing_video?: string | null;

  // Patient info:
  age?: number | null;
  first_name?: string | null;
  last_name?: string | null;

  featureset_values?: FeaturesetValues[];
}

export const getScanList = async (
  data?: ListDataRequestParams
): Promise<ListData<ScanModel>> => {
  // return requestList('/api/patients/', data);
  const response = await requestList('/api/records/', data);

  return response;
};

export const getScan = async (id: string | number): Promise<ScanModel> => {
  const response = await request({
    path: `/api/records/${id}`,
    method: 'GET',
    silent: true,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export interface ScanCountResponse {
  total: number;
  unread: number;
  new?: number;
}

export const getScanCount = async (): Promise<ScanCountResponse> => {
  const response = await request({
    path: '/api/records/',
    method: 'GET',
    silent: true,
  });

  if (response.ok) {
    return {
      total: response.data.count,
      unread: 0, //backend changes for these values will be added later
      new: 0,
    };
  }

  throw new Error(response.errorMessage);
};
