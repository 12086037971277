import { createTheme, css } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    bodySmall: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodyLarge: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodySmall?: React.CSSProperties;
    bodyMedium?: React.CSSProperties;
    bodyLarge?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodySmall: true;
    bodyMedium: true;
    bodyLarge: true;
  }
}

// Add custom variant to Button
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    containedLight: true;
  }
}

export const mainTheme = createTheme({
  typography: {
    fontFamily: '"Apercu Pro", sans-serif',
    h1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '48px',
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '32px',
      lineHeight: '38.4px',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '26px',
      lineHeight: '31.2px',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '29px',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20.3px',
    },
    bodySmall: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '17.4px',
    },
    bodyMedium: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20.3px',
    },
    bodyLarge: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '23.2px',
    },
    button: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '23px',
      textTransform: 'none',
      whiteSpace: 'nowrap',
    },
  },
  palette: {
    primary: {
      main: '#52489C',
      light: '#7E66E1',
    },
    error: {
      main: '#DD4234',
    },
    background: {
      default: '#F6F6F6',
    },
    text: {
      primary: '#353535',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        body: css`
          min-width: 1260px;
          * {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-rendering: optimizeLegibility;
          }
        `,
      }),
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          cursor: 'pointer',
          fontSize: '16px',
          lineHeight: '24px',
          '&:hover': {
            opacity: 0.8,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: css`
          position: relative;
          border: 1px solid transparent;
          border-radius: 10px;
          overflow: hidden;
          background-color: #f6f6f6;
          color: #353535;

          &.Mui-focused {
            border-color: #7e66e1;
            background-color: #fff;
          }
          &.Mui-error {
            border-color: #dd4234;
          }
          &.MuiInputBase-adornedEnd {
            padding-right: 16px;
          }
          label + & {
            margin-top: 20px;
            font-size: 14px;
            line-height: 19.6px;
          }

          & .MuiInputBase-input {
            padding: 12px 16px;
            border-radius: 10px;
            background-color: #f6f6f6;
          }
          &.Mui-focused .MuiInputBase-input {
            background-color: #fff;
          }
          & ::placeholder {
            color: #878787;
          }
        `,
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: { color: '#878787' },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ ownerState, theme }: any) => ({
          ...((ownerState.variant === 'contained' ||
            ownerState.variant === 'containedLight') &&
            ownerState.color === 'primary' && {
              padding: '12px 24px',
              background:
                'linear-gradient(114.04deg, #52489C 3.25%, #8F78F0 100%)',
              borderRadius: '12px',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '23.2px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
            }),
          ...(ownerState.variant === 'containedLight' &&
            ownerState.color === 'primary' && {
              color: theme.palette.primary.main,
              background: '#F1EEFF',
            }),
          ...(ownerState.disabled &&
            (ownerState.variant === 'contained' ||
              ownerState.variant === 'containedLight') && {
              background: '#E6E6E6',
              color: '#878787',
            }),
          ...(ownerState.variant === 'text' && {
            padding: '12px 24px',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '23px',
            textTransform: 'none',
            whiteSpace: 'nowrap',
          }),
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'primary' && {
              color: theme.palette.primary.light,
            }),
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: 'none',
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        focusHighlight: {
          '.MuiCardActionArea-root:hover &': {
            opacity: 0.02,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        head: {
          padding: '32px 32px 24px',
        },
        body: {
          padding: '24px 32px',
          // borderTop: '1px solid #E5E9EB',
          position: 'relative',
          '.MuiTableRow-hover &': {
            cursor: 'pointer',
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            height: '1px',
            backgroundColor: '#E5E9EB',
          },
          '&:first-of-type:before': {
            left: '32px',
          },
          '&:last-of-type:before': {
            right: '32px',
          },
          '.MuiTableRow-hover:hover + .MuiTableRow-hover > &:before': {
            display: 'none',
          },
        },
      },
    },
  },
});
