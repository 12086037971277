import { Card, Grid } from '@mui/material';
import { FC, ReactNode } from 'react';
import { SingleViewIcon } from '../../../../icon/SingleViewIcon';
import { SplitViewIcon } from '../../../../icon/SplitViewIcon';
import { Loader, SwitchButtons } from '../../../../ui';
import { ScanCompareViewItem } from '../ScanCompareViewItem';
import { useScanCompareContext } from '../useScanCompareContext';
import { Header, LoadingContainer, MeshViewContainer, Wrapper } from './styles';
import { ScanSync } from '../ScanSync';

const switchButtons = [
  {
    value: false,
    text: 'Single view',
    icon: <SingleViewIcon />,
  },
  {
    value: true,
    text: 'Split view',
    icon: <SplitViewIcon />,
  },
];

export const ScanCompareView: FC = () => {
  const {
    splitMode,
    selectedScanFirst,
    selectedScanSecond,
    loading,
    setSplitMode,
  } = useScanCompareContext();

  let content: ReactNode = (
    <ScanCompareViewItem
      name='first'
      item={selectedScanFirst}
      key={selectedScanFirst?.id}
    />
  );
  if (splitMode) {
    content = (
      <Grid container columnSpacing={4} alignItems='stretch' mt={0}>
        <Grid item xs={6}>
          {content}
        </Grid>
        <Grid item xs={6}>
          <ScanCompareViewItem
            name='second'
            item={selectedScanSecond}
            key={selectedScanSecond?.id}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Card>
      <Wrapper>
        <Header>
          <SwitchButtons
            items={switchButtons}
            value={splitMode}
            onChange={setSplitMode}
          />
          <ScanSync />
        </Header>
        <MeshViewContainer>
          {loading && (
            <LoadingContainer>
              <Loader />
            </LoadingContainer>
          )}
          {content}
        </MeshViewContainer>
      </Wrapper>
    </Card>
  );
};
