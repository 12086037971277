import { styled } from '@mui/material/styles';

export const LoaderContainer = styled('div')<{
  size: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: ${(props) => `${props.size}px`};
`;

export const LoaderSpinner = styled('div')`
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;

  @keyframes loader-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  div {
    animation: loader-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 8.75%;
    height: 8.75%;
    border-radius: 50%;
    background: #7e66e1;
    margin: -5% 0 0 -5%;
  }
  div:nth-of-type(1) {
    animation-delay: -0.036s;
  }
  div:nth-of-type(1):after {
    top: 78.75%;
    left: 78.75%;
  }
  div:nth-of-type(2) {
    animation-delay: -0.072s;
  }
  div:nth-of-type(2):after {
    top: 85%;
    left: 70%;
  }
  div:nth-of-type(3) {
    animation-delay: -0.108s;
  }
  div:nth-of-type(3):after {
    top: 88.75%;
    left: 60%;
  }
  div:nth-of-type(4) {
    animation-delay: -0.144s;
  }
  div:nth-of-type(4):after {
    top: 90%;
    left: 50%;
  }
  div:nth-of-type(5) {
    animation-delay: -0.18s;
  }
  div:nth-of-type(5):after {
    top: 88.75%;
    left: 40%;
  }
  div:nth-of-type(6) {
    animation-delay: -0.216s;
  }
  div:nth-of-type(6):after {
    top: 85%;
    left: 30%;
  }
  div:nth-of-type(7) {
    animation-delay: -0.252s;
  }
  div:nth-of-type(7):after {
    top: 78.75%;
    left: 21.25%;
  }
  div:nth-of-type(8) {
    animation-delay: -0.288s;
  }
  div:nth-of-type(8):after {
    top: 70%;
    left: 15%;
  }
`;
