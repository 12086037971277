import { useCallback, useEffect, useState } from 'react';
import { NotificationModel } from '../types';
import {
  getNotifications,
  toggleArchiveNotification,
  toggleReadNotification,
} from '../network';

const PAGE_SIZE = 5;

interface UseNotificationsListProps {
  archived: boolean;
}

export const useNotificationsList = ({
  archived,
}: UseNotificationsListProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);
  const [canLoadMore, setCanLoadMore] = useState<boolean | undefined>(
    undefined
  );

  const loadMore = async () => {
    if (nextPage === null) {
      return;
    }

    if (isLoading) {
      // don't make the load more request if we are already loading
      return;
    }

    setIsLoading(true);
    setError(null);

    const pagination = {
      page: nextPage,
      size: PAGE_SIZE,
    };

    const filters = {
      archived: archived,
    };

    try {
      const response = await getNotifications({
        pagination,
        filters,
      });
      if (response.data) {
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          ...(response.data || []),
        ]);
      }
      if (response.next) {
        setNextPage(response.next);
        setCanLoadMore(true);
      } else {
        setNextPage(null);
        setCanLoadMore(false);
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        const err = new Error('Unknown error');
        setError(err);
      }
      setNotifications([]);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleRead = async (id: number, value: boolean) => {
    if (isLoading) {
      // don't make the load more request if we are already loading
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      await toggleReadNotification({ id, value: value });
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) => {
          if (notification.id === id) {
            return {
              ...notification,
              is_read: value,
            };
          }
          return notification;
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        const err = new Error('Unknown error');
        setError(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const toggleArchive = async (id: number, value: boolean) => {
    if (isLoading) {
      // don't make the load more request if we are already loading
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      await toggleArchiveNotification({ id, value });

      // remove the notification from this list
      // if we're in the non-archived list we and it gets toggled to archived then remove it
      // if we're in the archived list and it gets toggled to non-archived then remove it
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== id)
      );
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        const err = new Error('Unknown error');
        setError(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const initialRequest = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    const pagination = {
      page: 1,
      size: PAGE_SIZE,
    };

    const filters = {
      archived: archived,
    };

    try {
      const response = await getNotifications({ pagination, filters });

      setNotifications(response.data || []);
      if (response.next) {
        setNextPage(response.next);
        setCanLoadMore(true);
      } else {
        setNextPage(null);
        setCanLoadMore(false);
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        const err = new Error('Unknown error');
        setError(err);
      }
      setNotifications([]);
    } finally {
      setIsLoading(false);
    }
  }, [archived]);

  useEffect(() => {
    // run initial request on mount
    initialRequest();
  }, [initialRequest]);

  /**
   * handle the case when a user archives
   * a bunch of notifications and thinks they're done
   */
  useEffect(() => {
    if (notifications.length === 0) {
      // check if there's more to load and load them
      // load them as an initial request because the page numbers will be all messed up
      if (canLoadMore) {
        initialRequest();
      }
    }
  }, [notifications, canLoadMore, initialRequest]);

  return {
    notifications,
    loading: isLoading,
    error,
    canLoadMore,
    loadMore,
    toggleRead,
    toggleArchive,
    refresh: initialRequest,
  };
};
