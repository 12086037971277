import { createContext } from 'react';
import { UserModel } from '../../types';

export type AccountState = {
  user: UserModel;
  updating: boolean;
  reload: () => void;
  notificationModalOpen: boolean;
  setNotificationModalOpen: (open: boolean) => void;
};

export type AccountContextValue = AccountState;

export const AccountContext = createContext<AccountContextValue>(
  {} as AccountContextValue
);
