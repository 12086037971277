import { useCallback, useState } from 'react';
import { useAuthContext } from '../containers';
import { getAuthToken } from '../network';

export interface SigninData {
  username: string; password: string, remember?: boolean
}

export const useSignin = (): [
  boolean | null,
  boolean,
  Error | null,
  (data: SigninData) => void,
] => {
  const { setAuthData } = useAuthContext();

  const [result, setResult] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (data: SigninData) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await getAuthToken(data);
        await setAuthData({
          accessToken: response.access,
          refreshToken: data.remember ? response.refresh : undefined,
        });
        setResult(true);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          const error = new Error('Unknown error');
          setError(error);
        }
      }

      setIsLoading(false);
    },
    [setAuthData]
  );

  return [result, isLoading, error, request];
};
