import { useDraggable } from '@dnd-kit/core';
import { FC } from 'react';
import { ScanItem, ScanItemProps } from './ScanItem';

export const ScanItemDraggable: FC<ScanItemProps> = (props) => {
  const { item } = props;

  const { attributes, listeners, setNodeRef } = useDraggable({
    data: item,
    id: item.id,
  });

  return (
    <div ref={setNodeRef} {...listeners} {...attributes}>
      <ScanItem {...props} />
    </div>
  );
};
