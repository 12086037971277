import { FC } from 'react';
import { LoaderContainer, LoaderSpinner } from './styles';

export interface LoaderProps {
  size?: number;
}

export const Loader: FC<LoaderProps> = (props) => {
  const { size = 50 } = props;

  return (
    <LoaderContainer size={size}>
      <LoaderSpinner>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </LoaderSpinner>
    </LoaderContainer>
  );
};

