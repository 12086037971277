import { FC, useMemo } from 'react';
import { PatientNextScanHistoryDataGrid } from '../../../patient';
import { Card, ListContainer } from '../../../ui';
import { getScanList } from '../../network';

export interface PatientScansWidgetProps {
  title?: string;
  patient: string | number;
}

export const PatientScansWidget: FC<PatientScansWidgetProps> = (props) => {
  const { patient, title } = props;

  const filters = useMemo(
    () => ({
      patient,
    }),
    [patient]
  );

  return (
    <Card title={title}>
      <ListContainer dataRequest={getScanList} filters={filters}>
        <PatientNextScanHistoryDataGrid height='835px' />
      </ListContainer>
    </Card>
  );
};
