import { Checkbox, CheckboxProps, FormControl, FormControlLabel } from '@mui/material';
import { FC, ReactNode } from 'react';
import { CheckboxLabel } from './styles';

export type CheckboxInputProps = CheckboxProps & {
  label?: string | ReactNode;
  fullWidth?: boolean;
};

export const CheckboxInput: FC<CheckboxInputProps> = (props) => {
  const { label, fullWidth, ...rest } = props;
  return (
    <FormControl fullWidth={fullWidth}>
      <FormControlLabel
        control={<Checkbox {...rest} />}
        label={<CheckboxLabel>{label}</CheckboxLabel>}
      />
    </FormControl>
  );
};
