import React, { FC, ReactNode, useMemo, useState } from 'react';
import { UserModel } from '../../types';
import { AccountContext } from './AccountContext';

export interface AccountProviderProps {
  user: UserModel;
  updating: boolean;
  children: ReactNode;
  reload: () => void;
}

export const AccountProvider: FC<AccountProviderProps> = (props) => {
  const { user, updating, children, reload } = props;
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  const contextValue = useMemo(() => {
    return {
      user,
      updating,
      reload,
      notificationModalOpen,
      setNotificationModalOpen,
    };
  }, [user, updating, notificationModalOpen, setNotificationModalOpen, reload]);

  return (
    <AccountContext.Provider value={contextValue}>
      {children}
    </AccountContext.Provider>
  );
};
