import { request } from '../../../network';
import { VerifyEmailParams } from '../types';

export const verifyEmail = async (params: VerifyEmailParams): Promise<any> => {
  const response = await request({
    path: '/api/verify_email/',
    method: 'POST',
    body: { ...params },
    withAuth: false,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};
