export interface NotificationModel {
  id: number;
  is_read: boolean;
  is_sent: boolean;
  title: string;
  message: string;
  created_at: string;
  notification_type: NOTIFICATION_TYPES;
  custom_notification_method: NOTIFICATION_METHODS | null;
  processing_error: string | null;
  archived: boolean;
  user: string;
  record: string;
}

export enum NOTIFICATION_TYPES {
  invitation_reminder = 'invitation_reminder',
  first_scan_reminder = 'first_scan_reminder',
  next_scan_reminder = 'next_scan_reminder',
  free_trial_reminder = 'free_trial_reminder',
  failed_scan = 'failed_scan',
  successful_scan = 'successful_scan',
  custom = 'custom',
}

export enum NOTIFICATION_METHODS {
  email = 'Email',
  phone = 'Phone',
  push = 'Push',
  multiple = 'Multiple',
}

export enum NOTIFICATION_USER_TYPES {
  patient = 'patient',
  coordinator = 'coordinator',
}
