import { useEffect, useState } from 'react';
import {
  defaultLayerVisibility,
  LayerVisibility,
  MeshType,
} from '../components/ScanItemViewer/MeshViewer/layers';

export const useScanLayerControls = () => {
  const [meshType, setMeshType] = useState<MeshType>('bent');
  const [layerState, setToggleState] = useState<LayerVisibility>(
    defaultLayerVisibility
  );

  // handles changing the visibility of a layer
  const toggleLayer = (layerId: keyof LayerVisibility) => {
    setToggleState({
      ...layerState,
      [layerId]: !layerState[layerId],
    });
  };

  // reacts to changes in the mesh type
  // and sets the layers to be displayed
  useEffect(() => {
    if (meshType === 'bent') {
      setToggleState({
        torso_mesh: true,
        bent_deviation_mesh: false,
        mid_sac_plane: false,
        shoulders: false,
        pseudo_spine: false,
        trunk_shift_plane: false,
        plumb_shift_plane: false,
      });
    }

    if (meshType === 'standing') {
      setToggleState({
        torso_mesh: true,
        bent_deviation_mesh: false,
        mid_sac_plane: false,
        shoulders: false,
        pseudo_spine: false,
        trunk_shift_plane: false,
        plumb_shift_plane: false,
      });
    }
  }, [meshType]);

  return {
    meshType,
    setMeshType,
    layerState,
    toggleLayer,
  };
};
