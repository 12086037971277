import { FC, useContext, useRef, useState } from 'react';
import { ListContext } from '../../..';
import { debounce } from 'lodash';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const SearchBar: FC = () => {
  const { filters, setFilters } = useContext(ListContext);
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetFilters = useRef(
    debounce((searchValue) => {
      setFilters({
        ...filters,
        search: searchValue,
      });
    }, 800)
  ).current;

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    debouncedSetFilters(e.target.value);
  };

  const handleForceSearch = () => {
    setFilters({
      search: searchTerm,
    });
  };

  return (
    <TextField
      placeholder='Search...'
      value={searchTerm}
      onChange={handleSearchTermChange}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleForceSearch();
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='search'
              onClick={handleForceSearch}
              edge='end'
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
