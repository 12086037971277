import { List } from '@mui/material';
import { useScanCompareContext } from '../useScanCompareContext';
import { ScanDragOverlay } from './ScanDragOverlay';
import { ScanItemDraggable } from './ScanItemDraggable';
import { Content, Title, Wrapper } from './styles';

export const ScanCompareHistory = () => {
  const { scans, selectedScanFirst, selectedScanSecond, selectScan } =
    useScanCompareContext();

  return (
    <>
      <Wrapper>
        <Title variant='h4'>Scan History</Title>
        <Content>
          <List>
            {scans.map((item) => (
              <ScanItemDraggable
                item={item}
                key={item.id}
                selectedFirst={selectedScanFirst}
                selectedSecond={selectedScanSecond}
                onSelect={selectScan}
              />
            ))}
          </List>
        </Content>
      </Wrapper>
      <ScanDragOverlay />
    </>
  );
};
