import axios from 'axios';
import { config } from '../config';

const apiClient = axios.create({
  baseURL: config.apiBaseUrl,
  timeout: 10000,
});

export const interceptedSuperRecordsUrl = { url: '' };

apiClient.interceptors.response.use((response) => {
  const config = response.config;
  if (config.url?.includes('super_records')) {
    const url = new URL(config.url);

    let page_size = 100;

    if (response.data.count) {
      page_size = response.data.count;
    }

    const newUrl = new URL(url);
    newUrl.searchParams.set('page_size', page_size.toString());

    // Sort search params alphabetically
    const sortedParams = new URLSearchParams(
      Array.from(newUrl.searchParams.entries()).sort()
    );
    newUrl.search = sortedParams.toString();

    interceptedSuperRecordsUrl.url = newUrl.href;
  }
  return response;
});

export default apiClient;
