import { format } from 'date-fns';
import { FC, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Button, styled } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { PatientNoteModel } from '../../../network';

interface NoteItemProps {
  note: PatientNoteModel;
  handleRemove: (noteId: number) => void;
  currentCoordinatorId: number;
}
export const NoteItem: FC<NoteItemProps> = ({
  note,
  currentCoordinatorId,
  handleRemove,
}) => {
  const [isMultiLine, setIsMultiLine] = useState(false);

  const title = `${note.coordinator_name} / ${format(
    new Date(note.created_at),
    'yyyy-MM-dd'
  )}`;

  const isDeleteButtonVisible = note.coordinator === currentCoordinatorId;

  return (
    <NoteItemRoot onClick={() => setIsMultiLine(!isMultiLine)}>
      <NoteItemContainer>
        <NoteTitle>{title}</NoteTitle>
        <NoteValue isMultiline={isMultiLine}>{note.note_value}</NoteValue>
        {isDeleteButtonVisible && isMultiLine === true && (
          <div>
            <Button
              onClick={() => handleRemove(note.id)}
              startIcon={<DeleteForeverIcon />}
              variant='text'
              color='error'
              size='small'
              sx={{
                padding: 0,
                fontWeight: 500,
                fontSize: '13px',
              }}
            >
              Delete
            </Button>
          </div>
        )}
      </NoteItemContainer>
      {isMultiLine ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </NoteItemRoot>
  );
};

const NoteItemRoot = styled('div')`
  display: flex;
  background: #f6f6f6;
  border-radius: 12px;
  padding: 10px 8px 10px 16px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const NoteItemContainer = styled('div')`
  gap: 12px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 24px);
`;

const NoteTitle = styled('div')`
  color: #a7a7a7;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

interface NoteValueProps {
  isMultiline: boolean;
}

const NoteValue = styled('div')((props: NoteValueProps) => ({
  overflow: props.isMultiline ? 'unset' : 'hidden',
  whiteSpace: props.isMultiline ? 'unset' : 'nowrap',
  textOverflow: props.isMultiline ? 'unset' : 'ellipsis',
  ...props,
}));
