import { FC, useCallback, useEffect } from 'react';
import { Button } from '@mui/material';
import { ValidationErrors } from 'final-form';
import { Form } from 'react-final-form';
import { FormStackVertical, Modal, ModalProps, TextInputFF } from '../../../ui';
import { useInvitePatient } from '../../hooks';
import { enqueueSnackbar } from 'notistack';

export type InvitePatientModalProps = ModalProps;

export const InvitePatientModal: FC<InvitePatientModalProps> = (props) => {
  const { open = false, onClose } = props;
  const [result, loading, , request] = useInvitePatient();

  const onSubmit = useCallback(
    (values: any) => {
      request(values);
    },
    [request]
  );

  const validate = useCallback((values: any) => {
    const result: ValidationErrors = {};

    if (!values.first_name) {
      result.first_name = true;
    }
    if (!values.last_name) {
      result.last_name = true;
    }
    if (!values.email) {
      result.email = true;
    }

    return result;
  }, []);

  useEffect(() => {
    if (result) {
      enqueueSnackbar('Invitation sent', { variant: 'success' });
      if (onClose) {
        onClose();
      }
    }
  }, [result, onClose]);

  return (
    <Modal open={open} onClose={onClose} title='Invite patient'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormStackVertical alignItems='center'>
              <TextInputFF name='first_name' label='First name' fullWidth />
              <TextInputFF name='last_name' label='Last name' fullWidth />
              <TextInputFF name='email' label='Email' fullWidth />
              <Button type='submit' disabled={loading}>
                Send invite
              </Button>
            </FormStackVertical>
          </form>
        )}
      />
    </Modal>
  );
};
