import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { BreadcrumbItem } from './styles';
import { PatientBreadcrumb } from '../../modules/patient';

const routes: BreadcrumbsRoute[] = [
  {
    path: '/',
    breadcrumb: 'Momentum Spine',
  },
  {
    path: '/dashboard',
    breadcrumb: 'Dashboard',
  },
  {
    path: '/scans',
    breadcrumb: 'Scans',
  },
  {
    path: '/patients',
    breadcrumb: 'Patients',
    children: [{ path: ':id', breadcrumb: PatientBreadcrumb }],
  },
  {
    path: '/settings',
    breadcrumb: 'Settings',
  },
];

export const AppBreadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <BreadcrumbItem key={match.pathname} to={match.pathname} end>
          <Typography variant='bodyMedium'>{breadcrumb}</Typography>
        </BreadcrumbItem>
      ))}
    </Breadcrumbs>
  );
};
