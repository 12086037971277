import apiClient from './apiClient';
import qs from 'qs';
import { enqueueSnackbar } from 'notistack';
import { authService } from '../modules/auth';
import { HTTP_ERROR_MESSAGES } from './constants';
import { config } from '../config';

export type ReqestOption = {
  path: string;
  baseUrl?: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: {
    [key: string]: string;
  };
  body?: any;
  contentType?: 'json';
  params?: {
    [key: string]: string;
  };
  withAuth?: boolean;
  silent?: boolean;
  interceptors?: {
    request?: (config: any) => any;
    response?: (response: any) => any;
  };
};

export type RequestResponse = {
  ok: boolean;
  statusCode?: number;
  headers?: any;
  data?: any;
  errorMessage?: string;
};

export const request = async (
  options: ReqestOption
): Promise<RequestResponse> => {
  let {
    baseUrl = config.apiBaseUrl,
    path,
    method = 'GET',
    headers,
    body,
    contentType = 'json',
    params,
    withAuth = true,
    silent = false,
    interceptors,
  } = options;

  let requestBody = null;
  if (body && contentType === 'json') {
    requestBody = JSON.stringify(body);
    headers = {
      ...headers,
      'Content-Type': 'application/json',
    };
  }

  let url = path;
  if (baseUrl) {
    url = baseUrl + url;
  }
  if (params) {
    url = url + (url.includes('?') ? '' : '?') + qs.stringify(params);
  }

  if (withAuth) {
    try {
      const accessToken = await authService.getValidAccessToken();
      headers = {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      };
    } catch (e) {
      const result: RequestResponse = {
        ok: false,
        data: null,
        errorMessage: 'Unauthorized',
      };
      if (!silent) {
        enqueueSnackbar(result.errorMessage!, { variant: 'error' });
      }
      return result;
    }
  }

  // Apply custom request and response interceptors if provided
  let requestInterceptorId: number | undefined;
  let responseInterceptorId: number | undefined;

  if (interceptors?.request) {
    requestInterceptorId = apiClient.interceptors.request.use(
      interceptors.request
    );
  }

  if (interceptors?.response) {
    responseInterceptorId = apiClient.interceptors.response.use(
      interceptors.response
    );
  }

  try {
    const response = await apiClient({
      url,
      method,
      headers,
      data: requestBody,
    });

    const result: RequestResponse = {
      ok: response.status >= 200 && response.status < 300,
      statusCode: response.status,
      headers: response.headers,
      data: response.data,
    };

    return result;
  } catch (error) {
    const response = (error as any).response || {};
    const result: RequestResponse = {
      ok: false,
      statusCode: response.status,
      headers: response.headers,
      data: response.data,
      errorMessage: '',
    };

    if (result.data) {
      if (result.data.message) {
        result.errorMessage = result.data.message;
      } else if (result.data.detail) {
        result.errorMessage = result.data.detail;
      } else if (typeof result.data === 'object') {
        const values = Object.values(result.data);
        if (values.length > 0) {
          const firstValue = values[0];
          result.errorMessage = Array.isArray(firstValue)
            ? firstValue[0]
            : firstValue;
        }
      } else {
        result.errorMessage = JSON.stringify(result.data);
      }
    } else if (result.statusCode === null || result.statusCode === undefined) {
      result.errorMessage = 'Connection failed';
    } else if (result.statusCode in HTTP_ERROR_MESSAGES) {
      result.errorMessage = HTTP_ERROR_MESSAGES[result.statusCode];
    } else {
      result.errorMessage = 'Unknown error';
    }

    if (!silent) {
      enqueueSnackbar(result.errorMessage || 'Unknown error', {
        variant: 'error',
      });
    }

    return result;
  } finally {
    // Eject the interceptors after the request is complete
    if (requestInterceptorId !== undefined) {
      apiClient.interceptors.request.eject(requestInterceptorId);
    }
    if (responseInterceptorId !== undefined) {
      apiClient.interceptors.response.eject(responseInterceptorId);
    }
  }
};
