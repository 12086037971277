import { FC, ReactNode } from 'react';
import { InputBaseProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { TextInput } from '../TextInput';

export type DateInputProps = InputBaseProps & {
  label?: string | ReactNode;
  helperText?: string | ReactNode;
};

export const DateInput: FC<DateInputProps> = (props) => {
  const { label, error, helperText, fullWidth, ...rest } = props;

  const datePickerProps = {
    variant: 'standard',
    error: error,
    label: label,
    fullWidth: fullWidth,
    onChange: () => {},
    renderInput: (params: any) => {
      let newParams = { ...params };

      if ('InputProps' in params) {
        let oldStuff = newParams.InputProps;
        delete newParams.InputProps;
        newParams = { ...newParams, ...oldStuff };
      }

      return (
        <TextInput
          {...newParams}
          fullWidth={fullWidth}
          error={error}
          helperText={helperText}
        />
      );
    },
    ...rest,
  } as DatePickerProps<any, any>;

  return <DatePicker mask='__/__/____' {...datePickerProps} />;
};
