import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid-pro';
import { TRowClickFunction } from '../../../ui/components/DataGridPro/helpers';
import { DataGridTable, ListContext, CombinedTableModel } from '../../../ui';
import { ScanModel } from '../../../scan';
import {
  formatNullValues,
  formatMostRecentScanDate,
} from '../../../ui/components/DataGridPro/helpers';
import { ScanStatusCellRenderer } from '../../../scan';

export interface PatientNextScanHistoryDataGridProps {
  height: string;
}

export const PatientNextScanHistoryDataGrid: FC<
  PatientNextScanHistoryDataGridProps
> = (props) => {
  const { height } = props;
  const { data, loading } = useContext(ListContext);
  const navigate = useNavigate();

  const onRowClick: TRowClickFunction<any> = (item: ScanModel) => {
    navigate(`/patients/${item.patient}/scans?scanId=${item.id}`);
  };

  const columns: GridColDef<CombinedTableModel>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 125,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 125,
      renderCell: ScanStatusCellRenderer,
    },
    {
      field: 'predicted_cobb',
      headerName: 'AI Predicted Cobb Angle',
      flex: 1,
      minWidth: 125,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'created_at',
      headerName: 'Scan Date',
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        return formatMostRecentScanDate(params.value);
      },
    },
  ];

  return (
    <DataGridTable
      height={height}
      loading={loading}
      data={data}
      columns={columns}
      tableType='record'
      onRowClick={onRowClick}
    />
  );
};
