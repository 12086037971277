import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  RecoveryScreen,
  SigninScreen,
  ResetScreen,
  ResetSuccessScreen,
  useAuthContext,
  SignUpScreen,
} from '../../modules/auth';
import { DashboardScreen } from '../../modules/dashboard';
import { PatientListScreen, PatientScreenRouter } from '../../modules/patient';
import { ScanListScreen } from '../../modules/scan';
import { SettingsScreen } from '../../modules/settings';
import { AuthorizedContainer } from '../AuthorizedContainer';
import { UnauthorizedContainer } from '../UnauthorizedContainer';
import { AllScansScreen } from '../../modules/allScans';
import { ContactUsScreen } from '../../modules/auth/screens/ContactUsScreen';
import { VerifyEmailScreen } from '../../modules/verifyEmail';
import { SignUpSuccessScreen } from '../../modules/auth/screens/SignUpSuccessScreen';
import { NotificationsRedirect } from '../../modules/notification';
import { UnhandledDeepLinkRedirect } from '../../modules/redirects/UnhandledDeepLinkRedirect';
import { DeleteAccountScreen } from '../../modules/deleteAccount/screens';

export const NavigationContainer: FC = () => {
  const { initialized, authorized } = useAuthContext();

  if (!initialized) {
    return null;
  }

  return (
    <Routes>
      {authorized} ? (
      <Route path='/' element={<AuthorizedContainer />}>
        <Route path='/' element={<Navigate replace to='/dashboard' />} />
        <Route path='/dashboard' element={<DashboardScreen />} />
        <Route path='/patients/:id/*' element={<PatientScreenRouter />} />
        <Route path='/patients' element={<PatientListScreen />} />
        <Route path='/scans' element={<ScanListScreen />} />
        <Route path='/settings' element={<SettingsScreen />} />
        <Route path='/all-scans' element={<AllScansScreen />} />
        <Route path='/notifications' element={<NotificationsRedirect />} />
        <Route path='*' element={<Navigate replace to='/' />} />
      </Route>
      ) : (
      <Route path='/' element={<UnauthorizedContainer />}>
        <Route path='/' element={<Navigate replace to='/auth' />} />
        <Route path='/auth' element={<SigninScreen />} />
        <Route path='/sign-up' element={<SignUpScreen />} />
        <Route path='/sign-up-success' element={<SignUpSuccessScreen />} />
        <Route path='/recovery' element={<RecoveryScreen />} />
        <Route path='/password_reset' element={<ResetScreen />} />
        <Route path='/reset_success' element={<ResetSuccessScreen />} />
        <Route path='*' element={<Navigate replace to='/' />} />
      </Route>
      )
      <Route path='/' element={<UnauthorizedContainer universalView={true} />}>
        <Route path='/contact_us' element={<ContactUsScreen />} />
        <Route path='/verify_email' element={<VerifyEmailScreen />} />
        <Route path='/delete-account' element={<DeleteAccountScreen />} />
        <Route path='/m/*' element={<UnhandledDeepLinkRedirect />} />
      </Route>
    </Routes>
  );
};
