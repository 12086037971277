import { useCallback, useState } from 'react';
import { verifyEmail } from '../network';
import { VerifyEmailParams } from '../types';
import { useDeviceType } from '../../auth/hooks/useDeviceType';

interface UseVerifyEmailReturn {
  result: boolean | null;
  isLoading: boolean;
  error: Error | null;
  request: () => void;
  redirect: () => void;
}

export const useVerifyEmail = (
  params: VerifyEmailParams
): UseVerifyEmailReturn => {
  const deviceType = useDeviceType();
  const [result, setResult] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      await verifyEmail(params);
      setResult(true);
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setError(e);
      } else {
        const error = new Error('Unknown error');
        setError(error);
      }
    }

    setIsLoading(false);
  }, [params]);

  const redirect = () => {
    let appURL = 'https://app.momentum.health/email_verified';
    if (deviceType === 'android' || deviceType === 'iOS') {
      appURL = 'com.momentum.spine.app://email_verified';
    } else {
      appURL = 'https://app.momentum.health/email_verified';
    }

    const link = document.createElement('a');
    link.href = appURL;
    link.target = '_blank';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return { result, isLoading, error, request, redirect };
};
