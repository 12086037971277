import { FC } from 'react';
import { Grid } from '@mui/material';
import {
  HealthDataTable,
  PatientInfoCard,
  PatientInfoNotes,
  PatientNextScanCard,
  PatientProgressionCard,
} from '../../components';
import { PatientScansWidget } from '../../../scan';
import { usePatientContext } from '../../containers/PatientContainer/usePatientContext';

export const PatientScreen: FC = () => {
  const { patient } = usePatientContext();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PatientProgressionCard />
      </Grid>

      <Grid item xs={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PatientNextScanCard />
          </Grid>
          <Grid item xs={12}>
            <PatientInfoCard />
          </Grid>
          <Grid item xs={12}>
            <PatientInfoNotes />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PatientScansWidget title='Scan History' patient={patient.id} />
          </Grid>
          <Grid item xs={12}>
            <HealthDataTable patient={patient.id} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
