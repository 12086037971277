import { FC, useCallback, useEffect } from 'react';
import { Button } from '@mui/material';
import { ValidationErrors } from 'final-form';
import { Form } from 'react-final-form';
import { FormStackVertical, Modal, ModalProps, TextInputFF } from '../../../ui';
import { enqueueSnackbar } from 'notistack';
import { useUserDelete } from '../../hooks/useUserDelete';
import { useAccountContext } from '../../containers';
import { useAuthContext } from '../../../auth';

export type DeleteAccountModalProps = ModalProps;

export const DeleteAccountModal: FC<DeleteAccountModalProps> = (props) => {
  const { open = false, onClose } = props;
  const { logout } = useAuthContext();
  const { user } = useAccountContext();
  const [result, loading, , request] = useUserDelete();

  const confirmationString = user.username || user.email || 'delete_account';

  const onSubmit = useCallback(() => {
    request(user.id);
  }, [user.id, request]);

  const validate = useCallback(
    (values: any) => {
      const result: ValidationErrors = {};

      if (!values.confirm || values.confirm !== confirmationString) {
        result.confirm = true;
      }

      return result;
    },
    [confirmationString]
  );

  useEffect(() => {
    if (result) {
      enqueueSnackbar('Account deleted successfully', { variant: 'success' });
      logout();
      if (onClose) {
        onClose();
      }
    }
  }, [result, logout, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Are you absolutely sure?'
      subtitle={<>Enter the following to confirm: <b>{confirmationString}</b></>}
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormStackVertical alignItems='center'>
              <TextInputFF name='confirm' autoFocus fullWidth />
              <Button type='submit' disabled={loading}>
                Delete account
              </Button>
            </FormStackVertical>
          </form>
        )}
      />
    </Modal>
  );
};
