import { useCallback, useEffect, useState } from 'react';
import { ListData, ListDataRequest, ListDataRequestParams } from './types';

export interface UseListDataRequestResult<Record = any>
  extends ListData<Record> {
  loading: boolean;
  error: Error | null;
  reload: () => void;
}

export const useListDataRequest = <Record = any>(
  dataRequest: ListDataRequest,
  params: ListDataRequestParams = {},
  incomingPatchRequest?: boolean | null,
  setLoadTable?: (value: boolean) => void,
  setIncomingPatchRequest?: (value: null) => void
): UseListDataRequestResult<Record> => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [result, setResult] = useState<ListData | null>(null);

  const { filters, ordering, pagination } = params;

  const request = useCallback(async () => {
    setLoading(true);
    setError(null);

    // only used in the all-scans page
    if (setLoadTable && incomingPatchRequest !== undefined) {
      setLoadTable(true);
    }

    try {
      const response = await dataRequest({
        filters,
        ordering,
        pagination,
      });

      setResult(response);
    } catch (e: any) {
      setResult(null);
      setError(e);
    }
    setLoading(false);

    // only used in the all-scans page
    if (setLoadTable && incomingPatchRequest !== undefined) {
      setLoadTable(false);
    }
    if (setIncomingPatchRequest) {
      setIncomingPatchRequest(null);
    }
  }, [
    filters,
    ordering,
    pagination,
    dataRequest,
    setLoadTable,
    incomingPatchRequest,
    setIncomingPatchRequest,
  ]);

  useEffect(() => {
    request();
  }, [request]);

  return {
    data: null,
    count: null,
    ...(result || {}),
    loading,
    error,
    reload: request,
  };
};
