import { FC, useContext, useState } from 'react';
import { Card } from '../../../ui';
import { FDAStaticSlider } from '../FDAStaticSlider';
import { styled } from '@mui/material';
import { FunctionalDynamicAssessmentContext } from '../../context';
import { axisClasses, ChartsReferenceLine, LineChart } from '@mui/x-charts';
import { CurrentPositionCircle } from '../ChartComponents/CurrentPositionCircle';
import { frameToData } from '../../utils';

export const GaitAssessment: FC = () => {
  const {
    gaitControls: { currentFrame },
    gaitData,
  } = useContext(FunctionalDynamicAssessmentContext);

  console.log(gaitData.chin_brow_avg);

  return (
    <Card title='Walking Assessment'>
      <Root>
        <AssessmentContainer>
          <ChartTitle>Dynamic Sagittal Alignment Distance (cm)</ChartTitle>
          <FDAStaticSlider
            value={gaitData.head_pelvis_avg || 0}
            min={-100}
            max={100}
            step={20}
          />
          {gaitData.head_pelvis_data && (
            <GaitChart
              data={gaitData.head_pelvis_data}
              currentFrame={currentFrame}
            />
          )}
        </AssessmentContainer>
        <AssessmentContainer>
          <ChartTitle>Dynamic Chin Brow Distance (cm)</ChartTitle>
          <FDAStaticSlider
            value={gaitData.chin_brow_avg || 0}
            min={-15}
            max={15}
            step={5}
          />
          {gaitData.chin_brow_data && (
            <GaitChart
              lineColor='blue'
              data={gaitData.chin_brow_data}
              currentFrame={currentFrame}
            />
          )}
        </AssessmentContainer>
      </Root>
    </Card>
  );
};

interface GaitChartProps {
  data: number[];
  currentFrame: number;
  currentFrameDotColor?: string;
  lineColor?: string;
}

const GaitChart: FC<GaitChartProps> = ({
  data,
  currentFrame,
  lineColor = 'orange',
  currentFrameDotColor = 'red',
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      {expanded && (
        <LineChart
          height={300}
          width={500}
          margin={{ left: 60 }}
          slotProps={{
            legend: {
              labelStyle: {
                fontSize: 16,
                fontWeight: 400,
              },
            },
          }}
          sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              // Move the y-axis label with CSS
              transform: 'translateX(-20px)',
            },
          }}
          series={[
            {
              data: data,
              color: lineColor,
              showMark: false,
            },
          ]}
          xAxis={[
            {
              data: Array.from({ length: data.length }, (_, i) => i),
              label: 'frame',
            },
          ]}
          yAxis={[
            {
              label: 'Distance (cm)',
            },
          ]}
        >
          <ChartsReferenceLine
            lineStyle={{
              strokeDasharray: '3 3',
            }}
            x={currentFrame}
          />
          <CurrentPositionCircle
            y={frameToData(currentFrame, data)}
            x={currentFrame}
            color={currentFrameDotColor}
          />
        </LineChart>
      )}
      <CustomButtonContainer>
        <CustomButton onClick={handleToggle}>
          {expanded ? 'Collapse graph' : 'Show expanded graph'}
        </CustomButton>
      </CustomButtonContainer>
    </div>
  );
};

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 16px;
`;

const AssessmentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ChartTitle = styled('div')`
  font-weight: 600;
  font-size: 16px;
`;

const CustomButtonContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CustomButton = styled('button')`
  background: none;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  border: none;
  cursor: pointer;
  color: #6e6e6e;
`;
