import { FC, ReactNode } from 'react';
import { AppBreadcrumbs } from '../AppBreadcrumbs';
import { SideBar } from '../SideBar';
import {
  BreadcrumbsWrapper,
  ContentWrapper,
  HeaderContent,
  HeaderContainer,
} from './styles';
import { HeaderRouteActions } from './HeaderRouteActions';
import { NotificationButton } from '../../modules/notification';

export const AuthorizedLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <SideBar />
      <ContentWrapper>
        <HeaderContent>
          <BreadcrumbsWrapper>
            <AppBreadcrumbs />
          </BreadcrumbsWrapper>
          <HeaderContainer>
            <HeaderRouteActions />
            {/* <HeaderButton
              color='primary'
              size='large'
              // disableRipple
            >
              <LupeIcon fontSize='small' />
            </HeaderButton> */}
            <NotificationButton />
          </HeaderContainer>
        </HeaderContent>
        {children}
      </ContentWrapper>
    </>
  );
};
