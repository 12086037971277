import { Card } from '../../../ui';
import { usePatientContext } from '../../containers/PatientContainer/usePatientContext';
import { subThreeMonths, useChartData } from '../../hooks';
import { EmptyMessage } from './styles';
import { Chart } from '../Chart';
import { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material';
import { DateRangeModal } from '../DateRangePicker';

const healthDataKeyToLabel: { [key: string]: string } = {
  steps: 'Steps',
  floors_climbed: 'Floors Climbed',
  double_support_time: 'Double Support Time',
  step_cadence: 'Step Cadence',
  walking_asymmetry: 'Walking Asymmetry',
  walking_speed: 'Walking Speed',
  walking_step_length: 'Walking Step Length',
};

const clinicalKeyToLabel: { [key: string]: string } = {
  forward_bend: 'Forward Bend',
  msl: 'Mid Sacral Line',
  plumb_shift: 'Plumb Shift',
  predicted_cobb: 'Predicted Cobb Angle',
  shoulder_asymmetry: 'Shoulder Asymmetry',
  trunk_shift: 'Trunk Shift',
  waist_asymmetry: 'Waist Asymmetry',
};

const keyToLabel: { [key: string]: string } = {
  ...clinicalKeyToLabel,
  ...healthDataKeyToLabel,
};

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
enum ButtonGroupOptions {
  THREE = 'three',
  YTD = 'ytd',
  ALL_TIME = 'all_time',
  CUSTOM = 'custom',
}

export const PatientProgressionCard = () => {
  const { patient } = usePatientContext();

  const { chartData, loading, setDateRange, dateRange } = useChartData(
    patient.id
  );

  const [selected, setSelected] = useState<ButtonGroupOptions>(
    ButtonGroupOptions.THREE
  );
  const handleButtonSelect = (value: ButtonGroupOptions) => {
    setSelected(value);
  };
  const [leftAxis, setLeftAxis] = useState({
    axisName: keyToLabel['predicted_cobb'],
    dataKey: 'predicted_cobb',
    label: keyToLabel['predicted_cobb'],
    color: 'blue',
    yAxisKey: 'leftAxis',
    connectNulls: true,
    valueFormatter: (value: any) => (value ? value.toString() : 'NA'),
  });

  const [rightAxis, setRightAxis] = useState({
    axisName: keyToLabel['walking_asymmetry'],
    dataKey: 'walking_asymmetry',
    label: keyToLabel['walking_asymmetry'],
    color: 'lightblue',
    yAxisKey: 'rightAxis',
    connectNulls: true,
    valueFormatter: (value: any) => (value ? value.toString() : 'NA'),
  });

  const handleChangeLeft = (value: SelectChangeEvent<string>) => {
    const key = value.target.value;
    setLeftAxis((prev) => ({
      ...prev,
      axisName: keyToLabel[key],
      dataKey: key,
      label: keyToLabel[key],
    }));
  };

  const handleChangeRight = (value: SelectChangeEvent<string>) => {
    const key = value.target.value;
    setRightAxis((prev) => ({
      ...prev,
      axisName: keyToLabel[key],
      dataKey: key,
      label: keyToLabel[key],
    }));
  };

  return (
    <Card height='100%' loading={loading}>
      <ControlsContainer>
        <div>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id='left-axis-select-label'>First Metric</InputLabel>
            <Select
              labelId='left-axis-select-label'
              id='left-axis-select'
              value={leftAxis.dataKey}
              onChange={handleChangeLeft}
              input={<Input />}
              renderValue={(selected) => keyToLabel[selected]}
              MenuProps={MenuProps}
            >
              <ListSubheader>Clinical</ListSubheader>
              {Object.keys(clinicalKeyToLabel).map((key) => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={key === leftAxis.dataKey} size='small' />
                  <ListItemText primary={clinicalKeyToLabel[key]} />
                </MenuItem>
              ))}
              <ListSubheader>Health Data</ListSubheader>
              {Object.keys(healthDataKeyToLabel).map((key) => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={key === leftAxis.dataKey} size='small' />
                  <ListItemText primary={healthDataKeyToLabel[key]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id='right-axis-select-label'>Second Metric</InputLabel>
            <Select
              labelId='right-axis-select-label'
              id='right-axis-select'
              value={rightAxis.dataKey}
              onChange={handleChangeRight}
              input={<Input />}
              renderValue={(selected) => keyToLabel[selected]}
              MenuProps={MenuProps}
            >
              {Object.keys(clinicalKeyToLabel).map((key) => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={key === rightAxis.dataKey} size='small' />
                  <ListItemText primary={clinicalKeyToLabel[key]} />
                </MenuItem>
              ))}
              <ListSubheader>Health Data</ListSubheader>
              {Object.keys(healthDataKeyToLabel).map((key) => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={key === rightAxis.dataKey} size='small' />
                  <ListItemText primary={healthDataKeyToLabel[key]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <DateSelectorContainer>
          <Button
            variant={
              selected === ButtonGroupOptions.THREE ? 'containedLight' : 'text'
            }
            onClick={() => {
              handleButtonSelect(ButtonGroupOptions.THREE);
              setDateRange({
                start: subThreeMonths(new Date()),
                end: new Date(),
              });
            }}
          >
            Last 3 Months
          </Button>
          <Button
            variant={
              selected === ButtonGroupOptions.YTD ? 'containedLight' : 'text'
            }
            onClick={() => {
              handleButtonSelect(ButtonGroupOptions.YTD);
              setDateRange({
                start: new Date(new Date().getFullYear(), 0, 1),
                end: new Date(),
              });
            }}
          >
            YTD
          </Button>
          <Button
            variant={
              selected === ButtonGroupOptions.ALL_TIME
                ? 'containedLight'
                : 'text'
            }
            onClick={() => {
              handleButtonSelect(ButtonGroupOptions.ALL_TIME);
              setDateRange({
                start: new Date(2023, 0, 1), // just put a random year that is before the first data point
                end: new Date(),
              });
            }}
          >
            All Time
          </Button>
          <DateRangeModal
            disabled={selected === ButtonGroupOptions.CUSTOM}
            onClick={() => handleButtonSelect(ButtonGroupOptions.CUSTOM)}
            start={dateRange.start}
            end={dateRange.end}
            onSubmit={(start, end) => setDateRange({ start, end })}
          />
        </DateSelectorContainer>
      </ControlsContainer>
      {chartData && chartData.length > 0 ? (
        <Chart
          chartData={chartData}
          leftAxis={leftAxis}
          rightAxis={rightAxis}
        />
      ) : (
        <EmptyMessage variant='bodyLarge'>
          No data for selected period.
        </EmptyMessage>
      )}
    </Card>
  );
};

const ControlsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
`;

const DateSelectorContainer = styled('div')``;
