import { FC } from 'react';
import { Card, styled } from '@mui/material';

export const SignUpSuccess: FC = () => {
  return (
    <RootLayout>
      <StyledCard>
        <h1>Verify Email</h1>
        <p>Please follow the link in the email we just sent you.</p>
      </StyledCard>
    </RootLayout>
  );
};

const RootLayout = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  text-align: center;
`;

const StyledCard = styled(Card)`
  padding: 20px;
  margin: 20px;
`;
