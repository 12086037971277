import { Slider, styled } from '@mui/material';
import { FC } from 'react';

interface StaticAverageRangeProps {
  value: number;
  min: number;
  max: number;
  step: number;
}

export const FDAStaticSlider: FC<StaticAverageRangeProps> = ({
  value,
  min,
  max,
  step,
}) => {
  const labels = Array.from({ length: (max - min) / step + 1 }, (_, i) => {
    return min + i * step;
  });

  return (
    <StaticSliderContainer>
      <StaticSlider
        defaultValue={value}
        min={min}
        max={max}
        step={step}
        disabled
        marks={labels.map((label) => ({
          value: label,
          label: label.toString(),
        }))}
      />
    </StaticSliderContainer>
  );
};

const StaticSliderContainer = styled('div')`
  width: 100%;
`;

const StaticSlider = styled(Slider)`
  & .MuiSlider-rail {
    background: linear-gradient(
      90deg,
      #a20643 10%,
      #f57748 20%,
      #fdbd6f 30%,
      #e4f598 40%,
      #79c9a5 50%,
      #e4f598 60%,
      #fdf1a5 70%,
      #fdbd6f 80%,
      #f57748 90%,
      #a20643 100%
    );
    height: 28px;
    border-radius: 24px;
    opacity: 1;
  }
  & .MuiSlider-mark {
    display: none;
  }
  & .MuiSlider-track {
    background: none;
    border: none;
  }
  & .MuiSlider-thumb {
    width: 6px;
    height: 35px;
    border-radius: 12px;
    background: white;
    box-shadow: 0px 3px 8px 0px #0000003d;
  }
`;
