import { styled } from '@mui/material';
import bottomCenter from '../../assets/image/figures/bottomCenter.svg';
import bottomLeft from '../../assets/image/figures/bottomLeft.svg';
import bottomRight from '../../assets/image/figures/bottomRight.svg';
import topLeft from '../../assets/image/figures/topLeft.svg';
import topRight from '../../assets/image/figures/topRight.svg';

export const ScreenWrapper = styled('div')`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: url(${topLeft}) 16% top / auto no-repeat,
    url(${topRight}) right 60px / auto no-repeat,
    url(${bottomLeft}) 18px calc(100% - 18px) / auto no-repeat,
    url(${bottomRight}) right bottom / auto no-repeat,
    url(${bottomCenter}) center 110% / contain no-repeat;
  background-color: #fff;
  overflow: hidden;
`;

export const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px 40px;
  height: 90px;
`;

export const Logo = styled('img')`
  width: 280px;
  height: 100%;
  @media (max-width: 600px) {
    width: 175px;
  }
  @media (max-width: 300px) {
    width: 100px;
  }
`;

export const ContentWrapper = styled('div')`
  display: flex;
  justify-content: center;
  height: calc(100% - 90px);
`;
