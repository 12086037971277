import { FC, memo } from 'react';
import { Typography } from '@mui/material';
import { BadgeWrapper } from './styles';

export const Badge: FC<{ children: string | null }> = memo(({ children }) => {
  return (
    <BadgeWrapper>
      <Typography variant='button'>{children}</Typography>
    </BadgeWrapper>
  );
});
