import { FC, useCallback, useEffect } from 'react';
import { useCreateNote } from '../../../hooks';
import { ValidationErrors } from 'final-form';
import { enqueueSnackbar } from 'notistack';
import { Form } from 'react-final-form';
import { FormStackVertical, TextInputFF } from '../../../../ui';
import { Button, styled } from '@mui/material';

interface AddNoteFormProps {
  patientId: number;
  reload: () => void;
  setIsEditing: (isEditing: boolean) => void;
}

export const AddNoteForm: FC<AddNoteFormProps> = ({
  patientId,
  reload,
  setIsEditing,
}) => {
  const [result, loading, error, request] = useCreateNote();

  const onSubmit = useCallback(
    (values: any) => {
      request({
        ...values,
        patient: patientId,
      });
    },
    [request, patientId]
  );

  const validate = useCallback((values: any) => {
    const result: ValidationErrors = {};

    if (!values.note_value) {
      result.note_value = true;
    }

    return result;
  }, []);

  const handleCancel = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    if (result) {
      enqueueSnackbar('Note created successfully', { variant: 'success' });
      setIsEditing(false);
      reload();
    }
  }, [result, error, reload, setIsEditing]);

  return (
    <FormRoot>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} onReset={handleCancel} noValidate>
            <FormStackVertical>
              <TextInputFF autoFocus name='note_value' multiline rows={4} />
              <FormButtonContainer>
                <div>
                  <Button
                    fullWidth
                    variant='contained'
                    type='submit'
                    disabled={loading}
                  >
                    Create Note
                  </Button>
                </div>
                <div>
                  <Button
                    fullWidth
                    variant='containedLight'
                    type='reset'
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </div>
              </FormButtonContainer>
            </FormStackVertical>
          </form>
        )}
      />
    </FormRoot>
  );
};

const FormRoot = styled('div')`
  margin: 16px 0;
`;

const FormButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
