import { FC, useContext, useState } from 'react';
import { Slider, styled, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { FunctionalDynamicAssessmentContext } from '../context';

interface FDAMeshViewerControlsProps {
  playPause: () => void;
  isPlaying: boolean;
  currentFrame: number;
  changeTimeScale: (timeScale: number) => void;
  totalFrames: number;
  seek: (value: number) => void;
  seekStart: () => void;
  seekEnd: () => void;
}
export const FDAMeshViewerControls: FC<FDAMeshViewerControlsProps> = ({
  playPause,
  isPlaying,
  currentFrame,
  changeTimeScale,
  totalFrames,
  seek,
  seekStart,
  seekEnd,
}) => {
  const [playbackSpeed, setPlaybackSpeed] = useState(1);

  const handleSeek = (_event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      seek && seek(newValue);
    }
  };

  const handlePlaybackSpeedChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPlaybackSpeed(parseFloat(event.target.value));
    changeTimeScale(parseFloat(event.target.value));
  };

  return (
    <ControlsRoot>
      <AnimationControls>
        <Tooltip title='seek'>
          <SeekSlider
            min={0}
            value={currentFrame}
            max={totalFrames - 1}
            onChange={handleSeek}
            onMouseDown={seekStart}
            onMouseUp={seekEnd}
            sx={{
              '& .MuiSlider-thumb': {
                transition: 'none',
              },
              '& .MuiSlider-track': {
                transition: 'none',
              },
            }}
          />
        </Tooltip>

        <Tooltip title='playback speed'>
          <PlaybackSpeedSelect
            value={playbackSpeed}
            onChange={handlePlaybackSpeedChange}
          >
            <option value={0.25}>0.25</option>
            <option value={0.5}>0.5</option>
            <option value={0.75}>0.75</option>
            <option value={1}>1</option>
            <option value={1.25}>1.25</option>
            <option value={1.5}>1.5</option>
            <option value={1.75}>1.75</option>
            <option value={2}>2</option>
          </PlaybackSpeedSelect>
        </Tooltip>
        <Tooltip title={isPlaying ? 'pause' : 'play'}>
          <IconButton onClick={playPause} sx={{ color: 'white' }}>
            {isPlaying ? <PauseCircleIcon /> : <PlayCircleIcon />}
          </IconButton>
        </Tooltip>
      </AnimationControls>
    </ControlsRoot>
  );
};

export const BalanceGaitSwitcher = () => {
  const { handleSwitch, selectedView } = useContext(
    FunctionalDynamicAssessmentContext
  );

  const handleChange = (value: 'balance' | 'gait') => {
    handleSwitch(value);
  };
  return (
    <BalanceGaitSwitchContainer>
      <BalanceGaitSwitcherButton
        title='Standing'
        selected={selectedView === 'balance'}
        onClick={() => handleChange('balance')}
      />
      <BalanceGaitSwitchSeparator />
      <BalanceGaitSwitcherButton
        title='Walking'
        selected={selectedView === 'gait'}
        onClick={() => handleChange('gait')}
      />
    </BalanceGaitSwitchContainer>
  );
};

interface BalanceGaitSwitcherButtonProps {
  title: string;
  selected: boolean;
  onClick: () => void;
}

const BalanceGaitSwitcherButton: FC<BalanceGaitSwitcherButtonProps> = ({
  title,
  selected,
  onClick,
}) => {
  return (
    <BalanceGaitSwitchButton onClick={onClick}>
      {selected && <ActiveButtonIndicator />}
      {title}
    </BalanceGaitSwitchButton>
  );
};

const BalanceGaitSwitchContainer = styled('div')`
  background: black;
  display: flex;
  align-items: center;
  border-radius: 50px;
  gap: 8px;
  padding: 0 16px;
  position: absolute;
  height: 40px;
  top: 12px;
  left: 12px;
`;
const BalanceGaitSwitchSeparator = styled('div')`
  height: 60%;
  width: 1px;
  background: #ffffff99;
`;

const BalanceGaitSwitchButton = styled('button')`
  background: none;
  display: flex;
  align-items: center;
  gap: 8px;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 14px;
  color: white;
  cursor: pointer;
`;

const ActiveButtonIndicator = styled('span')`
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background: #7cd2d1;
  display: inline-block;
`;

const ControlsRoot = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const AnimationControls = styled('div')`
  width: 350px;
  display: flex;
  align-items: center;
  background: black;
  border-radius: 50px;
  padding-left: 24px;
  padding-right: 8px;
  justify-content: space-between;
  gap: 8px;
`;

const PlaybackSpeedSelect = styled('select')`
  background: none;
  outline: none;
  border: none;
  color: white;
  font-weight: bold;
`;

const SeekSlider = styled(Slider)`
  width: 100%;
  & .MuiSlider-rail {
    background: grey;
    height: 10px;
    opacity: 1;
  }
  & .MuiSlider-track {
    transition: none;
    border: none;
    outline: none;
    height: 10px;
    background: lightgray;
  }
  & .MuiSlider-thumb {
    width: 17px;
    height: 17px;
    background: white;
    transition: none;
  }
`;
