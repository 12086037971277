import { Alert, Grid, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { Loader, useListDataRequest } from '../../../ui';
import { useScan } from '../../hooks';
import { getScanList } from '../../network';
import { ScanCompareContainer } from './ScanCompareContainer';
import { ScanCompareHistory } from './ScanCompareHistory';
import { ScanCompareView } from './ScanCompareView';
import { EmptyContainer, LoadingContainer } from './styles';
import { DndContainer } from './DndContainer';

export interface ScanCompareProps {
  patientId: string | number;
  selectedScanId?: string | number | null;
}

export const ScanCompare: FC<ScanCompareProps> = (props) => {
  const { patientId, selectedScanId } = props;

  const listParams = useMemo(
    () => ({
      filters: {
        patient: patientId,
      },
      pagination: {
        size: 1000,
        page: 1,
      },
    }),
    [patientId]
  );
  const { data, loading, error } = useListDataRequest(getScanList, listParams);
  const [selectedScan, selectedScanLoading] = useScan(selectedScanId);

  if (loading || selectedScanLoading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  if (error) {
    return <Alert color='error'>{error.message}</Alert>;
  }

  if (!data || data.length === 0) {
    return (
      <EmptyContainer>
        <Typography variant='h4'>Patient don’t have scans yet...</Typography>
      </EmptyContainer>
    );
  }

  return (
    <ScanCompareContainer scans={data} selectedScanFirst={selectedScan}>
      <DndContainer>
        <Grid container columnSpacing={4} alignItems='stretch' mt={0}>
          <Grid item xs={10} xl={10}>
            <ScanCompareView />
          </Grid>
          <Grid item xs={2} xl={2}>
            <ScanCompareHistory />
          </Grid>
        </Grid>
      </DndContainer>
    </ScanCompareContainer>
  );
};
