import { Button, IconButton } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePatientContext } from '../../containers/PatientContainer/usePatientContext';
import { ButtonContainer, NextScanContainer, WarningLabel } from './styles';
import { formatNextScanDate } from '../../../ui/components/DataGridPro/helpers';
import { Card, DateInputFF } from '../../../ui';
import { Form } from 'react-final-form';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import { sendNextScanReminder } from '../../../ui/components/DataGridResendIcon/network';
import { enqueueSnackbar } from 'notistack';
import { ValidationErrors } from 'final-form';
import { usePatientUpdate } from '../../hooks';

export const PatientNextScanCard = () => {
  const { patient, reload } = usePatientContext();
  const [result, isLoadingPatientUpdate, , request] = usePatientUpdate();

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const handleStartEditing = () => {
    setIsEditing(true);
  };

  const handleReset = () => {
    setIsEditing(false);
  };

  const handleSendReminder = async () => {
    try {
      setIsLoading(true);
      const response = await sendNextScanReminder({ patient_id: patient.id });
      enqueueSnackbar(response['Success'], { variant: 'success' });
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: 'error' });
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = useCallback(
    (values: any) => {
      values.id = patient.id;
      request(values);
      setIsEditing(false);
    },
    [request, patient.id]
  );

  const validate = useCallback((values: any) => {
    const result: ValidationErrors = {};

    if (!values.next_scan_date) {
      result.next_scan_date = true;
    }

    if (isNaN(new Date(values.next_scan_date).getTime()) === true) {
      result.next_scan_date = true;
    }

    return result;
  }, []);

  const { formattedDate, isPastDate } = useMemo(() => {
    return formatNextScanDate(patient.next_scan_date);
  }, [patient.next_scan_date]);

  useEffect(() => {
    if (result) {
      enqueueSnackbar('Patient updated successfully', { variant: 'success' });
      reload();
    }
  }, [result, reload]);

  return (
    <Card
      title='Next Scan'
      rightActions={
        isEditing ? undefined : (
          <IconButton size='small' onClick={handleStartEditing}>
            <EditIcon />
          </IconButton>
        )
      }
    >
      <NextScanContainer>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={{ next_scan_date: formattedDate }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              <DateInputFF
                name='next_scan_date'
                disabled={!isEditing}
                label={
                  isPastDate ? (
                    <WarningLabel>
                      <WarningIcon fontSize='small' />
                      Scan is overdue
                    </WarningLabel>
                  ) : (
                    'Next Scan Date'
                  )
                }
                fullWidth
                error={isPastDate}
              />

              {isEditing && (
                <ButtonContainer>
                  <div>
                    <Button
                      fullWidth
                      variant='contained'
                      type='submit'
                      disabled={isLoadingPatientUpdate}
                    >
                      Save Changes
                    </Button>
                  </div>
                  <div>
                    <Button
                      fullWidth
                      variant='containedLight'
                      type='reset'
                      disabled={isLoadingPatientUpdate}
                    >
                      Cancel
                    </Button>
                  </div>
                </ButtonContainer>
              )}
            </form>
          )}
        />

        {!isEditing && (
          <ButtonContainer>
            <Button
              variant={isPastDate ? 'contained' : 'containedLight'}
              fullWidth
              disabled={loading}
              onClick={handleSendReminder}
            >
              Send Reminder
            </Button>
          </ButtonContainer>
        )}
      </NextScanContainer>
    </Card>
  );
};
