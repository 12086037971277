import { FC, useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import {
  LoaderContainer,
  PositionedStack,
  InfoBadge,
  Title,
  HeaderContainer,
  ExpandIcon,
  CollapseIcon,
  LayersContainer,
  LayerRow,
  RowContainer,
  InformationCircleIcon,
  MeshLegendIcons,
} from '../styles';
import { MeshService } from './MeshService';
import { Loader } from '../../../../ui';
import { layersInfo } from './layers';
import { PreloadUrl } from './types';
import { useScanLayerControls } from '../../../hooks/useScanLayerControls';
import { useScanCompareContext } from '../../ScanCompare/useScanCompareContext';

export interface MeshMetaData {
  centroid_coordinates: number[][];
  layer_rotations: number[];
  pseudo_spine: number[][];
  slice_thickness: number[];
  shoulder1_coordinates: number[];
  shoulder2_coordinates: number[];
  plumb_shift_plane: number;
  msl_plane: number;
  trunk_plane: number;
}

export interface MeshViewerProps {
  mesh_url: string;
  deviation_mesh_url: string | null;
  mesh_type: string;
  mesh_texture_url: string | null;
  mesh_metadata: MeshMetaData | null;
  plumb_shift: number | null;
  shoulder_asymmetry: number | null;
  trunk_shift: number | null;
  preloadUrls?: PreloadUrl[] | undefined;
  name?: 'first' | 'second';
}

export const MeshViewer: FC<MeshViewerProps> = (props) => {
  const {
    mesh_url,
    deviation_mesh_url,
    mesh_type,
    mesh_texture_url,
    mesh_metadata,
    preloadUrls,
  } = props;

  const { splitMode, synced, syncedLayerState } = useScanCompareContext();
  const { layerState, toggleLayer } = useScanLayerControls();

  const [loading, setLoading] = useState<boolean>(false);
  const containerRef = useRef<any>();
  const serviceRef = useRef<MeshService | null>(null);
  const [isLayersExpanded, setIsLayersExpanded] = useState(true);

  // initialize the meshService
  useEffect(() => {
    if (containerRef.current && !serviceRef.current) {
      const service = new MeshService(
        {
          container: containerRef.current,
        },
        synced ? syncedLayerState : layerState,
        props.name
      );
      serviceRef.current = service;
    }
  }, [synced, layerState, syncedLayerState, props.name]);

  useEffect(() => {
    if (containerRef.current && serviceRef.current && mesh_url) {
      const service = serviceRef.current;

      service.on('loadStart', () => setLoading(true));
      service.on('loadEnd', () => setLoading(false));
      service.loadModel(
        mesh_url.replace('http://', 'https://'),
        deviation_mesh_url,
        mesh_texture_url,
        mesh_metadata
      );
      if (preloadUrls && preloadUrls.length) {
        // this will load the urls and add them to the cache
        try {
          service.addUrlsToCache(preloadUrls);
        } catch (error) {
          console.error('Error adding URLs to cache: ', error);
        }
      }
    }
  }, [
    mesh_url,
    mesh_metadata,
    mesh_texture_url,
    deviation_mesh_url,
    preloadUrls,
  ]);

  useEffect(() => {
    if (serviceRef.current && layerState) {
      if (synced) {
        serviceRef.current.updateLayers(syncedLayerState);
      } else {
        serviceRef.current.updateLayers(layerState);
      }
    }
  }, [layerState, syncedLayerState, synced]);

  const shouldHideToggles = splitMode === true && synced === true;

  return (
    <div
      ref={containerRef}
      style={{ position: 'relative', width: '100%', height: '100%' }}
    >
      {shouldHideToggles === false && (
        <PositionedStack
          position='br'
          style={{
            width: 'fit-content',
            justifyContent: 'space-evenly',
            alignItems: 'space-evenly',
            zIndex: 10,
          }}
        >
          <InfoBadge backgroundColor='rgba(255, 255, 255, 0.9)'>
            <HeaderContainer>
              <Title>Controls</Title>
              {!isLayersExpanded && (
                <ExpandIcon
                  onClick={() => setIsLayersExpanded(true)}
                  fontSize='inherit'
                />
              )}
              {isLayersExpanded && (
                <CollapseIcon
                  onClick={() => setIsLayersExpanded(false)}
                  fontSize='inherit'
                />
              )}
            </HeaderContainer>
            {isLayersExpanded && (
              <LayersContainer>
                {layersInfo.map((value) => {
                  return value.display_type === mesh_type ||
                    value.display_type === 'dual' ? (
                    <LayerRow id={value.id} key={value.id}>
                      <RowContainer>
                        <MeshLegendIcons src={value.icon} alt={value.alt} />
                        <label
                          style={{
                            fontSize: '0.8rem',
                            textAlign: 'center',
                            lineHeight: 1.25,
                          }}
                        >
                          {value.label}{' '}
                          {value.unit !== null &&
                            value.featureset_key !== null &&
                            props[
                              value.featureset_key as keyof MeshViewerProps
                            ] !== null &&
                            `(${(
                              props[
                                value.featureset_key as keyof MeshViewerProps
                              ] as number
                            ).toFixed(1)} ${value.unit})`}
                        </label>
                        <Tooltip
                          title={value.description}
                          placement={'right'}
                          enterDelay={300}
                        >
                          <span>
                            <InformationCircleIcon fontSize='inherit' />
                          </span>
                        </Tooltip>
                      </RowContainer>
                      <input
                        type='checkbox'
                        checked={layerState[value.id]}
                        onChange={() => toggleLayer(value.id)}
                      />
                    </LayerRow>
                  ) : null;
                })}
              </LayersContainer>
            )}
          </InfoBadge>
        </PositionedStack>
      )}
      {loading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </div>
  );
};
