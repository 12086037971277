import { createContext } from 'react';
import { ListData, ListFiltersState, ListOrderingState, ListPaginationState } from './types';

export type ListContextValue = {
  sort?: any;
  isLoading?: any;
  selectedIds?: any;
  setSort?: any;

  data: ListData['data'] | null;
  count?: ListData['count'] | null;
  loading: boolean;
  error: Error | null;
  filters: ListFiltersState | null;
  ordering: ListOrderingState | null;
  pagination: ListPaginationState;
  reload: () => void;
  goToNextPage: () => void;
  goToPrevPage: () => void;
  goToFirstPage: () => void;
  goToPage: (page: number) => void;
  setFilters: React.Dispatch<React.SetStateAction<ListFiltersState | null>>;
  setOrdering: React.Dispatch<React.SetStateAction<ListOrderingState | null>>;
  setPagination: React.Dispatch<React.SetStateAction<ListPaginationState>>;
};

export const ListContext = createContext<ListContextValue>(
  {} as ListContextValue
);
