import { FC, useContext, useEffect, useState } from 'react';
import { ScanItemViewer } from '../scan/components/ScanItemViewer';
import { ScanModel } from '../scan';
import { PreloadUrl } from '../scan/components/ScanItemViewer/MeshViewer/types';
import { findTwoClosestItemsInArray, createMeshViewerLoadUrls } from './utils';
import { AllScansContext } from './AllScansContext';
import {
  AllScansMeshViewerContainer,
  AllScansMeshViewerSkeleton,
} from './styles';

interface PreloadMeshViewerProps {
  scan: ScanModel | undefined;
}

export const PreloadMeshViewer: FC<PreloadMeshViewerProps> = ({ scan }) => {
  const { data, apiRef, loading, setIncomingPatchRequest, setLoadTable } =
    useContext(AllScansContext);
  const [closestScans, setClosestScans] = useState<PreloadUrl[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (data && scan && apiRef.current) {
      const sortedData = apiRef.current?.getSortedRows?.();
      const scanIndex = sortedData.findIndex((item) => item.id === scan.id);
      const closestItems = findTwoClosestItemsInArray(
        sortedData,
        scanIndex
      ) as ScanModel[];
      const formatted = createMeshViewerLoadUrls(closestItems);

      setClosestScans(formatted);
    }
  }, [scan, data, apiRef]);

  return (
    <AllScansMeshViewerContainer>
      {scan !== undefined ? (
        <ScanItemViewer
          item={scan}
          nextItems={closestScans}
          setIncomingPatchRequest={setIncomingPatchRequest}
          setLoadTable={setLoadTable}
          loading={loading}
        />
      ) : (
        <AllScansMeshViewerSkeleton>
          <h2>select a scan</h2>
        </AllScansMeshViewerSkeleton>
      )}
    </AllScansMeshViewerContainer>
  );
};
