import { styled } from '@mui/material';

export const CountBlock = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & + & {
    margin: 0 64px;
  }
`;

export const CountsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BorderLine = styled('div')`
  width: 1px;
  height: 77px;
  background: #e5e9eb;
  margin: 0 64px;
`;
