import { Alert } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { WidgetCard, WidgetCount } from '../../../ui';
import { usePatientCount } from '../../hooks';

export const PatientCountWidget = () => {
  const navigate = useNavigate();
  const [data, loading, error] = usePatientCount();

  const onRowClick = useCallback(() => {
    navigate('/patients/');
  }, [navigate]);

  let badge = null;

  if (data && data.new && data.new > 0) {
    badge = `+${data.new}`;
  }

  return (
    <WidgetCard
      title='Patients'
      badgeLabel={badge}
      onClick={onRowClick}
      loading={loading}
      children={
        <>
          {data && (<WidgetCount count={data && data.total} />)}
          {error && <Alert color='error'>{error.message}</Alert>}
        </>
      }
    />
  );
};
