import { styled, Card, CardContent as MuiCardContent } from '@mui/material';

export const CardWrapper = styled(Card)<{
  height?: string;
}>`
  width: 100%;
  height: ${({ height = 'auto' }) => height};
  position: relative;
`;

export const CardBody = styled(MuiCardContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
`;

export const CardHeader = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`;

export const CardContent = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const CardLoader = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 40%);
`;
