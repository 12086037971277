import { useCallback, useState } from 'react';
import { PatientModel, updatePatient } from '../network';

export const usePatientUpdate = (): [
  PatientModel | null,
  boolean,
  Error | null,
  (data: PatientModel) => void,
] => {
  const [result, setResult] = useState<PatientModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (data: PatientModel) => {
      setIsLoading(true);
      setError(null);

      try {
        const result = await updatePatient(data);
        setResult(result);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          const error = new Error('Unknown error');
          setError(error);
        }
      }

      setIsLoading(false);
    },
    []
  );

  return [result, isLoading, error, request];
};
