import { requestList, patchRequest, retriggerScanRequest } from '../../network';
import { ScanModel, FeaturesetValues } from '../scan';
import { ListData, ListDataRequestParams } from '../ui';

//const constructQuerp

export const getSuperRecords = async (
  data?: ListDataRequestParams
): Promise<ListData<ScanModel>> => {
  const response = await requestList('/api/super_records/', data);

  return response;
};

export const patchSuperRecord = async (
  id: number,
  patchData: Partial<ScanModel> = {}
): Promise<ScanModel> => {
  const response = await patchRequest(`/api/super_records/${id}/`, patchData);

  return response as ScanModel;
};

export const retriggerScan = async (postData: {
  id: number;
}): Promise<{ Success: 'Processing retriggered successfully' }> => {
  const response = await retriggerScanRequest(
    '/api/retrigger_record_processing/',
    postData
  );

  return response;
};

export const patchFeatureSet = async (
  id: number,
  patchData: Partial<FeaturesetValues> = {}
): Promise<FeaturesetValues> => {
  const response = await patchRequest(`/api/feature_set/${id}/`, patchData);

  return response as FeaturesetValues;
};
