import { FC, useState } from 'react';
import { TypographyProps } from '@mui/material';
import { IconButton, Tooltip } from '@mui/material';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

import {
  DataGridFieldBaseProps,
  DataGridFieldInjectedProps,
  sanitizeDataGridFieldRestProps,
  DataGridFieldValueFormat,
} from '../DataGridPro';
import { resendInvitation, sendNextScanReminder } from './network';
import { enqueueSnackbar } from 'notistack';

export type DataGridResendIconProps = DataGridFieldBaseProps &
  DataGridFieldInjectedProps &
  TypographyProps & {
    format?: DataGridFieldValueFormat;
    resendtype?: 'invitation' | 'next_scan';
    hide?: boolean;
  };

interface ResendActionApiResponse {
  Success: string;
}

export const DataGridResendIcon: FC<DataGridResendIconProps> = (props) => {
  const {
    record,
    onMouseOver,
    onMouseOut,
    selectedRowID,
    resendtype,
    hide = false,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSendAction = async (user_id: number, patient_id: number) => {
    setIsLoading(true);

    try {
      let response: ResendActionApiResponse = { Success: '' };

      if (resendtype === 'invitation') {
        response = await resendInvitation({ user_id: user_id });
      }
      if (resendtype === 'next_scan') {
        response = await sendNextScanReminder({ patient_id: patient_id });
      }

      enqueueSnackbar(response['Success'], { variant: 'success' });
    } catch (e: any) {
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const renderIcon = () => {
    if (hide) {
      return null;
    }

    return (
      <Tooltip
        title={
          resendtype === 'invitation'
            ? 'Resend Invitation'
            : 'Send Next Scan Reminder'
        }
        disableInteractive
      >
        <IconButton
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          type='button'
          {...sanitizeDataGridFieldRestProps(props)}
          onClick={() => handleSendAction(record.user, record.id)}
          disabled={isLoading && selectedRowID === record.id}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              background:
                'linear-gradient(114.04deg, #52489C 3.25%, #8F78F0 100%)',
              color: 'white',
            },
          }}
        >
          {resendtype === 'invitation' ? (
            <InsertInvitationIcon />
          ) : (
            <ScheduleSendIcon />
          )}
        </IconButton>
      </Tooltip>
    );
  };

  return renderIcon();
};
