import { Button } from '@mui/material';
import { FC, ReactNode } from 'react';
import { authService } from '../../../auth';
import { FullscreenStatus } from '../../../ui';
import { useCurrentUser } from '../../hooks';
import { AccountProvider } from './AccountProvider';

export interface AccountContainerProps {
  children: ReactNode;
}

export const AccountContainer: FC<AccountContainerProps> = (props) => {
  const { children } = props;
  const [data, loading, error, reload] = useCurrentUser();

  if (!data && loading) {
    return <FullscreenStatus loading />;
  }

  if (error || !data) {
    return (
      <FullscreenStatus
        failed
        message={
          <>
            {error?.message || 'Failed to load user'}
            <br />
            <Button onClick={authService.logout} variant='text'>Logout</Button>
          </>
        }
      />
    );
  }

  return (
    <AccountProvider user={data} updating={loading} reload={reload}>
      {children}
    </AccountProvider>
  );
};
