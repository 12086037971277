import { FC, ReactNode } from 'react';
import { Loader } from '../Loader';
import { IconContainer, Message, Root } from './styles';

export interface FullscreenStatusProps {
  loading?: boolean;
  failed?: boolean;
  message?: ReactNode;
}

export const FullscreenStatus: FC<FullscreenStatusProps> = (props) => {
  const { loading, failed, message } = props;

  if (!loading && !failed && !message) {
    return null;
  }

  return (
    <Root>
      <IconContainer>
        {loading && <Loader />}
        {!loading && failed && '🚫'}
      </IconContainer>
      <Message>{message}</Message>
    </Root>
  );
};
