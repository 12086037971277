import { styled } from '@mui/material';

export const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Footer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
`;
