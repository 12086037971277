import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { authService } from '../../services';
import { AuthContext } from './AuthContext';

export interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;

  const [initialized, setInitialized] = useState(false);
  const [authorized, setAuthorized] = useState(false);

  const contextValue = useMemo(() => {
    return {
      initialized,
      authorized,
      setAuthData: authService.setAuthData,
      logout: authService.logout,
    };
  }, [initialized, authorized]);

  useEffect(() => {
    setAuthorized(authService.authorized);
    setInitialized(authService.initialized);
    return authService.on('change', (data) => {
      setAuthorized(data.authorized);
      setInitialized(data.initialized);
    });
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
