import { useCallback, useState } from 'react';
import { restPassword } from '../network';

export interface RestPasswordData {
  password: string; 
  token: string;
}

export const useResetPassword = (): [
  any,
  boolean,
  Error | null,
  (data: RestPasswordData) => void,
] => {
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (data: RestPasswordData) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await restPassword(data);
        setResult(response);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          const error = new Error('Unknown error');
          setError(error);
        }
      }

      setIsLoading(false);
    },
    []
  );

  return [result, isLoading, error, request];
};
