import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Typography } from '@mui/material';
import { useScanCount } from '../../hooks/useScanCount';
import { WidgetCard, WidgetCount } from './../../../ui';
import { CountBlock, CountsContainer, BorderLine } from './styles';

export const ScanCountWidget = () => {
  const navigate = useNavigate();
  const [data, loading, error] = useScanCount();

  const onRowClick = useCallback(() => {
    navigate('/scans/');
  }, [navigate]);

  let badge = null;

  if (data && data.new && data.new > 0) {
    badge = `+${data.new}`;
  }

  return (
    <WidgetCard
      title='Scans'
      badgeLabel={badge}
      onClick={onRowClick}
      // collapsed='All time'
      loading={loading}
      children={
        <>
          {' '}
          {!error && (
            <>
              {data && (
                <CountsContainer>
                  <CountBlock>
                    <Typography variant='bodyLarge' color='#878787'>
                      All
                    </Typography>
                    {data && <WidgetCount count={data.total} />}
                  </CountBlock>
                  <BorderLine />
                  <CountBlock>
                    <Typography variant='bodyLarge' color='#878787'>
                      Unread
                    </Typography>
                    {data && <WidgetCount count={data.unread} />}
                  </CountBlock>
                </CountsContainer>
              )}
            </>
          )}
          {error && <Alert color='error'>{error.message}</Alert>}
        </>
      }
    />
  );
};
