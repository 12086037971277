import { request } from '../../../network';

export interface AuthTokenResponse {
  access: string;
  refresh: string;
}

export const getAuthToken = async (data: {
  username: string;
  password: string;
}): Promise<AuthTokenResponse> => {
  const response = await request({
    path: '/api/token/',
    method: 'POST',
    body: data,
    withAuth: false,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const refreshAuthToken = async (data: {
  refresh: string;
}): Promise<AuthTokenResponse> => {
  const response = await request({
    path: '/api/token/refresh/',
    method: 'POST',
    body: data,
    silent: true,
    withAuth: false,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export interface ForgotPasswordData {
  email: string;
}

export const forgotPassword = async (
  data: ForgotPasswordData
): Promise<any> => {
  const response = await request({
    path: '/api/password_reset/',
    method: 'POST',
    body: data,
    withAuth: false,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export interface ResetPasswordData {
  password: string;
  token: string;
}

export const restPassword = async (data: ResetPasswordData): Promise<any> => {
  const response = await request({
    path: '/api/password_reset/confirm/',
    method: 'POST',
    body: data,
    withAuth: false,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export interface LogoutData {
  refresh?: string;
}

export const logout = async (data: LogoutData): Promise<unknown> => {
  const response = await request({
    path: '/api/token/blacklist/',
    method: 'POST',
    body: data,
    withAuth: true,
    silent: true,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export interface CreateNewUserData {
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  password: string;
  birthday: string;
}

export const createNewUser = async (body: CreateNewUserData) => {
  const response = await request({
    path: '/api/patient_signup/',
    method: 'POST',
    body,
    withAuth: false,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export interface CreateNewUserDataWithOrgId extends CreateNewUserData {
  organization_id: string;
}

export const signupWithOrgId = async (body: CreateNewUserDataWithOrgId) => {
  const response = await request({
    path: '/api/organization_signup/',
    method: 'POST',
    body,
    withAuth: false,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};
