import { styled } from '@mui/system';

export const Root = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled('div')`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
`;

export const Message = styled('div')`
  width: 500px;
  max-width: 90%;
  margin-top: 24px;
  text-align: center;
`;
