import { useCallback, useState } from 'react';
import { contactUs, ContactUsData } from '../network';

export type UseContactUsResult = Awaited<ReturnType<typeof contactUs>>

export const useContactUs = (): [
  UseContactUsResult | null,
  boolean,
  Error | null,
  (data: ContactUsData) => void,
] => {
  const [result, setResult] = useState<UseContactUsResult | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (data: ContactUsData) => {
      setIsLoading(true);
      setError(null);

      try {
        const result = await contactUs(data);
        setResult(result);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          const error = new Error('Unknown error');
          setError(error);
        }
      }

      setIsLoading(false);
    },
    []
  );

  return [result, isLoading, error, request];
};
