import { GridColDef } from '@mui/x-data-grid';
import { ListContainer, ListContext, Loader } from '../../../ui';
import { FC, useContext } from 'react';
import { getFunctionalDynamicAssessmentList } from '../../network';
import { tableStyles } from '../../../ui/components/DataGridPro/styles';
import { styled, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { FDAListItem } from '../../types';
import { enqueueSnackbar } from 'notistack';
import { StatusItem } from '../../../scan/components/DataGridScanStatusField/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { format } from 'date-fns';
import { green, red, yellow } from '@mui/material/colors';

export const FDADashboardTable: FC = () => {
  return (
    <div>
      <Header>
        <Typography variant='h2'>
          Functional Dynamic Assessments (Beta)
        </Typography>
      </Header>
      <ListContainer dataRequest={getFunctionalDynamicAssessmentList}>
        <FunctionalDynamicAssessmentTable />
      </ListContainer>
    </div>
  );
};

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const StatusText = styled(Typography)`
  font-weight: bold;
  margin-left: 3%;
`;

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID' },
  { field: 'patient', headerName: 'Patient ID' },
  {
    field: 'created_at',
    width: 150,
    headerName: 'Created At',
    valueFormatter: (params) => {
      return format(new Date(params.value as string), 'yyyy-MM-dd');
    },
  },
  {
    field: 'status',
    width: 150,
    headerName: 'Status',
    renderCell: (params) => {
      const row = params.row as FDAListItem;
      switch (row.status) {
        case 'Completed':
          return (
            <StatusItem status='completed'>
              <CheckCircleIcon
                style={{
                  color: green[700],
                  fontSize: '100%',
                }}
              />
              <StatusText>Completed</StatusText>
            </StatusItem>
          );
        case 'Processing':
          return (
            <StatusItem status='processing'>
              <WatchLaterIcon
                style={{
                  fontSize: '100%',
                }}
              />
              <StatusText>Processing</StatusText>
            </StatusItem>
          );

        case 'Partial':
          return (
            <StatusItem status='partial'>
              <WarningOutlinedIcon
                style={{
                  color: yellow[700],
                  fontSize: '100%',
                }}
              />
              <StatusText>Partial</StatusText>
            </StatusItem>
          );

        case 'Failed':
          return (
            <StatusItem status='failed'>
              <ErrorOutlinedIcon
                style={{
                  color: red[700],
                  fontSize: '100%',
                }}
              />
              <StatusText>Failed</StatusText>
            </StatusItem>
          );
      }
    },
  },
];

const FunctionalDynamicAssessmentTable = () => {
  const { data, loading, error } = useContext(ListContext);
  const navigate = useNavigate();

  const handleRowClick = (item: FDAListItem) => {
    const { id, patient, status } = item;

    if (!id || !patient) {
      return;
    }

    if (status === 'Failed' || status === 'Processing') {
      const message =
        status === 'Failed'
          ? 'Functional Dynamic Assessment failed'
          : 'Functional Dynamic Assessment not ready to view';
      const variant = status === 'Failed' ? 'error' : 'warning';

      enqueueSnackbar(message, {
        variant,
      });

      return;
    }

    navigate(`/patients/${patient}/functional-dynamic-assessment?fdaId=${id}`);
  };

  if (data === null && loading) {
    return <Loader />;
  }

  if (data === null) {
    return <>No Functional Dynamic Assessment Data</>;
  }

  if (error) {
    return <>Error loading data: {error.message}</>;
  }

  console.log('hello');

  return (
    <div>
      <DataGridPro
        columns={columns}
        rows={data}
        style={tableStyles({ height: '835px' })}
        onRowClick={(params) => handleRowClick(params.row)}
      />
    </div>
  );
};
