import { createContext } from 'react';
import { ScanModel } from '../../network';
import {
  LayerId,
  LayerVisibility,
  MeshType,
} from '../ScanItemViewer/MeshViewer/layers';

export type ScanCompareState = {
  scans: Array<ScanModel>;
  splitMode: boolean;
  selectedScanFirst: ScanModel | null;
  selectedScanSecond: ScanModel | null;
  synced: boolean;
  syncedLayerState: LayerVisibility;
  meshType: MeshType;
  loading: boolean;
  setMeshType: React.Dispatch<React.SetStateAction<MeshType>>;
  toggleSyncedLayer: (layerId: LayerId) => void;
  setSynced: (value: boolean) => void;
  setSplitMode: (value: boolean) => void;
  setSelectedScanFirst: (value: ScanModel) => Promise<void>;
  setSelectedScanSecond: (value: ScanModel) => Promise<void>;
  selectScan: (value: ScanModel) => Promise<void>;
};

export type ScanCompareContextValue = ScanCompareState;

export const ScanCompareContext = createContext<ScanCompareContextValue>(
  {} as ScanCompareContextValue
);
