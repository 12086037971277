import { FC, useState } from 'react';
import { AccountSettings, OrganizationManagment } from '../../components';
import { Tab, TabPanel, Tabs } from '../../../ui';
import { ScreenTtile, TrademarkContainer } from './styles';
import { DocumentTitle } from '../../../../components/DocumentTitle';

export const SettingsScreen: FC = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <div>
      <DocumentTitle title='Settings' />
      <ScreenTtile variant='h1'>Settings</ScreenTtile>
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab label='Account' id='tab-0' aria-controls='tabpanel-0' />
        <Tab label='Organization' id='tab-1' aria-controls='tabpanel-1' />
      </Tabs>
      <TabPanel current={currentTab} id={0} isFirst>
        <AccountSettings />
      </TabPanel>
      <TabPanel current={currentTab} id={1}>
        <OrganizationManagment />
      </TabPanel>
      <TrademarkContainer>
        <span>© 2023. All rights reserved.</span>
      </TrademarkContainer>
    </div>
  );
};
