import { styled } from '@mui/material';

export const Wrapper = styled('div')`
  display: inline-flex;
  flex-direction: row;
  padding: 2px;
  background: #f6f6f6;
  border-radius: 12px;
`;

export const Item = styled('div')<{
  active?: boolean;
}>`
  display: inline-flex;
  flex-direction: row;
  padding: 8px 16px;
  background: ${({ active }) => (active ? '#353535' : 'transparent')};
  color: ${({ active }) => (active ? '#fff' : '#878787')};
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 145%;
  cursor: pointer;
  transition: ${({ theme }) =>
    theme.transitions.create(['background', 'color'])};
`;

export const ItemIcon = styled('div')`
  font-size: 16px;
  line-height: 0;
  margin-right: 8px;
`;
