import { Dialog, IconButton, styled } from '@mui/material';

export const DialogStyled = styled(Dialog)`
  & .MuiDialog-paper {
    width: 512px;
    max-width: 100%;
    border-radius: 24px;
    padding: 40px;
  }
`;

export const CloseModalButton = styled(IconButton)`
  position: absolute;
  top: 6px;
  right: 6px;
`;

export const ModalHeader = styled('div')`
  margin-bottom: 32px;
  text-align: center;
`;

export const ModalTitle = styled('div')`
  margin-bottom: 12px;
`;
