import { Button, Link, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Loader } from '../../../ui';
import {
  ActionItems,
  NotificationItem,
  NotificationItemContainer,
  NotificationItemHeader,
  PaddedContent,
  Root,
  StatusView,
  UnreadDot,
  NotificationItemTitleContainer,
  LoadMoreContainer,
} from './styles';
import {
  Archive,
  MarkEmailRead,
  MarkEmailUnread,
  Unarchive,
} from '@mui/icons-material';
import { useNotificationsList } from '../../hooks/useNotificationsList';
import { ListItemIconButton } from '../ListItemIconButton';

export interface NotificationMenuProps {
  anchorEl: HTMLElement | null;
  onClose?: () => void;
}

export const NotificationMenu: FC<NotificationMenuProps> = (props) => {
  const { anchorEl, onClose } = props;

  const [showingArchived, setShowingArchived] = useState(false);

  const {
    notifications,
    loading,
    error,
    canLoadMore,
    loadMore,
    toggleArchive,
    toggleRead,
  } = useNotificationsList({
    archived: showingArchived,
  });

  const handleClose = () => {
    onClose && onClose();
    setShowingArchived(false);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      loadMore();
    }
  };

  return (
    <Root
      id='notification-menu'
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{ onScroll: handleScroll }}
    >
      <PaddedContent>
        <NotificationItemHeader>
          <NotificationItemTitleContainer>
            <Typography variant='h3'>
              {showingArchived ? 'Archived Notifications' : 'Notifications'}
            </Typography>
            {loading && <Loader size={20} />}
          </NotificationItemTitleContainer>

          {showingArchived ? (
            <Link underline='always' onClick={() => setShowingArchived(false)}>
              View Unarchived
            </Link>
          ) : (
            <Link underline='always' onClick={() => setShowingArchived(true)}>
              View Archived
            </Link>
          )}
        </NotificationItemHeader>

        {error && (
          <StatusView>
            <Typography>{error.message || 'Unknown error'}</Typography>
          </StatusView>
        )}
        {!loading &&
          !error &&
          (!notifications || notifications.length === 0) && (
            <StatusView>
              <Typography>No notifications</Typography>
            </StatusView>
          )}
      </PaddedContent>
      {notifications && (
        <>
          {notifications.map((item) => (
            <NotificationItem key={item.id}>
              <div>{item.is_read === false && <UnreadDot />}</div>
              <NotificationItemContainer>
                <div>
                  <div>
                    <Typography variant='subtitle2'>
                      {item.title}{' '}
                      <Typography variant='bodySmall'>
                        {new Date(item.created_at).toLocaleString()}
                      </Typography>
                    </Typography>
                  </div>
                  <Typography variant='bodySmall'>{item.message}</Typography>
                </div>

                <ActionItems>
                  {item.is_read ? (
                    <ListItemIconButton
                      tooltip='Mark as unread'
                      icon={<MarkEmailUnread />}
                      onClick={() => toggleRead(item.id, false)}
                    />
                  ) : (
                    <ListItemIconButton
                      tooltip='Mark as read'
                      icon={<MarkEmailRead />}
                      onClick={() => toggleRead(item.id, true)}
                    />
                  )}
                  {item.archived ? (
                    <ListItemIconButton
                      tooltip='Unarchive'
                      icon={<Unarchive />}
                      onClick={() => toggleArchive(item.id, false)}
                    />
                  ) : (
                    <ListItemIconButton
                      tooltip='Archive'
                      icon={<Archive />}
                      onClick={() => toggleArchive(item.id, true)}
                    />
                  )}
                </ActionItems>
              </NotificationItemContainer>
            </NotificationItem>
          ))}
        </>
      )}

      {canLoadMore && (
        <LoadMoreContainer>
          {loading ? (
            <Loader size={20} />
          ) : (
            <Button variant='outlined' onClick={loadMore}>
              Load More
            </Button>
          )}
        </LoadMoreContainer>
      )}
    </Root>
  );
};
