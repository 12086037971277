import { styled } from '@mui/material';

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -12px;
`;

export const DataGridContainer = styled('div')`
  margin-left: -32px;
  margin-right: -32px;
`;
