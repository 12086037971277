import { request } from '../../../network';

export enum Unit {
  CM = 'cm',
  PERCENT = 'percent',
  KMH = 'kmh',
  RPM = 'rpm',
  NONE = 'none',
}

export interface Range {
  min: number | null;
  max: number | null;
  avg: number | null;
  unit: Unit;
}

export interface HealthDataModel {
  date: string;
  steps: number | null;
  floors_climbed: number | null;
  step_cadence: Range;
  double_support_time: Range;
  walking_asymmetry: Range;
  walking_step_length: Range;
  walking_speed: Range;
}

interface HealthDataRequestParams {
  start_date?: string;
  end_date?: string;
}

export const getPatientHealthData = async (
  patientId: number,
  startDate?: string,
  endDate?: string
): Promise<HealthDataModel[]> => {
  const params: HealthDataRequestParams = {};

  if (startDate) {
    params['start_date'] = startDate;
  }

  if (endDate) {
    params['end_date'] = endDate;
  }

  const response = await request({
    path: `/api/health_data/${patientId}/`,
    method: 'GET',
    params: params as { [key: string]: string },
  });

  if (response.ok) {
    return response.data;
  }

  // need to sort the response by latest date first

  throw new Error(response.errorMessage);
};
