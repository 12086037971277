import { useCallback, useMemo } from 'react';
import { Button, Typography } from '@mui/material';
import { ValidationErrors } from 'final-form';
import { Form } from 'react-final-form';
import { FormStackVertical, TextInputFF } from '../ui';
import { AuthCard, AuthCardHeader, AuthCardTitle } from '../auth/components/ui';
import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useAccountContext } from '../account';
import { useContactUs } from './hooks';
import { ContactUsData } from './network';

export const ContactUs = () => {
  const { user } = useAccountContext();
  const [result, loading, , request] = useContactUs();

  const onSubmit = useCallback(
    (values: any) => {
      request(values);
    },
    [request]
  );

  const validate = useCallback((values: Partial<ContactUsData>) => {
    const result: ValidationErrors = {};

    if (!values.sender_name) {
      result.sender_name = true;
    }
    if (!values.sender_email) {
      result.sender_email = true;
    }
    if (!values.sender_message) {
      result.sender_message = true;
    }

    return result;
  }, []);

  useEffect(() => {
    if (result) {
      enqueueSnackbar('Message sent', { variant: 'success' });
    }
  }, [result]);

  const initialData = useMemo(() => {
    if (user) {
      return {
        sender_name: `${user.first_name} ${user.last_name}`,
        sender_email: user.email,
      };
    }
    return {};
  }, [user]);

  return (
    <AuthCard>
      <AuthCardHeader>
        <AuthCardTitle variant='h1'>Contact Us</AuthCardTitle>
        <Typography variant='bodyMedium'>
          If you have any problems or if you wish to report a bug, please send
          us a message.
        </Typography>
      </AuthCardHeader>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialData}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormStackVertical alignItems='center'>
              {!initialData.sender_name && (
                <TextInputFF name='sender_name' label='Name' fullWidth />
              )}
              {!initialData.sender_email && (
                <TextInputFF name='sender_email' label='Email' fullWidth />
              )}
              <TextInputFF
                name='sender_message'
                label={
                  initialData.sender_name && initialData.sender_email
                    ? undefined
                    : 'Message'
                }
                placeholder='Your message...'
                minRows={5}
                multiline
                fullWidth
              />
              <Button type='submit' disabled={loading}>
                Send
              </Button>
            </FormStackVertical>
          </form>
        )}
      />
    </AuthCard>
  );
};
