import { FC } from 'react';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { Recovery } from '../../components/Recovery';

export const RecoveryScreen: FC = () => {
  return (
    <>
      <DocumentTitle title='Password Recovery' />
      <Recovery />
    </>
  );
};
