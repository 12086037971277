import get from 'lodash/get';
import { ReactNode, useMemo } from 'react';
import { DataGridFieldBaseProps, DataGridFieldInjectedProps } from './types';

export type UseDataGridFieldValueOptions = DataGridFieldBaseProps &
  DataGridFieldInjectedProps;

export type DataGridFieldValueFormatOptions = Omit<UseDataGridFieldValueOptions, 'source'> & {
  value?: any;
}

export type DataGridFieldValueFormat = (data: DataGridFieldValueFormatOptions) => ReactNode | undefined;

export const useDataGridFieldValue = (
  options: UseDataGridFieldValueOptions,
  format?: DataGridFieldValueFormat,
) => {
  const { source, record, emptyText = '—' } = options;

  const value = useMemo(() => {
    let result: any;
    if (record && source) {
      result = get(record, source);
    }
    if (format) {
      if (source && result) {
        result = format({
          value: result,
          emptyText,
          record,
        });
      }
      if (!source) {
        result = format({
          emptyText,
          record,
        });
      }
    }

    if (result !== null && result !== undefined && result !== '') {
      return result;
    }
    return emptyText;
  }, [source, record, emptyText, format]);

  return value;
};
