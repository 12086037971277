import { useEffect, useState } from 'react';
import { getQrCode } from '../network';

export const useGetQRCode = () => {
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const getSignUpQRCode = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getQrCode();
      setQrCode(response);
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setError(e);
      } else {
        const error = new Error('Unknown error');
        setError(error);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getSignUpQRCode();
  }, []);

  return {
    qrCode,
    isLoading,
    error,
  };
};
