import { styled } from '@mui/material';

export const LoadingContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const EmptyContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;
