import { IconButton, IconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export const SideBarWrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px 20px 40px;
  width: 104px;
  height: 100%;
  border-radius: 0px 24px 24px 0px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
`;

export const SideBarItem = styled(IconButton)<IconButtonProps>`
  padding: 16px;
  font-size: 32px;
  border-radius: 16px;
  &.active {
    background: #f1eeff;
  }
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
` as typeof IconButton;

export const LogoItem = styled(NavLink)`
  margin-bottom: 40px;
  line-height: 0;
`;

export const MomentumSpineLogo = styled('img')`
  width: 40px;
  height: 80px;
`;

export const QuestionItem = styled(IconButton)<IconButtonProps>`
  margin-top: auto;
  padding: 20px;
  font-size: 25px;
  border-radius: 16px;
  &.active {
    background: #f1eeff;
  }
` as typeof IconButton;
