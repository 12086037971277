import { FC } from 'react';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { Signin } from '../../components';

export const SigninScreen: FC = () => {
  return (
    <>
      <DocumentTitle title='Sign-in' />
      <Signin />
    </>
  );
};
