import { useMemo, useState } from 'react';
import { useAnimations, useGLTF } from '@react-three/drei';
import { AnimationAction } from 'three';
import { GLTF } from 'three-stdlib';

export type UseAnimationsReturnType = ReturnType<typeof useAnimations>;

export type GLTFResult = GLTF & {
  nodes: {
    mesh_0: THREE.Mesh;
  };
  materials: {};
};

interface UseAnimatedMeshViewerProps {
  url: string;
  authToken: string;
}

export const useAnimatedMeshViewer = ({
  url,
  authToken,
}: UseAnimatedMeshViewerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [wasPlayingBeforeSeek, setWasPlayingBeforeSeek] = useState(false);
  const [animationContext, setAnimationContext] =
    useState<UseAnimationsReturnType | null>(null);
  const [currentFrame, setCurrentFrame] = useState<number>(0);

  const { nodes, animations } = useGLTF(url, true, undefined, (loader) => {
    loader.setRequestHeader({ Authorization: 'Bearer ' + authToken });
  }) as GLTFResult;

  const mainAnimationAction = useMemo(() => {
    // find the first action that is not null
    let result: AnimationAction | null = null;

    if (animationContext) {
      const { actions, names } = animationContext;
      if (actions) {
        for (let i = 0; i < names.length; i++) {
          const name = names[i];
          const action = actions[name];
          if (action) {
            result = action;
            break;
          }
        }
      }
    }

    return result;
  }, [animationContext]);

  const duration = useMemo(() => {
    if (mainAnimationAction) {
      return mainAnimationAction.getClip().duration;
    }
    return 0;
  }, [mainAnimationAction]);

  const totalFrames = useMemo(() => {
    return duration * 60;
  }, [duration]);

  const pause = () => {
    if (mainAnimationAction) {
      mainAnimationAction.paused = true;
      setIsPlaying(false);
    }
  };

  const play = () => {
    if (mainAnimationAction) {
      mainAnimationAction.play();
      mainAnimationAction.paused = false;
      setIsPlaying(true);
    }
  };

  const playPause = () => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  };

  const stop = () => {
    if (mainAnimationAction) {
      mainAnimationAction.stop();
      setIsPlaying(false);
    }
  };

  const changeTimeScale = (timeScale: number) => {
    if (mainAnimationAction) {
      mainAnimationAction.timeScale = timeScale;
    }
  };

  const seekStart = () => {
    // if we're already playing, pause
    // if we're not playing, do nothing
    if (isPlaying) {
      setWasPlayingBeforeSeek(true);
      pause();
    }
  };

  const seekEnd = () => {
    // if we WERE playing before we started seeking, resume playing
    // if we were not playing before we started seeking, do nothing
    if (wasPlayingBeforeSeek) {
      play();
      setWasPlayingBeforeSeek(false);
    }
  };

  const seek = (value: number) => {
    if (mainAnimationAction) {
      const percent = value / totalFrames;
      const newTime = duration * percent;
      mainAnimationAction.time = newTime;
    }
  };

  return {
    isPlaying,
    playPause,
    animationContext,
    setAnimationContext,
    currentFrame,
    setCurrentFrame,
    changeTimeScale,
    totalFrames,
    play,
    pause,
    duration,
    seek,
    seekStart,
    seekEnd,
    animations,
    nodes,
    stop,
  };
};
