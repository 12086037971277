import { useCallback, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ValidationErrors } from 'final-form';
import { Form } from 'react-final-form';
import { enqueueSnackbar } from 'notistack';
import { FormStackVertical, TextInputFF } from '../../../ui';
import { useForgotPassword } from '../../hooks';
import { AuthCard, AuthCardHeader, AuthCardTitle } from '../ui';
import { ButtonContainer } from './styles';

export const Recovery = () => {
  const [result, isLoading, , request] = useForgotPassword();
  const navigate = useNavigate();

  useEffect(() => {
    if (result) {
      enqueueSnackbar(
        'Recovery instructions have been sent to the specified email',
        { variant: 'success' }
      );
      navigate('/auth');
    }
  }, [result, navigate]);

  const onSubmit = useCallback(
    (values: any) => {
      request(values);
    },
    [request]
  );

  const handleCancel = useCallback(() => {
    navigate('/auth');
  }, [navigate]);

  const validate = useCallback((values: any) => {
    const result: ValidationErrors = {};
    if (
      !values?.email
        ?.toLowerCase()
        ?.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/)
    ) {
      result.email = 'invalid email';
    }
    return result;
  }, []);

  return (
    <AuthCard>
      <AuthCardHeader>
        <AuthCardTitle variant='h2'>Password recovery</AuthCardTitle>
        <Typography variant='bodyMedium'>
          Enter your email address and we will send you password recovery
          instructions!
        </Typography>
      </AuthCardHeader>
      <Form
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormStackVertical>
              <TextInputFF
                label='Email*'
                name='email'
                placeholder='John@momentum.health'
              />

              <ButtonContainer>
                <Button variant='text' onClick={handleCancel}>
                  Cancel
                </Button>{' '}
                <Button
                  type='submit'
                  disabled={isLoading}
                  sx={{ width: '132px' }}
                >
                  Send
                </Button>
              </ButtonContainer>
            </FormStackVertical>
          </form>
        )}
      />
    </AuthCard>
  );
};
