import { FC, useState } from 'react';
import { usePatientHealthData } from '../../hooks';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Card } from '../../../ui';
import {
  NoRowsOverlayContainer,
  tableStyles,
} from '../../../ui/components/DataGridPro/styles';
import { Button, Typography, styled } from '@mui/material';

interface HealthDataTableProps {
  patient: number;
}

const columns: GridColDef<any>[] = [
  {
    field: 'date',
    headerName: 'Date (YYYY-MM-DD)',
    width: 200,
  },
  {
    field: 'steps_avg',
    headerName: 'Steps',
    width: 150,
  },
  {
    field: 'floors_climbed_avg',
    headerName: 'Floors Climbed',
    width: 150,
  },
  {
    field: 'step_cadence_avg',
    headerName: 'Step Cadence',
    width: 150,
  },
  {
    field: 'double_support_time_avg',
    headerName: 'Double Support Time',
    width: 150,
  },
  {
    field: 'walking_asymmetry_avg',
    headerName: 'Walking Asymmetry',
    width: 150,
  },
  {
    field: 'walking_step_length_avg',
    headerName: 'Walking Step Length',
    width: 150,
  },
  {
    field: 'walking_speed_avg',
    headerName: 'Walking Speed',
    width: 150,
  },
];

const dateHeaders = {
  day: 'Date (YYYY-MM-DD)',
  week: 'Week (YYYY-WW)',
  month: 'Month (YYYY-MM)',
  year: 'Year',
};

const noRowsOverlayDisplay = () => (
  <NoRowsOverlayContainer>
    <Typography variant='h6' color='textSecondary'>
      No health data found...
    </Typography>
  </NoRowsOverlayContainer>
);

export const HealthDataTable: FC<HealthDataTableProps> = ({ patient }) => {
  const { loading, loadMore, healthData, groupingMethod, setGroupingMethod } =
    usePatientHealthData(patient);

  const [columnDefs, setColumnDefs] = useState(columns);

  const handleGroupingMethodChange = (
    group: 'day' | 'week' | 'month' | 'year'
  ) => {
    setGroupingMethod(group);

    const lColumns = columns.map((col) => {
      if (col.field === 'date') {
        return {
          ...col,
          headerName: dateHeaders[group],
        };
      }
      return col;
    });

    setColumnDefs(lColumns);
  };

  return (
    <Card
      title='Health Data'
      rightActions={
        <GroupingContainer>
          group by:
          <Button
            variant={groupingMethod === 'day' ? 'containedLight' : 'text'}
            onClick={() => handleGroupingMethodChange('day')}
          >
            Day
          </Button>
          <Button
            variant={groupingMethod === 'week' ? 'containedLight' : 'text'}
            onClick={() => handleGroupingMethodChange('week')}
          >
            Week
          </Button>
          <Button
            variant={groupingMethod === 'month' ? 'containedLight' : 'text'}
            onClick={() => handleGroupingMethodChange('month')}
          >
            Month
          </Button>
          <Button
            variant={groupingMethod === 'year' ? 'containedLight' : 'text'}
            onClick={() => handleGroupingMethodChange('year')}
          >
            Year
          </Button>
        </GroupingContainer>
      }
    >
      <div style={{ height: healthData.length > 0 ? 'unset' : 200 }}>
        <DataGridPro
          slots={{
            noRowsOverlay: noRowsOverlayDisplay,
          }}
          style={tableStyles({ height: '835px' })}
          loading={loading}
          columns={columnDefs}
          rows={healthData}
          onRowsScrollEnd={loadMore}
        />
      </div>
    </Card>
  );
};

const GroupingContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;
