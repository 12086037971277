import { FC } from 'react';
import {
  DataGridFieldBaseProps,
  DataGridFieldInjectedProps,
  useDataGridFieldValue,
} from '../../../ui';
import { ProgressionContainer, ProgressionValue } from './styles';
import { ArrowUp } from './../../../icon/ArrowUp';
import { ArrowDown } from '../../../icon/ArrowDown';

export type DataGridProgressionFieldProps = DataGridFieldBaseProps &
  DataGridFieldInjectedProps;

export const DataGridProgressionField: FC<DataGridProgressionFieldProps> = (
  props
) => {
  const value = useDataGridFieldValue({
    ...props,
    emptyText: null,
  });

  let direction = 'none';
  if (value !== null) {
    direction = value < 0 ? 'down' : 'up';
  }
  return (
    <ProgressionContainer>
      {direction === 'up' && <ArrowUp fontSize='inherit' />}
      {direction === 'down' && <ArrowDown fontSize='inherit' />}
      <ProgressionValue direction={direction} variant='bodyLarge'>
        {value !== null ? `${value < 0 ? value * -1 : value}%` : '—'}
      </ProgressionValue>
    </ProgressionContainer>
  );
};
