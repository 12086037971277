import { request, requestList } from '../../../network';
import { ListData, ListDataRequestParams } from '../../ui';
import { FDAItemApiResponse, FDAListItem } from '../types';

export const getFunctionalDynamicAssessmentList = async (
  data?: ListDataRequestParams
): Promise<ListData<FDAListItem>> => {
  const response = (await requestList(
    '/api/functional_analysis_data/',
    data
  )) as ListData<FDAListItem>;

  return response;
};

export const getFunctionalDynamicAssessmentItem = async (
  id: number
): Promise<FDAItemApiResponse> => {
  const response = await request({
    path: `/api/functional_analysis_data/${id}`,
    method: 'GET',
    silent: true,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};
