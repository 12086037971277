import { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { InvitePatientModal } from '../InvitePatientModal';
import { PlusIcon } from '../../../icon/PlusIcon';

export const InvitePatientHeaderButton = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button onClick={handleOpen} startIcon={<PlusIcon />}>
        Invite new patient
      </Button>
      <InvitePatientModal open={open} onClose={handleClose} />
    </>
  );
};
