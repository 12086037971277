import { createContext } from 'react';
import { PatientModel } from '../../network';

export type PatientState = {
  patient: PatientModel;
  reload: () => void;
};

export type PatientContextValue = PatientState

export const PatientContext = createContext<PatientContextValue>(
  {} as PatientContextValue
);
