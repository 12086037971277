import { format, getISOWeek } from 'date-fns';
import { HealthDataModel, Unit } from '../network/healthData';

export const toPercent = (value: number | null) => {
  if (value === null) {
    return 0;
  }

  return value * 100;
};

export const normalizeValue = (
  value: number | null,
  isPercent: boolean = false
) => {
  if (isPercent) {
    return toPercent(value);
  }

  if (value === null) {
    return 0;
  }

  return value;
};

export interface GroupedByKey {
  [key: string]: HealthDataModel[];
}

export const getUnitString = (unit: Unit) => {
  switch (unit) {
    case Unit.CM:
      return 'cm';
    case Unit.KMH:
      return 'km/h';
    case Unit.PERCENT:
      return '%';
    case Unit.RPM:
      return 'rpm';
    case Unit.NONE:
    default:
      return '';
  }
};

export const averageOfDataByDataKey = (
  data: HealthDataModel[],
  keyName: keyof HealthDataModel
) => {
  const totalOfData = data.reduce((acc, curr) => {
    if (keyName === 'date') {
      throw new Error('date is not a valid key to calculate average');
    }
    if (keyName === 'steps' || keyName === 'floors_climbed') {
      return acc + (curr[keyName] || 0);
    } else {
      const isPercent = curr[keyName].unit === Unit.PERCENT;
      return acc + normalizeValue(curr[keyName].avg, isPercent);
    }
  }, 0);

  const avg = data.length > 0 ? Math.round(totalOfData / data.length) : 0;

  if (avg === 0) {
    return 'NA';
  }

  if (
    keyName === 'steps' ||
    keyName === 'floors_climbed' ||
    keyName === 'date'
  ) {
    return avg;
  } else {
    const firstItem = data[0];
    const unit = firstItem[keyName].unit;

    return `${avg} ${getUnitString(unit)}`;
  }
};

export const averagesOfDataGroupedByKey = (data: GroupedByKey) => {
  return Object.keys(data).map((key) => {
    const avgSteps = averageOfDataByDataKey(data[key], 'steps');
    const avgFloorsClimbed = averageOfDataByDataKey(
      data[key],
      'floors_climbed'
    );
    const avgStepCadence = averageOfDataByDataKey(data[key], 'step_cadence');
    const avgDoubleSupportTime = averageOfDataByDataKey(
      data[key],
      'double_support_time'
    );
    const avgWalkingAsymmetry = averageOfDataByDataKey(
      data[key],
      'walking_asymmetry'
    );
    const avgWalkingStepLength = averageOfDataByDataKey(
      data[key],
      'walking_step_length'
    );
    const avgWalkingSpeed = averageOfDataByDataKey(data[key], 'walking_speed');

    return {
      id: key,
      date: key,
      steps_avg: avgSteps,
      floors_climbed_avg: avgFloorsClimbed,
      step_cadence_avg: avgStepCadence,
      double_support_time_avg: avgDoubleSupportTime,
      walking_asymmetry_avg: avgWalkingAsymmetry,
      walking_step_length_avg: avgWalkingStepLength,
      walking_speed_avg: avgWalkingSpeed,
    };
  });
};

export const groupedByDay = (data: HealthDataModel[]): GroupedByKey => {
  const result: { [key: string]: HealthDataModel[] } = {};
  data.forEach((item) => {
    const date = new Date(item.date);
    const key = format(date, 'yyyy-MM-dd');
    if (result[key] === undefined) {
      result[key] = [];
    }
    result[key].push(item);
  });
  return result;
};

export const groupedByWeek = (data: HealthDataModel[]): GroupedByKey => {
  const result: { [key: string]: HealthDataModel[] } = {};
  data.forEach((item) => {
    const date = new Date(item.date);
    const weekNumber = getISOWeek(date);
    const weekString = weekNumber >= 10 ? weekNumber : `0${weekNumber}`;
    const key = `${date.getFullYear()}-${weekString}`;
    if (result[key] === undefined) {
      result[key] = [];
    }
    result[key].push(item);
  });
  return result;
};

export const groupedByMonth = (data: HealthDataModel[]): GroupedByKey => {
  const result: { [key: string]: HealthDataModel[] } = {};
  data.forEach((item) => {
    const date = new Date(item.date);
    const key = `${format(date, 'yyyy-MM')}`;
    if (result[key] === undefined) {
      result[key] = [];
    }
    result[key].push(item);
  });
  return result;
};

export const groupedByYear = (data: HealthDataModel[]): GroupedByKey => {
  const result: { [key: string]: HealthDataModel[] } = {};
  data.forEach((item) => {
    const date = new Date(item.date);
    const key = `${date.getFullYear()} `;
    if (result[key] === undefined) {
      result[key] = [];
    }
    result[key].push(item);
  });
  return result;
};
