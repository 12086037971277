import { FC } from 'react';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { SignUpSuccess } from '../../components';

export const SignUpSuccessScreen: FC = () => {
  return (
    <>
      <DocumentTitle title='Sign-up' />
      <SignUpSuccess />
    </>
  );
};
