import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { NotificationMenu } from '../NotificationMenu';
import { HeaderButton } from '../../../../components/AuthorizedLayout/styles';
import { BellIcon } from '../../../icon/BellIcon';
import { useAccountContext } from '../../../account';

export interface NotificationButtonRenderProps {
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
  hasNew: boolean;
}

export const NotificationButton: FC = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { notificationModalOpen, setNotificationModalOpen } =
    useAccountContext();

  const handleOpen = useCallback(() => {
    setAnchorEl(buttonRef.current);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    // see NotificationsRedirect component for more info
    const shouldOpenModal = notificationModalOpen;
    if (shouldOpenModal === true) {
      handleOpen();
    }

    setNotificationModalOpen(false);
  }, [notificationModalOpen, handleOpen, setNotificationModalOpen]);

  return (
    <>
      <HeaderButton
        ref={buttonRef}
        onClick={handleOpen}
        color='primary'
        size='large'
      >
        <BellIcon fontSize='small' />
      </HeaderButton>
      <NotificationMenu anchorEl={anchorEl} onClose={handleClose} />
    </>
  );
};
