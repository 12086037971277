import { FC } from 'react';
import { WidgetCountWrapper } from './styles';

export const WidgetCount: FC<{ count: number | null }> = ({ count }) => {
  return (
    <WidgetCountWrapper color={count && +count > 0 ? '#353535' : '#E6E6E6'}>
      {count}
    </WidgetCountWrapper>
  );
};
