import { css, styled } from '@mui/material';

export const StatusContainer = styled('span')`
  display: inline-flex;
  flex-direction: row;

  & > span + span {
    margin-left: 10px;
  }
`;

export const StatusItem = styled('span')<{ status: string; }>`
  display: inline-block;
  padding: 0 10px;
  border-radius: 20px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 145%;

  display: flex;
  align-items: center;

  > img {
    width: 13px;
    height: 14px;
    margin: 0 3px;
  }

  ${({ status }) => {
    if (status === 'new') {
      return css`
        color: #7E66E1;
        background: #F1EEFF;
      `;
    }
    if (status === 'unread') {
      return css`
        color: #59C3C3;
        background: #DDFFF7;
      `;
    }
    if (status === 'ready') {
      return css`
        color: #59C3C3;
        background: #DDFFF7;
      `;
    }
    return css`
      color: #909090;
      background: #DDDDDD;
    `;
  }}
`;
