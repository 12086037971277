import { FC, useContext } from 'react';
import { FunctionalDynamicAssessmentContext } from '../context';
import { FDAMeshViewer } from './FDAMeshViewer';
import {
  BalanceGaitSwitcher,
  FDAMeshViewerControls,
} from './FDAMeshViewerControls';
import { styled } from '@mui/material';

export const BalanceMeshViewerContainer: FC = () => {
  const { balanceControls } = useContext(FunctionalDynamicAssessmentContext);
  return (
    <div>
      <FDAMeshViewerContainer>
        <FDAMeshViewer
          setAnimationContext={balanceControls.setAnimationContext}
          setCurrentFrame={balanceControls.setCurrentFrame}
          animations={balanceControls.animations}
          nodes={balanceControls.nodes}
        />
        <BalanceGaitSwitcher />
        <FDAMeshViewerOverlayControls>
          <FDAMeshViewerControls
            playPause={balanceControls.playPause}
            isPlaying={balanceControls.isPlaying}
            currentFrame={balanceControls.currentFrame}
            changeTimeScale={balanceControls.changeTimeScale}
            totalFrames={balanceControls.totalFrames}
            seek={balanceControls.seek}
            seekStart={balanceControls.seekStart}
            seekEnd={balanceControls.seekEnd}
          />
        </FDAMeshViewerOverlayControls>
      </FDAMeshViewerContainer>
    </div>
  );
};
export const GaitMeshViewerContainer: FC = () => {
  const { gaitControls } = useContext(FunctionalDynamicAssessmentContext);
  return (
    <div>
      <FDAMeshViewerContainer>
        <FDAMeshViewer
          setAnimationContext={gaitControls.setAnimationContext}
          setCurrentFrame={gaitControls.setCurrentFrame}
          animations={gaitControls.animations}
          nodes={gaitControls.nodes}
        />
        <BalanceGaitSwitcher />
        <FDAMeshViewerOverlayControls>
          <FDAMeshViewerControls
            playPause={gaitControls.playPause}
            isPlaying={gaitControls.isPlaying}
            currentFrame={gaitControls.currentFrame}
            changeTimeScale={gaitControls.changeTimeScale}
            totalFrames={gaitControls.totalFrames}
            seek={gaitControls.seek}
            seekStart={gaitControls.seekStart}
            seekEnd={gaitControls.seekEnd}
          />
        </FDAMeshViewerOverlayControls>
      </FDAMeshViewerContainer>
    </div>
  );
};

const FDAMeshViewerContainer = styled('div')`
  position: relative;
  background-color: lightgray;
  border-radius: 16px;
`;

const FDAMeshViewerOverlayControls = styled('div')`
  position: absolute;
  bottom: 12px;
  width: 100%;
`;
