import { FC, ReactNode, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputBaseProps,
  InputLabel,
  InputBase,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export type TextInputProps = InputBaseProps & {
  label?: string | ReactNode;
  helperText?: string | ReactNode;
};

export const TextInput: FC<TextInputProps> = (props) => {
  const { label, error, helperText, fullWidth, type, ...rest } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const renderPasswordToggle = type === 'password' && (
    <InputAdornment position='end'>
      <IconButton
        aria-label='toggle password visibility'
        onClick={handleTogglePasswordVisibility}
        edge='end'
        tabIndex={-1}
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <FormControl variant='standard' error={error} fullWidth={fullWidth}>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <InputBase
        error={error}
        fullWidth={fullWidth}
        type={showPassword ? 'text' : type}
        endAdornment={renderPasswordToggle}
        {...rest}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
