import { styled } from '@mui/material';

export const BadgeWrapper = styled('span')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  padding: 0px 10px;
  background: #59c3c3;
  border-radius: 20px;
  color: #ffffff;
`;
