import { FC } from 'react';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { ContactUs } from '../../../../modules/contactUs';

export const ContactUsScreen: FC = () => {
  return (
    <>
      <DocumentTitle title='Sign-in' />
      <ContactUs />
    </>
  );
};
