import { styled } from '@mui/material';

export const FormStackVertical = styled('div')<{
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch'
}>`
  display: flex;
  flex-direction: column;

  ${({ alignItems }) => alignItems && {
    alignItems
  }}

  & > :not(style) + :not(style) {
    margin-top: 20px;
  }
`;

export const FormStackHorizontal = styled('div')<{
  fullWidth?: boolean;
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch'
}>`
  display: flex;
  flex-direction: row;

  ${({ alignItems }) => alignItems && {
    alignItems
  }}

  ${({ fullWidth }) => fullWidth && {
    width: '100%',
  }}

  & > :not(style) + :not(style) {
    margin-left: 16px;
  }
`;
