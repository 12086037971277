import { FC, ReactNode } from 'react';
import { IconButton, Tooltip } from '@mui/material';

interface ListItemIconButtonProps {
  tooltip: string;
  icon: ReactNode;
  onClick: () => void;
}

export const ListItemIconButton: FC<ListItemIconButtonProps> = ({
  tooltip,
  icon,
  onClick,
}) => {
  return (
    <Tooltip title={tooltip} aria-label={tooltip}>
      <IconButton onClick={onClick}>{icon}</IconButton>
    </Tooltip>
  );
};
