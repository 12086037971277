const getApiBaseUrl = () => {
  const isStaging =
    window.location.href.includes('staging.momentum.health') ||
    process.env.REACT_APP_STAGING === 'true';
  const isDevEnvironment = process.env.REACT_APP_LOCAL === 'true';
  if (isStaging) {
    return 'https://staging-api.momentum.health';
  } else if (isDevEnvironment && !isStaging) {
    return 'http://localhost:8000';
  }
  return 'https://production-api.momentum.health';
};

export const config = {
  apiBaseUrl: getApiBaseUrl(),
  apiDateFormat: 'yyyy-MM-dd'
};
