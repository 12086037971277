import { ListItemButton } from '@mui/material';
import { format } from 'date-fns';
import { FC, memo, useCallback, useMemo } from 'react';
import { ScanModel } from '../../../network';
import {
  ScanItemProperty,
  ScanItemPropertyValue,
  ScanItemTitle,
  ScanItemWrapper,
} from './styles';

export interface ScanItemProps {
  item: ScanModel;
  selectedFirst?: ScanModel | null;
  selectedSecond?: ScanModel | null;
  onSelect?: (item: ScanModel) => void;
}

export const ScanItem: FC<ScanItemProps> = memo((props) => {
  const { item, selectedFirst = null, selectedSecond = null, onSelect } = props;

  const handleSelect = useCallback(() => {
    if (onSelect) {
      onSelect(item);
    }
  }, [item, onSelect]);

  const dateString = useMemo(() => {
    if (item.created_at) {
      const date = new Date(item.created_at);
      return format(date, 'MMM dd, yyyy');
    }
    return 'Unknown date';
  }, [item.created_at]);

  return (
    <ScanItemWrapper
      selected={selectedFirst?.id === item.id || selectedSecond?.id === item.id}
      onClick={handleSelect}
    >
      <ListItemButton>
        <ScanItemTitle variant='subtitle2'>
          {item.id} — {dateString}
        </ScanItemTitle>
        <ScanItemProperty variant='bodySmall'>
          Standing scan:
          <ScanItemPropertyValue valid={item.is_standing_scan_valid}>
            {item.is_standing_scan_valid ? 'Valid' : 'Invalid'}
          </ScanItemPropertyValue>
        </ScanItemProperty>
        <ScanItemProperty variant='bodySmall'>
          Bent scan:
          <ScanItemPropertyValue valid={item.is_bent_scan_valid}>
            {item.is_bent_scan_valid ? 'Valid' : 'Invalid'}
          </ScanItemPropertyValue>
        </ScanItemProperty>
      </ListItemButton>
    </ScanItemWrapper>
  );
});
