import { Alert, IconButton } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import {
  Card,
  ListDataRequestParams,
  Loader,
  useListDataRequest,
} from '../../../ui';
import { usePatientContext } from '../../containers/PatientContainer/usePatientContext';
import { PatientNoteModel, getNoteList } from '../../network';
import AddIcon from '@mui/icons-material/Add';

import { NoteList } from './components/NoteList';
import { AddNoteForm } from './components';

export const PatientInfoNotes: FC = () => {
  const { patient } = usePatientContext();

  const [isCreatingNewNote, setIsCreatingNewNote] = useState(false);

  const listParams: ListDataRequestParams = useMemo(() => {
    return {
      filters: {
        patient: patient.id,
      },
      pagination: {
        size: 100,
        page: 1,
      },
    };
  }, [patient.id]);

  const { data, loading, error, reload } = useListDataRequest<PatientNoteModel>(
    getNoteList,
    listParams
  );
  const showCreateNoteForm = () => {
    setIsCreatingNewNote(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Card
      title='Notes'
      rightActions={
        isCreatingNewNote ? undefined : (
          <IconButton onClick={showCreateNoteForm}>
            <AddIcon />
          </IconButton>
        )
      }
    >
      <div>
        {error && <Alert color='error'>{error.message}</Alert>}
        {isCreatingNewNote && (
          <AddNoteForm
            patientId={patient.id}
            reload={reload}
            setIsEditing={setIsCreatingNewNote}
          />
        )}
        <NoteList data={data} reload={reload} />
      </div>
    </Card>
  );
};
