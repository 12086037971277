type DeviceType = 'iOS' | 'android' | 'macOS' | 'other';

export const useDeviceType = (): DeviceType => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (/iphone|ipad|ipod/.test(userAgent)) {
    return 'iOS';
  } else if (/android/.test(userAgent)) {
    return 'android';
  } else if (/macintosh/.test(userAgent)) {
    return 'macOS';
  } else {
    return 'other';
  }
};
