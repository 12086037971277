import { FC, useCallback, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { ListContainer, useListContext } from '../../../ui';
import { getEmployeeList } from '../../network';
import { EmployeeDataGrid } from '../EmployeeDataGrid';
import { AddEmployeeModal } from '../AddEmployeeModal';
import { PlusIcon } from '../../../icon/PlusIcon';
import { DataGridContainer, Header } from './styles';

const AddEmployeeButton = () => {
  const [open, setOpen] = useState(false);
  const { reload } = useListContext();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(
    (reason?: any) => {
      setOpen(false);
      if (reason === 'success') {
        reload();
      }
    },
    [reload]
  );

  return (
    <>
      <Button
        onClick={handleOpen}
        variant='containedLight'
        startIcon={<PlusIcon />}
      >
        Add Member
      </Button>
      <AddEmployeeModal open={open} onClose={handleClose} />
    </>
  );
};

export const OrganizationManagment: FC = () => {
  return (
    <ListContainer dataRequest={getEmployeeList}>
      <Header>
        <Typography variant='h4'>Organization Members</Typography>
        <AddEmployeeButton />
      </Header>
      <DataGridContainer>
        <EmployeeDataGrid height='60vh' />
      </DataGridContainer>
    </ListContainer>
  );
};
