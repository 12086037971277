import { FC, useEffect, useState } from 'react';
import { patientNamesService } from '../../services';

export const PatientBreadcrumb: FC<any> = (props) => {
  const { match } = props;

  const [name, setName] = useState<string | null>(null);

  useEffect(() => {
    if (!match.params.id) {
      return;
    }
    const patientName = patientNamesService.getPatientName(match.params.id);
    if (patientName) {
      setName(patientName);
      return;
    }
    const removeListener = patientNamesService.on('change', () => {
      const patientName = patientNamesService.getPatientName(match.params.id);
      if (patientName) {
        setName(patientName);
        removeListener();
      }
    });
    return removeListener;
  }, [match.params.id]);

  return <span>{name ?? `Patient #${match.params.id}`}</span>;
};
